import { useSessionStorage } from '@mantine/hooks';
import { useEffect } from 'react';

import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { FlagTags } from '@/core/types/feature-flags.types';

// Flags for Dev Tools.
export const useDevToolFlags = () => {
  // Hooks
  const currentUser = useCurrentUser();

  // Toggle Galileo User flag
  const [isGalileoUserFlag, setIsGalileoUserFlag] = useSessionStorage({
    key: 'isGalileoUser',
    defaultValue: false
  });

  useEffect(() => {
    if (currentUser.data) {
      setIsGalileoUserFlag(
        Boolean(currentUser?.data?.email?.match(/@rungalileo.io/))
      );
    }
  }, [currentUser?.dataUpdatedAt]);

  return {
    isGalileoUser: {
      description: 'Toggle Galileo User',
      isEnabled: isGalileoUserFlag,
      tag: [FlagTags.Tool],
      title: 'Galileo User',
      toggle: () => setIsGalileoUserFlag(!isGalileoUserFlag)
    }
  };
};
