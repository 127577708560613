import LabelBadge from '../label-badge';

export interface OdLabelBadgeProps {
  label: string | null;
  disabled?: boolean;
}

/**
 * OdLabelBadge
 *
 *
 *
 * @returns {React.Component} OdLabelBadge
 */
const OdLabelBadge = ({ label, disabled = false }: OdLabelBadgeProps) => {
  const styles = {
    margin: '0px'
  };

  if (!label) {
    return null;
  }

  return (
    <LabelBadge
      data-testid='od-label-badge'
      disabled={disabled}
      key={label}
      maxWidth={100}
      rounded={false}
      style={styles}
      value={label}
      variant='filled'
    />
  );
};

export default OdLabelBadge;
