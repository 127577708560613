import { Box, Card, Grid, Image, Text, UnstyledButton } from '@mantine/core';
import React from 'react';

import { Modals } from '@/core/hooks/use-modals/use-modals';
import {
  useComputedParameters,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { InsightsRow } from '@/fine-tune/types/query.types';

import BaseCvCard from '../base-cv-card/base-cv-card';
import DepOrConfidence from '../../dep-or-confidence/dep-or-confidence';
import LabelBadge from '../../label-badge/label-badge';
import DataNotAvailable from '../../../../core/components/atoms/accessibility/data-not-available/data-not-available';

interface IcCardProps {
  sample: InsightsRow;
  /** Defaults to true */
  canSelect?: boolean;
  /** When enabled, displays the sample's newly assigned label */
  showEdit?: boolean;
  width?: number;
}

const IcCard = ({ sample, canSelect, showEdit, width }: IcCardProps) => {
  // Props
  const { image, gold, pred, new_label } = sample || {};

  const { setParameters } = useParametersStoreActions();
  const isInference = useComputedParameters('isInference');

  // Computed
  const altText = `sample with ${gold || 'no'} label`;

  const imageEl = (
    <Image
      alt={altText}
      fit='contain'
      height='200px'
      miw='100%'
      role='img'
      src={image}
    />
  );

  const groundTruthEl = (
    <>
      <Text c='dimmed' mb='xs' size='sm'>
        Ground Truth
      </Text>
      {gold ? (
        <UnstyledButton onClick={() => setParameters({ goldFilter: [gold] })}>
          <LabelBadge maxWidth={120} value={gold} />
        </UnstyledButton>
      ) : (
        <DataNotAvailable />
      )}
    </>
  );

  const predictedEl = (
    <>
      <Text c='dimmed' mb='xs' size='sm'>
        Predicted
      </Text>
      {pred ? (
        <UnstyledButton onClick={() => setParameters({ predFilter: [pred] })}>
          <LabelBadge maxWidth={120} value={pred} />
        </UnstyledButton>
      ) : (
        <DataNotAvailable />
      )}
    </>
  );

  const depOrConfidenceEl = (
    <Box mt='sm'>
      <DepOrConfidence sample={sample} />
    </Box>
  );

  const newLabelEl = (
    <>
      <Text c='dimmed' mb='xs' size='sm'>
        {isInference ? 'Assigned label' : 'Relabeled'}
      </Text>
      {new_label ? (
        // Keep button to match style of other label badges
        <UnstyledButton>
          <LabelBadge maxWidth={120} value={new_label} />
        </UnstyledButton>
      ) : (
        <DataNotAvailable />
      )}
    </>
  );

  const bodyEl = showEdit ? (
    <Card.Section>
      <Grid>
        <Grid.Col span={6}>
          {isInference ? predictedEl : groundTruthEl}
        </Grid.Col>
        {new_label && <Grid.Col span={6}>{newLabelEl}</Grid.Col>}
      </Grid>
    </Card.Section>
  ) : (
    <Card.Section>
      <Grid>
        {isInference ? (
          <Grid.Col span={6}>
            {predictedEl}
            {depOrConfidenceEl}
          </Grid.Col>
        ) : (
          <>
            <Grid.Col span={6}>
              {groundTruthEl}
              {depOrConfidenceEl}
            </Grid.Col>
            <Grid.Col span={6}>{predictedEl}</Grid.Col>
          </>
        )}
      </Grid>
    </Card.Section>
  );

  return (
    <BaseCvCard
      body={bodyEl}
      canSelect={canSelect}
      image={imageEl}
      modal={Modals.CV}
      sample={sample}
      width={width}
    />
  );
};

export default IcCard;
