import { IconCode, IconEdit, IconTag } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';

import { PopoverMenuItem } from '@/core/components/molecules/popover-menu/popover-menu';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import { useSelectPromptTemplate } from '@/evaluate/hooks/query-hooks/use-select-prompt-template/use-select-prompt-template';
import { usePromptEdit } from '@/evaluate/hooks/use-prompt-edit/use-prompt-edit';
import { IPromptRunParameters } from '@/evaluate/hooks/use-prompt-run-parameters/use-prompt-run-parameters';
import { useQueryParameters } from '@/evaluate/hooks/use-query-parameters/use-query-parameters';

interface UsePromptTemplateMenuConfig {
  promptRunParams: IPromptRunParameters;
}

export const usePromptTemplateMenu = ({
  promptRunParams
}: UsePromptTemplateMenuConfig) => {
  const { internal } = promptRunParams;
  const { templateId, templateVersion, templateVersionSelected, runId } =
    internal;

  // Query Parameters
  const { get, set, reset } = useQueryParameters();
  const rowId = get('rowId');

  // Refs
  const rowIdRef = useRef(rowId);

  // Hooks
  const { openModal, openConfirmModal } = useModals();

  const { handlePromptEdit } = usePromptEdit();
  const selectTemplate = useSelectPromptTemplate();

  const handleTemplateSelect = () => {
    openConfirmModal({
      title: 'Tag as production template?',
      labels: {
        cancel: 'Cancel',
        confirm: 'Confirm'
      },
      onConfirm: () => {
        if (!templateId || typeof templateVersion !== 'number') {
          return;
        }

        selectTemplate.mutate({
          templateId,
          version: templateVersion
        });
      },
      closeOnCancel: true,
      zIndex: Z_INDEX.TOOLTIPS
    });
  };

  const handleEditRun = () => {
    if (!runId) {
      return;
    }

    handlePromptEdit(promptRunParams);
  };

  const handleTemplateCodeClose = () => {
    if (rowIdRef.current) {
      set({ rowId: rowIdRef.current });
    }
  };

  const handleTemplateCodeOpen = () => {
    reset('rowId');

    return openModal(
      Modals.TEMPLATE_CODE,
      { promptRunParams },
      handleTemplateCodeClose
    );
  };

  // Effects
  useEffect(() => {
    if (rowId) {
      // If the rowId changes, update the ref, otherwise the ref won't be in sync
      rowIdRef.current = rowId;
    }
  }, [rowId]);

  const menuItems: PopoverMenuItem[] = [
    {
      label: 'Tag as production Template',
      icon: <IconTag size={18} />,
      disabled: Boolean(templateVersionSelected),
      onClick: handleTemplateSelect
    },
    {
      label: 'View Template code',
      icon: <IconCode size={18} />,
      onClick: handleTemplateCodeOpen
    },
    {
      label: 'Edit Template',
      icon: <IconEdit size={18} />,
      onClick: handleEditRun
    }
  ];

  return {
    menuItems
  };
};
