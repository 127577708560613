import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
export const GET_INTEGRATIONS = '/integrations/';

export const useIntegrations = () => {
  const fetchIntegrations = async () => {
    try {
      const res = await api.GET(GET_INTEGRATIONS);
      return res?.data;
    } catch {
      return [];
    }
  };

  const response = useQuery([GET_INTEGRATIONS], {
    queryFn: fetchIntegrations
  });

  return response;
};
