import { components } from '@/core/types/api';
import { MetricConfig } from '@/observe/types/observe-store.types';

/** Common non-hook logic between the charts and columns to determine whether a given field should be displayed or not */
export function isFieldEnabled({
  fieldId,
  isCustomMetric,
  registeredScorersConfiguration,
  projectSettingAccessor,
  nliProjectSettingAccessor,
  scorersConfiguration
}: {
  fieldId: string;
  isCustomMetric: boolean | undefined;
  registeredScorersConfiguration:
    | components['schemas']['RegisteredScorerRequest'][]
    | undefined;
  projectSettingAccessor: MetricConfig['projectSettingAccessor'];
  nliProjectSettingAccessor: MetricConfig['nliProjectSettingAccessor'];
  scorersConfiguration:
    | components['schemas']['ScorersConfiguration']
    | undefined;
}) {
  if (isCustomMetric) {
    const isCustomMetricEnabled =
      registeredScorersConfiguration?.find(
        (registeredScorerConfig) => registeredScorerConfig?.name === fieldId
      ) != null;

    return isCustomMetricEnabled;
  }

  if (projectSettingAccessor != null) {
    const isSettingEnabled =
      scorersConfiguration?.[projectSettingAccessor] === true;

    // Check if nli is being used
    if (!isSettingEnabled && nliProjectSettingAccessor != null) {
      const isNliSettingEnabled =
        scorersConfiguration?.[nliProjectSettingAccessor] === true;

      return isNliSettingEnabled;
    }

    return isSettingEnabled;
  }

  // Default to true for all other fields
  return true;
}
