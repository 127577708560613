import { Box, Group, Input, Text } from '@mantine/core';
import { ChangeEvent, useState } from 'react';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { ContextModalPropsWithRowId } from '@/core/hooks/use-modals/use-modals.types';
import Button from '@/fine-tune/components/button/button';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';

const { SELECT_FOR_LABEL, DELETE } = EDIT_ACTIONS;

const RemoveOrSelectModal = ({
  id,
  innerProps
}: ContextModalPropsWithRowId) => {
  const [reason, setReason] = useState('');
  const isNer = useComputedParameters('isNer');
  const isInference = useComputedParameters('isInference');

  const allRowsSelected = useStore((state) => state.allRowsSelected);

  const { createEditMutation, samplesIds } = useEditCreate(innerProps?.rowId);
  const { closeModal } = useModals();

  const handleSubmit = () => {
    const editAction = isInference ? SELECT_FOR_LABEL : DELETE;
    createEditMutation.mutate(
      {
        edit_action: editAction,
        note: reason
      },
      {
        onSettled: () => {
          closeModal(id);
        }
      }
    );
  };

  const handleReasonUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const copy = isNer ? 'spans' : 'samples';

  const samplesAmount = samplesIds?.length;

  return (
    <Box mt='lg'>
      <Text>
        Are you sure you want to {isInference ? 'add' : 'remove'}{' '}
        {allRowsSelected ? 'all the selected' : `${samplesAmount}`} {copy}{' '}
        {isInference ? 'to the Labels Cart' : ''}?
      </Text>
      <Text c='gray' fw={500} mt={12} size='sm'>
        Reason for change (optional)
      </Text>
      <Input onChange={handleReasonUpdate} />
      <Group justify='space-between' mt={12}>
        <Button
          copy='Cancel'
          size='sm'
          variant='subtle'
          onClick={() => closeModal(id)}
        />
        <Button
          copy={isInference ? 'Confirm' : 'Remove'}
          id='remove-or-select-modal-submit-btn'
          size='sm'
          onClick={handleSubmit}
        />
      </Group>
    </Box>
  );
};

export default RemoveOrSelectModal;
