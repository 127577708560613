import { Badge, Group, Text } from '@mantine/core';

import { useErrorTypesMetaFilter } from '@/fine-tune/stores/parameters-store/use-store-meta-filters/use-store-meta-filters';

import { GoldSpanHighlighterData } from '../gold-span-highlighter/gold-span-highlighter';

const ERROR_COPY = {
  ghost_span: 'Ghost span(s)',
  missed_label: 'Missed',
  span_shift: 'Span Shift',
  wrong_tag: 'Wrong Tag'
};
/**
 * ErrorTypesCell
 *
 *
 *
 * @returns {React.Component} ErrorTypesCell
 */
const ErrorTypesCell = ({ rowData }: { rowData: GoldSpanHighlighterData }) => {
  const { ghost_span, missed_label, span_shift, wrong_tag } = rowData;
  const errors = { ghost_span, missed_label, span_shift, wrong_tag };

  const errorFilter = useErrorTypesMetaFilter();

  const showBadge = (key: string, value: number) => {
    if (!errorFilter || errorFilter?.isin?.length === 0) {
      return value > 0;
    }

    const isIn = errorFilter?.isin as string[];
    return isIn?.includes(key) && value > 0;
  };

  return (
    <Group data-testid='error-types-cell' gap={2}>
      {Object.entries(errors).map(([key, value]) =>
        showBadge(key, value) ? (
          <Badge
            color='primary.1'
            key={key}
            radius='sm'
            size='md'
            style={({ colors }) => ({
              background: colors.brand[1],
              textTransform: 'lowercase',
              padding: '4px 8px'
            })}
            variant='light'
          >
            <Text c='contrast.2' component='span' fw={600} size='sm'>
              {value}{' '}
            </Text>
            <Text c='gray.6' component='span' fw={400} size='sm'>
              {ERROR_COPY?.[key as keyof typeof ERROR_COPY]}
            </Text>
          </Badge>
        ) : null
      )}
    </Group>
  );
};

export default ErrorTypesCell;
