import { Box, Skeleton } from '@mantine/core';

const TableLoading = () => {
  const approxContentHeightAboveTable = 220;
  const height = window.innerHeight - approxContentHeightAboveTable;
  const skeletonSize = 50;

  const skeletonArray = Array.from(
    {
      length: Math.floor(height / skeletonSize)
    },
    (_i, idx) => (
      <Skeleton data-testid='skeleton' height={40} key={idx} mb='xs' />
    )
  );
  return (
    <Box mt='lg' px='xs'>
      {skeletonArray}
    </Box>
  );
};

export default TableLoading;
