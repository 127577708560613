import { useRouter } from 'next/router';

import { Paths } from '@/core/constants/routes.constants';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

import { IPromptRunParameters } from '../use-prompt-run-parameters/use-prompt-run-parameters';
import { useQueryParameters } from '../use-query-parameters/use-query-parameters';

export const usePromptEdit = () => {
  // Parameters
  const { projectId } = usePathParameters();

  // Query Parameters
  const { get } = useQueryParameters();
  const rowId = get('rowId');

  // Hooks
  const { closeAll } = useModals();

  const router = useRouter();
  const handlePromptEdit = (promptRunParams: IPromptRunParameters) => {
    closeAll();
    const { internal } = promptRunParams;

    router.push({
      pathname: Paths.PROMPT_CREATE_RUN,
      query: {
        projectId,
        resultsRunId: internal.runId,
        templateId: internal.templateId,
        templateVersionId: internal.templateVersionId,
        templateVersionNum: internal.templateVersion,
        rowId: rowId ?? 0
      }
    });
  };

  return {
    handlePromptEdit
  };
};
