import { Box, Text } from '@mantine/core';
import { AxiosError } from 'axios';

export interface ErrorData {
  detail:
    | string
    | {
        loc: string[];
        msg: string;
        type: string;
      }[];
}

interface ErrorMessageProps {
  error: AxiosError<ErrorData, any>;
}
const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const errorMessage = error?.response?.data?.detail;

  const splitLineBreaks = (str: string) => {
    return str
      .split('\n')
      .map((line, index) => <Text key={index}>{line}</Text>);
  };

  if (typeof errorMessage === 'string') {
    return (
      <Text data-testid='error-message'>{splitLineBreaks(errorMessage)}</Text>
    );
  }

  if (Array.isArray(errorMessage)) {
    return (
      <Box data-testid='error-message'>
        {errorMessage.map((error) => (
          <Text key={error.msg}>{splitLineBreaks(error.msg)}</Text>
        ))}
      </Box>
    );
  }
};

export default ErrorMessage;
