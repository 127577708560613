import { MantineSize, Text } from '@mantine/core';

type DataNotAvailableProps = {
  size?: MantineSize;
};

const DataNotAvailable = ({ size = 'xs' }: DataNotAvailableProps) => {
  return (
    <>
      <Text aria-hidden='true' component='span' size={size}>
        —
      </Text>
      <Text style={{ display: 'none' }}>data not available.</Text>
    </>
  );
};

export default DataNotAvailable;
