import {
  Group,
  GroupProps,
  MantineSize,
  Text,
  ThemeIcon,
  Tooltip
} from '@mantine/core';

import {
  MetricsGroupId,
  useMetricsGroup
} from '@/core/hooks/use-metrics-group/use-metrics-group';

interface MetricsGroupLabelProps extends GroupProps {
  description?: string | null | undefined;
  groupId: MetricsGroupId | 'default' | null | undefined;
  /** An override of the label provided by the metrics group */
  label?: string | null | undefined;
  hideIcon?: boolean;
  size?: MantineSize;
}

const MetricsGroupLabel = ({
  description,
  groupId,
  label,
  hideIcon,
  size = 'sm',
  gap = 8,
  ...props
}: MetricsGroupLabelProps) => {
  const group = useMetricsGroup(groupId);

  return (
    <Group data-testid='metrics-group-label' gap={gap} wrap='nowrap' {...props}>
      {!hideIcon && group.icon != null && (
        <ThemeIcon
          c='gray.5'
          size={size === 'lg' ? 24 : 18}
          variant='transparent'
        >
          <group.icon />
        </ThemeIcon>
      )}
      <Tooltip
        multiline
        withArrow
        withinPortal
        disabled={!description}
        label={description}
        maw={350}
        position='top'
      >
        <Text c='gray.6' fw={600} size={size} style={{ whiteSpace: 'nowrap' }}>
          {label ?? group.label}
        </Text>
      </Tooltip>
    </Group>
  );
};

export default MetricsGroupLabel;
