import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { GET_SLICES_KEY } from '@/fine-tune/hooks/query-hooks/use-slices/use-slices';
import { MetaFilter, QueryParams } from '@/fine-tune/types/query.types';

import { POST_COMPARE_RUNS } from '../use-compare-runs/use-compare-runs';

interface Body {
  name: string;
  logic: {
    data_error_potential_low?: number;
    data_error_potential_high?: number;
    text_pat?: string;
    gold_filter?: string[];
    pred_filter?: string[];
    class_filter?: string[];
    meta_filter?: MetaFilter[];
    misclassified_only?: boolean | null;
    regex?: boolean | undefined;
    span_text?: string;
  };
  task?: string;
}

export interface FormValues
  extends Omit<
    QueryParams,
    | 'goldFilter'
    | 'predFilter'
    | 'classFilter'
    | 'misclassifiedOnly'
    | 'isRegex'
    | 'likelyMislabelled'
  > {
  dep?: number[];
  metaFilters?: MetaFilter[];
  sliceName: string;
  goldFilter: string[];
  predFilter: string[];
  classFilter: string[];
  misclassifiedOnly: boolean | undefined;
  isRegex: boolean | undefined;
  likelyMislabelled: boolean | undefined;
  odErrors: string[];
}

// API Path
const POST_CREATE_SLICE = '/projects/{project_id}/slices';

/**
 * useCreateSlice
 *
 */
export const useCreateSlice = () => {
  const { projectId } = usePathParameters();
  const queryClient = useQueryClient();

  const createSlice = async (values: FormValues) => {
    const {
      dep,
      searchTerm,
      goldFilter,
      predFilter,
      classFilter,
      metaFilters,
      misclassifiedOnly,
      likelyMislabelled,
      isRegex,
      spanSearch,
      sliceName,
      odErrors
    } = values || {};

    const [easy, hard] = dep?.map((percentage) => percentage / 100) || [0, 1];

    const combinedMetaFilters = metaFilters && [...metaFilters];
    if (odErrors) {
      // @ts-expect-error
      combinedMetaFilters?.push([...odErrors]);
    }
    const logic = {
      data_error_potential_low: easy,
      data_error_potential_high: hard,
      text_pat: searchTerm,
      gold_filter: goldFilter,
      pred_filter: predFilter,
      class_filter: classFilter,
      meta_filter: metaFilters,
      misclassified_only: misclassifiedOnly || null,
      likely_mislabeled: likelyMislabelled || null,
      regex: isRegex,
      span_text: spanSearch
    };

    const body: Body = {
      name: sliceName,
      logic
    };

    const res = await api.POST(POST_CREATE_SLICE, {
      params: {
        path: {
          project_id: projectId!
        }
      },
      body
    });

    return res?.data;
  };

  const mutation = useMutation(createSlice, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SLICES_KEY]);
      queryClient.invalidateQueries([POST_COMPARE_RUNS]);
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error creating slice')
  });

  return mutation;
};
