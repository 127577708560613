import { TIME_RANGE_DELIMITER } from '@/observe/constants/time.constants';
import { TimeType } from '@/observe/types/time.types';

export function getTimeRange(
  currentTime: number,
  timeRange: TimeType | string
) {
  let startTime: string = new Date(currentTime).toISOString();
  const oneMinute = 1000 * 60;
  const oneDay = oneMinute * 60 * 24;
  switch (timeRange) {
    case 'last5Mins':
      startTime = new Date(currentTime - oneMinute * 5).toISOString();
      break;
    case 'last15Mins':
      startTime = new Date(currentTime - oneMinute * 15).toISOString();
      break;
    case 'last30Mins':
      startTime = new Date(currentTime - oneMinute * 30).toISOString();
      break;
    case 'lastHour':
      startTime = new Date(currentTime - oneMinute * 60).toISOString();
      break;
    case 'last3Hours':
      startTime = new Date(currentTime - oneMinute * 60 * 3).toISOString();
      break;
    case 'last6Hours':
      startTime = new Date(currentTime - oneMinute * 60 * 6).toISOString();
      break;
    case 'last12Hours':
      startTime = new Date(currentTime - oneMinute * 60 * 12).toISOString();
      break;
    case 'last24Hours':
      startTime = new Date(currentTime - oneDay).toISOString();
      break;
    case 'last2Days':
      startTime = new Date(currentTime - oneDay * 2).toISOString();
      break;
    case 'lastWeek':
      startTime = new Date(currentTime - oneDay * 7).toISOString();
      break;
    case 'lastMonth':
      startTime = new Date(currentTime - oneDay * 30).toISOString();
      break;
    case 'lastYear':
      startTime = new Date(currentTime - oneDay * 365).toISOString();
      break;
    default:
      if (timeRange.includes(TIME_RANGE_DELIMITER)) {
        const splitTimeRange = timeRange.split(TIME_RANGE_DELIMITER);
        if (splitTimeRange.length === 2) {
          return { startTime: splitTimeRange[0], endTime: splitTimeRange[1] };
        } else {
          throw new Error('Invalid custom time range format');
        }
      } else {
        throw new Error('Invalid time range parameter');
      }
  }

  return {
    startTime,
    endTime: new Date(currentTime).toISOString()
  };
}
