import { EditAction } from '../stores/data-edits-store/data-edits.store.types';

export const EDIT_ACTIONS: Record<string, EditAction> = {
  ADD_SPAN: 'add_span',
  CREATE_NEW_LABEL: 'create_new_label',
  DELETE: 'delete',
  RELABEL: 'relabel',
  RELABEL_AS_PRED: 'relabel_as_pred',
  SELECT_FOR_LABEL: 'select_for_label',
  SHIFT_SPAN: 'shift_span',
  UPDATE_TEXT: 'update_text'
};
