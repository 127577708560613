import { Text } from '@mantine/core';

import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';

import CopyBox from '../copy-box/copy-box';
import DataNotAvailable from '../../atoms/accessibility/data-not-available/data-not-available';

interface TextRatingProps {
  rating: string;
  explanation?: string | null;
  title?: string;
  maxWidth?: number;
  showPopover?: boolean;
}
export const TextRating = ({
  rating,
  explanation,
  maxWidth,
  showPopover = true
}: TextRatingProps) => {
  if (!rating) {
    return <DataNotAvailable />;
  }

  return (
    <MetricPopover
      body={
        <>
          {showPopover && (
            <CopyBox copyText={rating} variant='dark'>
              <Text pt='sm' px='md' size='sm'>
                {rating}
              </Text>
              <Text px='md' py='sm' size='sm'>
                {explanation}
              </Text>
            </CopyBox>
          )}
        </>
      }
    >
      <Text truncate data-testid='text-rating' maw={maxWidth}>
        {rating}
      </Text>
    </MetricPopover>
  );
};
