export const ASC = 'asc';
export const DESC = 'desc';

export enum Tab {
  DATAFRAME = 'df',
  EMBEDDINGS = 'em',
  DATA_VIEW = 'dv',
  FEATURE_AGGREGATES = 'fd',
  SINGLE_FEATURE = 'fi',
  OBSERVE_METRIC_VIEW = 'pmmv',
  OBSERVE_DATA_VIEW = 'pmdv'
}
