import { STATUS } from '@/core/constants/job-status.constants';
import { JobStatus } from '@/core/hooks/query-hooks/use-latest-job/use-latest-job';

export function isSuccessfulJobStatus(
  status: JobStatus
): status is
  | typeof STATUS.COMPLETED
  | typeof STATUS.SUCCESS
  | typeof STATUS.NOT_COMPUTED
  | typeof STATUS.NOT_APPLICABLE {
  return (
    status != null &&
    (status === STATUS.COMPLETED ||
      status === STATUS.SUCCESS ||
      status === STATUS.NOT_COMPUTED ||
      status === STATUS.NOT_APPLICABLE)
  );
}

export function isInProgressJobStatus(
  status: JobStatus
): status is
  | typeof STATUS.IN_PROGRESS
  | typeof STATUS.UNSTARTED
  | typeof STATUS.PENDING
  | typeof STATUS.COMPUTING {
  return (
    status != null &&
    (status === STATUS.IN_PROGRESS ||
      status === STATUS.UNSTARTED ||
      status === STATUS.PENDING ||
      status === STATUS.COMPUTING)
  );
}

export function isFailedJobStatus(
  status: JobStatus
): status is typeof STATUS.FAILED | typeof STATUS.ERROR {
  return (
    status != null && (status === STATUS.FAILED || status === STATUS.ERROR)
  );
}
