import { useSessionStorage } from '@mantine/hooks';

import { defaults as defaultGroupMembersPermissions } from '@/core/hooks/use-permissions/use-user-group-members-permissions';
import { FlagTags } from '@/core/types/feature-flags.types';
import {
  GroupMembersAction,
  GroupMembersPermissions
} from '@/core/types/user-permissions.types';

export function useUserGroupMembersPermissionsFlags() {
  const [groupMembersFlag, setGroupMembersFlag] = useSessionStorage({
    key: 'group-members',
    defaultValue: false
  });

  const [groupMembersPermissions, setGroupMembersPermissions] =
    useSessionStorage<GroupMembersPermissions>({
      key: 'group-members-permissions',
      defaultValue: defaultGroupMembersPermissions
    });

  return {
    groupMembersPermissions: {
      description: 'Select Group Members permissions',
      isEnabled: groupMembersFlag,
      tag: [FlagTags.Permission],
      title: 'Group Members Permissions',
      values: groupMembersPermissions,
      toggle: () => {
        const newValue = !groupMembersFlag;
        setGroupMembersFlag(newValue);

        if (newValue === false) {
          setGroupMembersPermissions(defaultGroupMembersPermissions);
        }
      },
      subFlags: Object.keys(groupMembersPermissions || {}).map((key) => ({
        description: `toggle ${key} permissions`,
        isEnabled: groupMembersPermissions[key as GroupMembersAction].allowed,
        tag: [FlagTags.Permission],
        title: key,
        toggle: () =>
          setGroupMembersPermissions((current) => ({
            ...current,
            [key]: {
              ...current[key as GroupMembersAction],
              allowed: !current[key as GroupMembersAction].allowed
            }
          }))
      }))
    }
  };
}
