import chroma from 'chroma-js';
import _uniq from 'lodash/uniq';
import _zip from 'lodash/zip';

import { DATA_COLORS } from '@/core/constants/colors.constants';
import { ColorMap } from '@/fine-tune/stores/store.types';

/**
 * colorMappings
 *
 * Utility function to assign a fixed color to a label across the whole ui
 *
 */
export const colorMappings = (
  labels: (string | boolean | number)[],
  alpha: number = 1
): ColorMap => {
  if (!labels?.length) {
    return {};
  }

  // Create a list of 594 colors from the palette
  const colorScaleFromPalette = DATA_COLORS.map((color) => {
    const darkenValue = 3;
    const brightenValue = 0;

    // Darken color to get a range
    const colorUpperLimit = chroma(color).darken(darkenValue).hex();
    const colorLowerLimit = chroma(color).brighten(brightenValue).hex();

    // Create 10 colors between the palette color and the upper limit (darkest) color
    const scale = chroma
      .scale([colorLowerLimit, colorUpperLimit])
      // https://gka.github.io/chroma.js/#scale-correctlightness
      .correctLightness()
      // Create 10 colors
      .colors(10);

    return scale;
  });

  // Zip the color array together to prevent seeing only gradients
  const zippedColors = _zip(...colorScaleFromPalette).flat();

  // Create 594 unique colors
  const uniqueColors = _uniq([
    // Add original color scale (~200 colors)
    ...zippedColors,
    // Add saturated color scale (~200 colors)
    ...zippedColors.map((color = '') => chroma(color).saturate(2).hex()),
    // Add desaturate color scale (~200 colors)
    ...zippedColors.map((color = '') => chroma(color).desaturate(1).hex())
  ]);

  let colorIndex = 0;
  let map = {};

  labels.forEach((label, index) => {
    map = {
      ...map,
      // @ts-expect-error
      [label]: {
        id: index,
        background: uniqueColors[colorIndex],
        bar: chroma(uniqueColors[colorIndex] || '')
          .alpha(alpha)
          .hex(),
        text: uniqueColors[colorIndex]
      }
    };

    colorIndex++;

    if (colorIndex > uniqueColors.length - 1) {
      colorIndex = 0;
    }
  });

  return map;
};
