import { Box, MantineSize } from '@mantine/core';
import { Badge, Text, Tooltip } from '@mantine/core';
import { IconRotateClockwise2 } from '@tabler/icons-react';
import check from 'check-types';
import { forwardRef } from 'react';

import useStore from '@/fine-tune/stores/store';

interface LabelBadgeProps {
  disabled?: boolean;
  isGhostOrMissed?: boolean;
  maxWidth?: number | string;
  mr?: MantineSize;
  onClick?: () => void;
  rightSection?: JSX.Element;
  rounded?: boolean;
  size?: MantineSize;
  style?: Object;
  title?: string;
  value: string | number;
  variant?: 'outline' | 'filled';
  withoutTooltip?: boolean;
  inline?: boolean;
  wasEdited?: boolean;
}

/**
 * LabelBadge
 *
 * Badges specifically styled for Text Classification gold/ pred use
 *
 */
const LabelBadge = forwardRef<HTMLDivElement, LabelBadgeProps>(
  (
    {
      disabled = false,
      inline = false,
      isGhostOrMissed = false,
      maxWidth = 150,
      onClick,
      rightSection,
      rounded = true,
      size = 'md',
      style,
      title,
      value,
      variant = 'outline',
      wasEdited = false,
      withoutTooltip = false,
      ...styling
    }: LabelBadgeProps,
    ref
  ) => {
    // Global Store
    const colorMap = useStore((state) => state.colorMap);

    // Computed Values
    const isFilled = variant === 'filled';
    const borderColor = disabled
      ? 'var(--mantine-color-contrast-5)'
      : colorMap?.[value]?.text;

    const getBackground = () => {
      if (disabled) {
        return 'var(--mantine-color-contrast-5)';
      }

      if (isFilled) {
        return colorMap?.[value]?.bar;
      }

      if (isGhostOrMissed) {
        return 'var(--mantine-color-red-2)';
      }

      return 'white';
    };

    const badge = (
      <div
        style={{
          maxWidth,
          display: inline ? 'inline-flex' : undefined,
          ...style
        }}
      >
        <Badge
          fullWidth
          leftSection={
            wasEdited ? (
              <Box mt={5}>
                <IconRotateClockwise2 size={14} />
              </Box>
            ) : null
          }
          px='xs'
          radius={rounded ? 'md' : 0}
          ref={ref}
          rightSection={rightSection ?? null}
          size={size}
          style={{
            color: !isFilled ? colorMap?.[value]?.background : 'white',
            background: getBackground(),
            borderColor: borderColor,
            borderWidth: 1,
            cursor: check.function(onClick) ? 'pointer' : 'inherit',
            textTransform: 'none'
          }}
          variant={variant}
          onClick={onClick}
          {...styling}
        >
          {title && (
            <Text
              c={colorMap?.[value]?.text}
              component='span'
              fw={900}
              mr={6}
              size='xs'
            >
              {title}
            </Text>
          )}
          {value}
        </Badge>
      </div>
    );

    return (
      <>
        {withoutTooltip ? (
          badge
        ) : (
          <Tooltip multiline withArrow withinPortal label={value} maw={300}>
            {badge}
          </Tooltip>
        )}
      </>
    );
  }
);

export default LabelBadge;
