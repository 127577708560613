import check from 'check-types';

export const caseInsensitiveMatch = (
  value: string | string[],
  matcher: string
): boolean => {
  const scapedMatcher = matcher.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const regex = new RegExp(scapedMatcher, 'i');

  if (check.array(value)) {
    return Boolean((value as string[]).some((str) => str.match(regex)));
  }

  return Boolean((value as string).match(regex));
};
