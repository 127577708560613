import { useSessionStorage } from '@mantine/hooks';

import { defaults } from '@/core/hooks/use-permissions/use-project-permissions';
import { FlagTags } from '@/core/types/feature-flags.types';
import {
  ProjectAction,
  ProjectPermissions
} from '@/core/types/user-permissions.types';

export function useProjectPermissionsFlags() {
  const [projectFlag, setProjectFlag] = useSessionStorage({
    key: 'project',
    defaultValue: false
  });

  const [projectPermissions, setProjectPermissions] =
    useSessionStorage<ProjectPermissions>({
      key: 'project-permissions',
      defaultValue: defaults
    });

  return {
    projectPermissions: {
      description: 'Select Project permissions',
      isEnabled: projectFlag,
      tag: [FlagTags.Permission],
      title: 'Project Permissions',
      values: projectPermissions,
      toggle: () => {
        const newValue = !projectFlag;
        setProjectFlag(newValue);
        if (newValue === false) {
          setProjectPermissions(defaults);
        }
      },
      subFlags: Object.keys(projectPermissions).map((key) => ({
        description: `toggle ${key} permissions`,
        isEnabled: projectPermissions[key as ProjectAction].allowed,
        tag: [FlagTags.Permission],
        title: key,
        toggle: () =>
          setProjectPermissions((current) => ({
            ...current,
            [key]: {
              ...current[key as ProjectAction],
              allowed: !current[key as ProjectAction].allowed
            }
          }))
      }))
    }
  };
}
