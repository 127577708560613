import { ActionIcon, Box, Kbd, Text, TextInput, Tooltip } from '@mantine/core';
import { IconCode, IconCornerDownLeft } from '@tabler/icons-react';

import ModKbd from '@/core/components/atoms/mod-kbd/mod-kbd';
import LiveSearchBar from '@/core/components/organisms/no-projects/live-search-bar/live-search-bar';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';

interface SearchProps {
  withReplace?: boolean;
}

/**
 * Search
 *
 *
 *
 * @returns {React.Component} Search
 */
const Search = ({ withReplace }: SearchProps) => {
  const searchTerm = useDataEditsStore((s) => s.searchTerm);
  const value = useDataEditsStore((s) => s.value);
  const isRegexSearch = useDataEditsStore((s) => s.isRegexSearch);

  const { setValue, setSearchTerm, setIsRegexSearch, setReplaceTerm } =
    useDataEditsStore((s) => s.actions);

  return (
    <>
      <TextInput
        autoFocus
        data-testid='ner-search'
        placeholder={isRegexSearch ? 'Regex search' : 'Text search'}
        rightSection={
          <>
            <Tooltip
              withArrow
              label={
                <Box className='text-center' mb={2}>
                  <Text size='sm'>Use RegEx Search</Text>
                  <ModKbd withShift character='u' />
                </Box>
              }
            >
              <ActionIcon
                aria-label='Regex Search'
                color='gray'
                mr={4}
                size='sm'
                variant={isRegexSearch ? 'filled' : 'default'}
                onClick={() => setIsRegexSearch()}
              >
                <IconCode size={16} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              withArrow
              withinPortal
              className='flex'
              label='Click Enter to search'
            >
              <Kbd className='flex' mr={2} style={{ lineHeight: 1.25 }}>
                <IconCornerDownLeft size={16} />
              </Kbd>
            </Tooltip>
          </>
        }
        rightSectionWidth={60}
        size='sm'
        value={value}
        onBlur={(e) => setSearchTerm(e.currentTarget.value)}
        onChange={(e) => setValue(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setSearchTerm(value);
          }
        }}
      />

      {withReplace && (
        <LiveSearchBar
          autoFocus={false}
          data-testid='ner-search-replace'
          disabled={!searchTerm}
          mt='xs'
          placeholder='Replace'
          size='sm'
          onChange={(val) => setReplaceTerm(val)}
        />
      )}
    </>
  );
};

export default Search;
