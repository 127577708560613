// @ts-nocheck
// TODO: Fix Generic Permissions flags
import { useSessionStorage } from '@mantine/hooks';

import { defaults as defaultGenericPermissions } from '@/core/hooks/use-permissions/use-generic-permissions';
import { FlagTags } from '@/core/types/feature-flags.types';
import {
  GenericAction,
  GenericPermissions
} from '@/core/types/user-permissions.types';

export function useGenericPermissionsFlags() {
  const [genericFlag, setGenericFlag] = useSessionStorage({
    key: 'generic',
    defaultValue: false
  });

  const [genericPermissions, setGenericPermissions] =
    useSessionStorage<GenericPermissions>({
      key: 'generic-permissions',
      defaultValue: defaultGenericPermissions
    });

  return {
    genericPermissions: {
      description: 'Select Generic permissions',
      isEnabled: genericFlag,
      tag: [FlagTags.Permission],
      title: 'Generic Permissions',
      values: genericPermissions,
      toggle: () => {
        const newValue = !genericFlag;
        setGenericFlag(newValue);
        if (newValue === false) {
          setGenericPermissions(defaultGenericPermissions);
        }
      },
      subFlags: Object.keys(genericPermissions).map((key) => ({
        description: `toggle ${key} permissions`,
        isEnabled: genericPermissions[key as GenericAction].allowed,
        tag: [FlagTags.Permission],
        title: key,
        toggle: () =>
          setGenericPermissions((current) => ({
            ...current,
            [key]: {
              ...current[key as GenericAction],
              allowed: !current[key as GenericAction].allowed
            }
          }))
      }))
    }
  };
}
