import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export const toHumanReadableNumber = (
  number: number | null | undefined,
  config?: { fallback: string | number }
) => {
  const { format } = Intl.NumberFormat('en', { notation: 'compact' });

  if (typeof number !== 'number') {
    return config?.fallback ?? STRING_PLACEHOLDER;
  }

  return format(number);
};
