import { Anchor, Text } from '@mantine/core';

const FAQErrorDocumentationLink = () => {
  return (
    <Text data-testid='faq-error-documentation-link' p='sm' size='sm'>
      See{' '}
      <Anchor
        c='brand.3'
        fw={600}
        href='https://docs.rungalileo.io/galileo/how-to-and-faq/faqs/errors-computing-metrics'
        target='_blank'
      >
        documentation
      </Anchor>{' '}
      for common errors.
    </Text>
  );
};

export default FAQErrorDocumentationLink;
