import { InputBase, InputBaseProps } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { ChangeEvent } from 'react';

export interface SimpleNumberInputProps extends InputBaseProps {
  /** Uncontrolled component default value */
  defaultValue?: number | string;
  onChange?: (value: string, valueAsNumber: number) => void;
  placeholder?: string;
}

/**
 * A dumbed down version of NumberInput that:
 * 1. Does not have formatting issues i.e. backspace removes decimal point
 * 2. Does not have step arrow buttons
 * 3. Yields both the string value and number parsed value on change
 * 4. Remains uncontrollable yet allows for a `defaultValue`
 */
const SimpleNumberInput = ({
  defaultValue,
  onChange,
  placeholder,
  ...props
}: SimpleNumberInputProps) => {
  const [_value, setValue] = useUncontrolled({
    defaultValue:
      typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue,
    onChange
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.currentTarget.value;
    const valueAsNumber = parseFloat(nextValue);
    setValue(nextValue, valueAsNumber);
  };

  return (
    <InputBase
      data-testid='simple-number-input'
      placeholder={placeholder}
      type='number'
      value={_value}
      onChange={handleChange}
      {...props}
    />
  );
};

export default SimpleNumberInput;
