import { PRED_KEY } from '@/fine-tune/constants/embeddings.constants';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import { InsightsRow } from '@/fine-tune/types/query.types';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';

interface BaseSelectedSample {
  gold: string;
  pred: string;
}

interface NerSelectedSample {
  spans: SpanHighlightData[];
}

export const getChangesByLabel = (
  rowsData: InsightsRow[],
  selectedRows: number[],
  similarToIds: number[],
  allRowsSelected: boolean,
  selectedObjects?: number[],
  key: 'pred' | 'gold' = 'gold',
  selectedSpans?: SpanHighlightData[]
) => {
  const isInference = useParametersStore?.getState()?.computed?.isInference();
  const isNer = useParametersStore?.getState()?.computed?.isNer();
  const isOd = useParametersStore?.getState()?.computed?.isOd();

  if (!isOd) {
    key = isInference ? PRED_KEY : key;
  }

  const rowsChanges: Record<string, number> = {};

  rowsData
    ?.filter(
      (row: { id: number }) =>
        allRowsSelected ||
        selectedRows.some((selected: number) => selected === row.id)
    )
    ?.forEach((selectedSample) => {
      if (isNer) {
        (selectedSample as NerSelectedSample)?.spans?.forEach((span) => {
          if (span?.[key] && span.is_active) {
            // @ts-expect-error - FIXME
            rowsChanges[span?.[key]] = (rowsChanges[span?.[key]] || 0) + 1;
          }
        });
      } else if (isOd) {
        selectedSample?.boxes?.forEach((box) => {
          if (box?.[key] && selectedObjects?.includes(box.id)) {
            rowsChanges[box?.[key]!] = (rowsChanges[box?.[key]!] || 0) + 1;
          }
        });
      } else {
        const selectedKey = (selectedSample as BaseSelectedSample)?.[key];
        rowsChanges[selectedKey] = (rowsChanges[selectedKey] || 0) + 1;
      }
    });

  if (isNer && similarToIds?.length) {
    rowsData
      ?.flatMap((row) => row.spans)
      ?.filter((span) => similarToIds.includes(span?.id as number))
      ?.forEach((span) => {
        if (span) {
          // @ts-expect-error - FIXME
          rowsChanges[span?.[key]] = (rowsChanges[span?.[key]] || 0) + 1;
        }
      });
  }

  if (isNer && selectedSpans?.length) {
    selectedSpans.forEach((span) => {
      // @ts-expect-error - FIXME
      rowsChanges[span?.[key]] = (rowsChanges[span?.[key]] || 0) + 1;
    });
  }

  return rowsChanges;
};
