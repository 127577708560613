import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';

export const INSIGHTS_ROWS = 'INSIGHTS_ROWS';

export const useInsightsRowsQueryKey = (queryKey = INSIGHTS_ROWS) => {
  const mlcTaskAndIds = useStore((state) => state.mlcTaskAndIds);
  const shapeSelection = useEmbeddingsStore((state) => state.shapeSelection);
  const similarToQuantity = useStore((state) => state.similarToQuantity);
  const { runId, projectId } = usePathParameters();

  const { inferenceName, sortBy, sortDirection, task, split } =
    useParametersStore((s) => ({
      inferenceName: s.inferenceName,
      sortBy: s.sortBy,
      sortDirection: s.sortDirection,
      task: s.task,
      split: s.split
    }));

  const filter_params = useFilterParams();

  return [
    queryKey,
    {
      inferenceName,
      mlcTaskAndIds,
      projectId,
      runId,
      shapeSelection,
      similarToQuantity,
      sortBy,
      sortDirection,
      split,
      task,
      ...filter_params
    }
  ];
};
