import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { DataEditsStoreState } from './data-edits.store.types';

export const useDataEditsStore = createWithEqualityFn<DataEditsStoreState>()(
  (set) => ({
    // Start TODO:

    // Fix handling the debounced state across two different components here
    // There is definitely a better way to do this - it's an odd use case where the value has to
    // be persisted across two tabs, and debounced

    // Debounced Search Term
    searchTerm: '',

    // Undebounced Search Term
    value: '',

    // End TODO:
    // ---------

    changeLabelTo: undefined,
    currentTab: 'add',
    isRegexSearch: true,
    notes: '',
    replaceTerm: '',
    spanEndIndex: 0,
    spanStartIndex: 0,
    actions: {
      clearState: () =>
        set(() => ({
          spanEndIndex: 0,
          spanStartIndex: 0,
          notes: '',
          searchTerm: '',
          replaceTerm: ''
        })),
      setCurrentTab: (tab: 'add' | 'shift') => set(() => ({ currentTab: tab })),
      setIsRegexSearch: (bool?: boolean) =>
        set((state) => ({ isRegexSearch: bool ?? !state.isRegexSearch })),
      setChangeLabelTo: (str: string) => set(() => ({ changeLabelTo: str })),
      setNotes: (str: string) => set(() => ({ notes: str })),
      setReplaceTerm: (value: string) => set(() => ({ replaceTerm: value })),
      setSearchTerm: (value: string) => set(() => ({ searchTerm: value })),
      setSpanEndIndex: (num: number) => set(() => ({ spanEndIndex: num })),
      setSpanStartIndex: (num: number) => set(() => ({ spanStartIndex: num })),
      setValue: (value: string) => set(() => ({ value }))
    }
  }),
  shallow
);
