import { Pill, PillProps, useMantineTheme } from '@mantine/core';

import {
  SCORER_TYPE,
  ScorerType
} from '@/core/constants/scorer-type.constants';
import { getScorerTypeLabel } from '@/core/utils/get-scorer-type-label/get-scorer-type-label';

interface ScorerTypePillProps extends PillProps {
  type: ScorerType;
}

const ScorerTypePill = ({ type, ...pillProps }: ScorerTypePillProps) => {
  const { colors } = useMantineTheme();

  return (
    <Pill
      bg={type === SCORER_TYPE.LUNA ? colors.contrast[8] : colors.brand[5]}
      c={type === SCORER_TYPE.LUNA ? colors.contrast[2] : 'white'}
      size='xs'
      tt='uppercase'
      {...pillProps}
    >
      {getScorerTypeLabel(type)}
    </Pill>
  );
};

export default ScorerTypePill;
