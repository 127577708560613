import { Box, Rating, Text } from '@mantine/core';

import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';

export interface StarRatingProps {
  rating: number;
  explanation?: string;
  title?: string;
}

export const StarRating = ({ rating, explanation, title }: StarRatingProps) => {
  return (
    <MetricPopover
      body={
        explanation && (
          <Box p='sm'>
            <Text c='gray.2' fw={600} pb='sm' size='sm'>
              {title ?? 'Star Rating'}
            </Text>
            <Text c='gray.2' size='sm'>
              {explanation}
            </Text>
          </Box>
        )
      }
    >
      <Rating readOnly data-testid='star-rating' value={rating} />
    </MetricPopover>
  );
};
