import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const GET_SLICES_KEY = `/projects/{project_id}/slices`;
export const GET_SLICE_BY_ID_KEY = `/projects/{project_id}/slices/{slice_id}`;

export const useSlices = (config?: { byId?: boolean }) => {
  const { projectId } = usePathParameters();
  const slice = useParametersStore((state) => state.slice);
  const queryClient = useQueryClient();

  let sliceId: string | undefined = undefined;
  let enabled = Boolean(projectId);

  if (config?.byId) {
    sliceId = slice;
    enabled = Boolean(projectId && slice);
  }

  const path = sliceId ? GET_SLICE_BY_ID_KEY : GET_SLICES_KEY;

  const fetchSlices = async () => {
    const res = await api.GET(path, {
      params: {
        path: {
          project_id: projectId as string,
          slice_id: sliceId as string
        }
      }
    });

    return res?.data;
  };

  const queryResponse = useQuery(
    [GET_SLICES_KEY, { projectId, sliceId }],
    fetchSlices,
    {
      enabled
    }
  );

  const handleDeleteSlice = (sliceId: string) => {
    return api.DELETE(GET_SLICE_BY_ID_KEY, {
      params: {
        path: {
          project_id: projectId as string,
          slice_id: sliceId
        }
      }
    });
  };

  const deleteSlice = useMutation(handleDeleteSlice, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SLICES_KEY]);

      // TODO: add slice name to notification
      showNotification({
        message: 'Slice deleted successfully'
      });
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error deleting slice')
  });

  return {
    ...queryResponse,
    deleteSlice
  };
};
