import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';

type CenteringValue = 'data_error_potential' | 'confidence';

interface ParseSpanHighlightDataArgs {
  text?: string;
  spans?: SpanHighlightData[];
  columnId?: 'gold' | 'pred';
  centeringValue?: CenteringValue;
  hiddenSpans?: string[];
}

interface SpanArray {
  leading: string;
  span: string | null;
  trailing: string | null;
  label: string | null | undefined;
  id?: number | null;
  isCenteredSpan: boolean;
  isFilled: boolean;
  isBordered: boolean;
  isNotRealSpan: boolean;
  isActive: boolean;
  dep?: number | null;
  errorType?: string | null;
  confidence?: number | null;
}

interface ParseSpanHighlightDataReturn {
  spanArray?: SpanArray[];
  centeredIdx?: number;
  error?: string | null;
}

const SPAN_ARRAY_FALLBACKS = {
  span: null,
  trailing: null,
  label: null,
  id: null,
  isCenteredSpan: false,
  isFilled: false,
  isBordered: false,
  isNotRealSpan: false,
  isActive: false,
  dep: null,
  errorType: null,
  confidence: null
};

const getCenteredSpanId = (
  spans: SpanHighlightData[] = [],
  centeringValue: CenteringValue = 'data_error_potential'
) => {
  if (spans?.length) {
    const centeredId = spans?.reduce((a, b) => {
      const aCenteringValue = a.is_active ? a[centeringValue] : 0;
      const bCenteringValue = b.is_active ? b[centeringValue] : 0;

      return (aCenteringValue || 0) > (bCenteringValue || 0) ? a : b;
    })?.id;

    return centeredId;
  }
};

/**
 * parseSpanHighlightData
 *
 * Handler for parsing span highlight data
 *
 */
export const parseSpanHighlightData = ({
  text = '',
  spans = [],
  columnId = 'gold',
  centeringValue = 'data_error_potential',
  hiddenSpans
}: ParseSpanHighlightDataArgs): ParseSpanHighlightDataReturn => {
  if (!text) {
    return {
      error: 'No text passed',
      spanArray: []
    };
  }

  if (!spans?.length) {
    return {
      error: 'No spans passed',
      spanArray: [
        {
          leading: text,
          ...SPAN_ARRAY_FALLBACKS
        }
      ]
    };
  }

  const isGoldColumn = columnId === 'gold';

  const spansByColumn = spans
    // Sort by start index, if the start index is the same then find the smallest end index and return first
    ?.sort((a, b) => {
      if (a.span_start === b.span_start) {
        // Sort by smaller end index first
        return a.span_end - b.span_end;
      }
      // Sort by smallest start index first
      return a.span_start - b.span_start;
    });

  let filteredSpans: SpanHighlightData[] = [];
  let skip = false;

  if (!isGoldColumn) {
    spansByColumn?.forEach((spanEl, idx) => {
      if (skip) {
        skip = false;
      } else if (
        spanEl.span_start === spansByColumn?.[idx + 1]?.span_start &&
        !spanEl.pred
      ) {
        return;
      } else if (spanEl.span_end > spansByColumn?.[idx + 1]?.span_start) {
        const withPred = spanEl?.pred ? spanEl : spansByColumn?.[idx + 1];

        filteredSpans.push(withPred);
        skip = true;
      } else {
        filteredSpans.push(spanEl);
      }
    });
  } else {
    filteredSpans = spansByColumn?.filter(
      ({ gold }) => (isGoldColumn && gold) || !isGoldColumn
    );
  }

  if (!spansByColumn.length) {
    return {};
  }

  const centeredSpan = getCenteredSpanId(filteredSpans, centeringValue);

  let previousEndIndex = 0;

  let spanArray: SpanArray[] = filteredSpans.map((_span, idx) => {
    const {
      span_start,
      span_end,
      gold,
      pred,
      id,
      is_active,
      data_error_potential,
      error_type = '',
      confidence = null
    } = _span || {};

    const isLastSpan: boolean = filteredSpans.length === idx + 1;
    const label = isGoldColumn ? gold : pred;
    const isCenteredSpan = centeredSpan === id;

    // Text before span
    const leading = text.substring(previousEndIndex, span_start);
    // Span
    const span = text.substring(span_start, span_end);
    // Text after span
    const trailing = isLastSpan ? text.substring(span_end) : null;

    // Track the last span index for the next loop
    previousEndIndex = span_end;

    return {
      leading,
      span,
      trailing,
      label,
      id,
      isCenteredSpan,
      isFilled: !isGoldColumn && gold !== pred,
      isBordered: Boolean(!isGoldColumn && pred),
      isNotRealSpan: Boolean(
        (isGoldColumn && !label) || hiddenSpans?.includes(gold || '')
      ),
      isActive: Boolean(is_active),
      dep: data_error_potential,
      confidence,
      errorType: error_type?.replaceAll('_', ' ')
    };
  });

  if (!spanArray.length) {
    spanArray = [
      {
        leading: text,
        ...SPAN_ARRAY_FALLBACKS
      }
    ];
  }

  return {
    spanArray,
    error: null,
    centeredIdx: spanArray.findIndex(
      ({ id }) => id === centeredSpan?.toString()
    )
  };
};
