import { Highlight, Text } from '@mantine/core';
import { useState } from 'react';

import { components } from '@/core/types/api';
import LabelledWord from '@/fine-tune/components/labelled-word/labelled-word';
import useStore from '@/fine-tune/stores/store';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';

export interface GoldSpanHighlighterData {
  id: number;
  text: string | null;
  pred: string | null;
  x: number | null;
  y: number | null;
  confidence: number | null;
  meta: Meta;
  gold: string;
  data_error_potential: number;
  spans?: SpanHighlightData[];
  missed_label: number;
  wrong_tag: number;
  span_shift: number;
  ghost_span: number;
  total_errors: number;
  is_on_the_boundary: null;
  is_drifted: null;
}
export interface GoldSpanHighlighterProps {
  data: components['schemas']['DataRow'];
  changeLabelTo?: string;
  searchTerm?: string;
}

export interface Meta {}

interface AddSpanHighlightProps {
  text: string;
  highlight?: string;
  label: string;
  color: string;
}

const AddSpanHighlight = ({
  text,
  highlight = '',
  label,
  color
}: AddSpanHighlightProps) => {
  return (
    <Highlight
      component='span'
      highlight={highlight}
      highlightStyles={{
        display: 'inline-flex',
        flexDirection: 'column',
        padding: 4,
        paddingBottom: 0,
        backgroundColor: 'var(--mantine-color-yellow-1)',
        border: `1px solid ${color}`,
        borderRadius: 4,

        // TODO - Not sure how to fix this one
        // @ts-expect-error
        '&.mantine-Mark-root::after': {
          display: 'inline-flex',
          content: `"${label?.toLowerCase()}"`,
          color,
          fontSize: 10,
          justifyContent: 'center',
          marginTop: -5,
          fontWeight: 600
        }
      }}
    >
      {text}
    </Highlight>
  );
};

/**
 * GoldSpanHighlighter
 *
 *
 *
 * @returns {React.Component} GoldSpanHighlighter
 */
const GoldSpanHighlighter = ({
  data,
  changeLabelTo = '',
  searchTerm
}: GoldSpanHighlighterProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const colorMap = useStore((state) => state.colorMap);

  const { text, spans, id } = data || {};

  let prevEnd = 0;

  const filteredSpans = spans?.filter((i) => i.is_active) || [];

  const spanArray = filteredSpans.map(
    ({ gold, span_start, span_end, id: spanId }, idx) => {
      const leading = text?.substring(prevEnd, span_start);

      const span = (
        <LabelledWord
          color={colorMap[gold as string]?.text}
          label={gold}
          spanId={spanId}
          word={text?.substring(span_start, span_end)}
          onClick={() => setIsExpanded((prev) => !prev)}
        />
      );

      prevEnd = span_end;

      let trailing = '';

      if (idx === filteredSpans?.length - 1) {
        trailing = text?.substring(span_end) || '';
      }

      return [
        <AddSpanHighlight
          color={colorMap[changeLabelTo]?.background}
          highlight={searchTerm}
          key={`${spanId}-leading`}
          label={changeLabelTo}
          text={leading as string}
        />,
        span,
        <AddSpanHighlight
          color={colorMap[changeLabelTo]?.background}
          highlight={searchTerm}
          key={`${spanId}-trailing`}
          label={changeLabelTo}
          text={trailing}
        />
      ];
    }
  );

  return (
    <Text
      className='cursor-pointer'
      data-testid='gold-span-highlighter'
      key={id}
      lineClamp={isExpanded ? undefined : 1}
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      {spanArray}
    </Text>
  );
};

export default GoldSpanHighlighter;
