import {
  Card,
  Divider,
  MantineSize,
  Paper,
  Skeleton,
  Text
} from '@mantine/core';
import check from 'check-types';
import cx from 'classix';
import { forwardRef } from 'react';

import classes from './split-card.module.css';
export interface SplitCardProps {
  children?: React.ReactNode;
  header?: string | React.ReactNode;
  isLoading?: boolean;
  mb?: MantineSize;
  mt?: MantineSize;
  subheader?: React.ReactNode;
  testId?: string;
  disableFocus?: boolean;
  withoutBorder?: boolean;
  cardPadding?: MantineSize | number;
  style?: React.CSSProperties;
}

const SplitCard = forwardRef(
  (
    {
      children,
      header,
      isLoading = false,
      mb,
      mt,
      subheader,
      cardPadding,
      testId = 'split-card',
      disableFocus = false,
      withoutBorder = true,
      style
    }: SplitCardProps,
    ref?: React.Ref<HTMLInputElement>
  ) => {
    const isFocusable = !disableFocus && ref;
    const loader = (
      <>
        <Skeleton data-testid='skeleton' height={20} mb='sm' mt='sm' />
        <Skeleton height={20} mb='sm' />
        <Skeleton height={20} mb='sm' />
      </>
    );

    return (
      <Paper
        className={cx(
          withoutBorder && classes.noBorder,
          isFocusable && classes.withFocus
        )}
        data-testid={testId}
        mb={mb}
        mt={mt}
        radius='md'
        style={{
          ...(withoutBorder && {
            '.mantine-Paper-root': {
              border: '2px solid transparent'
            }
          }),
          ...(isFocusable && {
            '.mantine-Paper-root:focus': {
              borderColor: 'var(--mantine-color-brand-3)'
            }
          })
        }}
      >
        <Card
          withBorder
          className='flex-column'
          padding={check.number(cardPadding) ? cardPadding : 'sm'}
          radius='md'
          ref={ref}
          style={style}
          tabIndex={isFocusable ? 0 : -1}
        >
          <Card.Section>
            <Paper p='sm'>
              <Text c='dimmed' fw={500}>
                {header}
              </Text>
              {subheader || null}
            </Paper>
            <Divider
              style={{ borderTopColor: 'var(--mantine-color-gray-2)' }}
            />
          </Card.Section>
          {isLoading && loader}
          {!isLoading && children}
        </Card>
      </Paper>
    );
  }
);

export default SplitCard;
