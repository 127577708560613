import { useParametersStore } from '../parameters.store';
import { ComputedValue } from '../parameters.store.types';

type ComputedParameter = ComputedValue | undefined;
type Accessor = Record<ComputedValue, boolean>;

// eslint-disable-next-line no-redeclare
export function useComputedParameters(accessor?: undefined): Accessor;
// eslint-disable-next-line no-redeclare
export function useComputedParameters(accessor?: ComputedValue): boolean;
// eslint-disable-next-line no-redeclare
export function useComputedParameters(accessor?: ComputedParameter) {
  const computed = useParametersStore((s) => s.computed);

  if (accessor) {
    return computed?.[accessor]?.();
  }

  return Object.entries(computed).reduce((acc, [key, cb]) => {
    // @ts-expect-error TODO: Fix typing
    acc[key] = cb();
    return acc;
  }, {});
}

export function getComputedParameters(accessor?: ComputedParameter) {
  const { computed } = useParametersStore.getState();
  if (accessor) {
    return computed[accessor]?.();
  }
  return computed;
}
