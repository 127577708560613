import { NextRouter } from 'next/router';

export const POST_SIGN_IN_PATH_KEY = 'g-post-sign-in-url';

export const getPath = () => sessionStorage.getItem(POST_SIGN_IN_PATH_KEY);

export const setPath = (router: NextRouter) => {
  const path = router?.asPath;

  if (path) {
    sessionStorage.setItem(POST_SIGN_IN_PATH_KEY, path);
  }
};

export const clearPath = () => {
  sessionStorage.removeItem(POST_SIGN_IN_PATH_KEY);
};
