import { InputVariant, Tooltip } from '@mantine/core';
import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps
} from '@mantine/core';

interface SelectProps extends MantineSelectProps {
  allowDeselect?: boolean;
  clearable?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  ml?: string | number;
  mr?: string | number;
  mx?: string | number;
  my?: string | number;
  onChange?: (str: string | null) => void;
  placeholder?: string;
  rightSection?: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  value?: string | null;
  variant?: InputVariant | undefined;
  rightSectionWidth?: number;
  leftSectionWidth?: number;
  searchable?: boolean;
  width?: string | number;
  tooltipCopy?: string | React.ReactNode;
  id?: string | undefined;
}

const Select = ({
  searchable = true,
  placeholder,
  icon,
  data = [],
  value,
  onChange,
  label,
  defaultValue,
  size = 'xs',
  clearable = false,
  allowDeselect = false,
  disabled = false,
  variant = 'default',
  rightSection,
  width = '100%',
  tooltipCopy,
  leftSectionWidth,
  ...others
}: SelectProps) => {
  const extraStyles = {
    rightSection: {}
  };

  if (rightSection) {
    extraStyles.rightSection = { pointerEvents: 'none' };
  }

  return (
    <Tooltip
      withArrow
      disabled={!tooltipCopy}
      label={tooltipCopy}
      position='bottom'
    >
      <MantineSelect
        allowDeselect={allowDeselect}
        clearable={clearable}
        data={data}
        defaultValue={defaultValue}
        disabled={disabled || !data.length}
        label={label}
        leftSection={icon}
        leftSectionWidth={leftSectionWidth}
        placeholder={placeholder}
        rightSection={rightSection}
        rightSectionProps={extraStyles.rightSection}
        role='combobox'
        searchable={searchable}
        size={size}
        style={{
          width
        }}
        value={value}
        variant={variant}
        onChange={(val) => onChange && onChange(val as string)}
        {...others}
      />
    </Tooltip>
  );
};

export default Select;
