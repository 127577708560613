import check from 'check-types';

import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { FilterParams } from '@/fine-tune/types/query.types';

import { useFilterParams } from '../use-filters-params/use-filter-params';
import { useCurrentProject } from '../../../core/hooks/query-hooks/use-current-project/use-current-project';
import { useCurrentRun } from '../../../core/hooks/query-hooks/use-current-run/use-current-run';

interface UseCreateDqCodeSnippetArgs {
  huggingFaceTaggingSchema?: string;
  isHuggingFaceFormat?: boolean;
  includeMeta?: boolean;
}

const NEW_LINE_INDENT = '\n    ';

export const useCreateDqCodeSnippet = (
  args?: UseCreateDqCodeSnippetArgs,
  isEditsCart?: boolean
) => {
  const split = useParametersStore((state) => state.split);
  const inferenceName = useParametersStore((state) => state.inferenceName);

  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const selectedRows = useStore((state) => state.selectedRows);

  // Query Hooks
  const run = useCurrentRun();
  const project = useCurrentProject();

  // Utility hooks
  const filterParams = useFilterParams();

  const getter = isEditsCart ? 'get_edited_dataframe' : 'get_dataframe';

  // Remove falsy or empty array values, and convert back to object
  const filtered: Partial<FilterParams> = Object.entries(filterParams).reduce(
    (acc: Record<string, string>, [key, value]) => {
      if (
        value &&
        (check.nonEmptyString(value) ||
          check.nonEmptyObject(value) ||
          check.nonEmptyArray(value) ||
          check.boolean(value))
      ) {
        const parsedValue = value === true ? 'true' : value;
        // @ts-expect-error FIXME
        acc[key] = parsedValue;
      }

      return acc;
    },
    {}
  );

  if (allRowsSelected && selectedRows?.length) {
    filtered.exclude_ids = selectedRows;
  }

  // JSON stringify object
  const stringified = JSON.stringify(filtered);

  // Conditionally rendered key value pairs as strings
  const inferenceNameString =
    NEW_LINE_INDENT + `inference_name="${inferenceName}",`;
  const pfString = NEW_LINE_INDENT + `filter=${stringified},`;
  const hfString =
    NEW_LINE_INDENT +
    `hf_format=True,` +
    NEW_LINE_INDENT +
    `tagging_schema="${args?.huggingFaceTaggingSchema}",`;

  // Handle conditional rendering string
  const inferenceArg = inferenceName ? inferenceNameString : '';
  const filterArg =
    !isEditsCart && check.nonEmptyObject(filtered) ? pfString : '';

  const hfArg =
    args?.isHuggingFaceFormat && args?.huggingFaceTaggingSchema ? hfString : '';

  const withMetaCols = args?.includeMeta;

  // Code snippet - indentation and spacing is very important
  const dqSnippet = `
import dataquality as dq

dq.login()

df = dq.metrics.${getter}(
    project_name="${project?.data?.name ?? ''}",
    run_name="${run?.data?.name ?? ''}",
    split="${split}",${inferenceArg}${filterArg}${hfArg}${
      withMetaCols ? '\n    meta_cols=["*"],' : ''
    }
)
`;

  return dqSnippet;
};
