import {
  Button,
  createTheme,
  HoverCard,
  Input,
  MantineProvider
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import buttonClasses from '@/core/styles/button.module.css';
import hoverCardClasses from '@/core/styles/hovercard.module.css';
import inputClasses from '@/core/styles/input.module.css';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useGlobalHotkeys } from '@/core/hooks/use-global-hotkeys/use-global-hotkeys';
import { modals } from '@/core/modals/modals';
import themes from '@/core/themes/mantine';
// eslint-disable-next-line
import { modals as evaluateModals } from '@/evaluate/modals/modals';
// eslint-disable-next-line
import { modals as fineTuneModals } from '@/fine-tune/modals/modals';
// eslint-disable-next-line
import { modals as observeModals } from '@/observe/modals/modals';

import { ReactQueryProvider } from '../react-query-provider/react-query-provider';

const modalsZIndex = 2080;
const notificationZIndex = modalsZIndex + 999;

interface GlobalHotkeysProviderProps {
  children: React.ReactNode;
}

const GlobalHotkeyProvider = ({ children }: GlobalHotkeysProviderProps) => {
  const { parsedHotkeys } = useGlobalHotkeys();

  useHotkeys(parsedHotkeys);

  return <>{children}</>;
};

interface MantineAndReactQueryProviderProps {
  children?: React.ReactNode;
  pageProps: object;
  withAuth: boolean;
}

const theme = createTheme({
  fontFamily: 'Inter, sans-serif',
  ...themes.light,
  defaultRadius: 8,
  components: {
    Loader: {
      defaultProps: {
        type: 'bars'
      }
    },
    Text: {
      defaultProps: {
        size: 'sm'
      }
    },
    ActionIcon: {
      defaultProps: {
        variant: 'transparent',
        color: 'gray'
      }
    },
    HoverCard: HoverCard.extend({
      classNames: {
        dropdown: hoverCardClasses.dropdown
      }
    }),
    Input: Input.extend({ classNames: inputClasses }),
    InputLabel: {
      defaultProps: {
        size: 'sm',
        c: 'gray.6'
      }
    },
    Button: Button.extend({ classNames: buttonClasses }),
    Tooltip: {
      defaultProps: {
        style: {
          zIndex: Z_INDEX.TOOLTIPS
        }
      }
    }
  }
});

const MantineAndReactQueryProvider = ({
  children,
  pageProps,
  withAuth
}: MantineAndReactQueryProviderProps) => {
  const allModals = {
    ...modals,
    ...fineTuneModals,
    ...evaluateModals,
    ...observeModals
  };
  return (
    <MantineProvider forceColorScheme='light' theme={theme}>
      <Notifications
        limit={1}
        position='top-center'
        zIndex={notificationZIndex}
      />
      <ReactQueryProvider pageProps={pageProps} withAuth={withAuth}>
        <ModalsProvider
          modalProps={{ zIndex: modalsZIndex }}
          modals={allModals}
        >
          <GlobalHotkeyProvider>{children}</GlobalHotkeyProvider>
        </ModalsProvider>
      </ReactQueryProvider>
    </MantineProvider>
  );
};

export default MantineAndReactQueryProvider;
