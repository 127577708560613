import { TimeUnit } from 'chart.js';

import { TimeType } from '@/observe/types/time.types';

export const TIME_RANGE_DELIMITER = '__';

export const TIME_TYPE_LABELS: { [key in TimeType]: string } = {
  last5Mins: 'Last 5 Minutes',
  last15Mins: 'Last 15 Minutes',
  last30Mins: 'Last 30 Minutes',
  lastHour: 'Last Hour',
  last3Hours: 'Last 3 Hours',
  last6Hours: 'Last 6 Hours',
  last12Hours: 'Last 12 Hours',
  last24Hours: 'Last 24 Hours',
  last2Days: 'Last 2 Days',
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  lastYear: 'Last Year',
  custom: 'Custom'
};

export const TIME_UNIT_DISPLAY_FORMATS: { [key in TimeUnit]: string } = {
  millisecond: 'ss:SSS', // 25:750
  second: 'LTS', // 8:30:25 PM
  minute: 'LT', // 8:30 PM
  hour: 'LT', // 8:30 PM
  day: 'MMM Do', // Jun 6th
  week: 'MMM Do', // Jun 6th
  month: 'MMM', // Jun
  quarter: 'MMM', // Jun
  year: 'MMM YYYY' // Jun 2023
};
