import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { LLMStoreState } from './llm.store.types';

const defaultValues = {
  showHighDEPWords: false,
  showPriorityHeatmap: false
};

export const useLLMStore = createWithEqualityFn<LLMStoreState>()(
  (set) => ({
    ...defaultValues,
    actions: {
      resetLLMStore: () => set(() => ({ ...defaultValues })),
      setShowHighDEPWords: (bool) => set(() => ({ showHighDEPWords: bool })),
      setShowPriorityHeatmap: (bool) =>
        set(() => ({ showPriorityHeatmap: bool }))
    }
  }),
  shallow
);
