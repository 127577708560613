import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  ComputerVisionStoreState,
  ImageType
} from './computer-vision.store.types';

const defaultState = {
  errorDistributionCol: null,
  errorDistributionError: null,
  hideGold: false,
  hidePred: false,
  isEmbeddingsPointActive: false,
  isTransparent: true,
  firstImage: ImageType.GOLD,
  secondImage: ImageType.PRED,
  modalIndex: 0,
  modalTitle: ''
};

export const useComputerVisionStore =
  createWithEqualityFn<ComputerVisionStoreState>()(
    (set) => ({
      ...defaultState,
      actions: {
        clearState: () => set(() => ({ ...defaultState })),

        toggleGold: () => set((s) => ({ hideGold: !s.hideGold })),
        togglePred: () => set((s) => ({ hidePred: !s.hidePred })),
        toggleIsTransparent: () =>
          set((s) => ({ isTransparent: !s.isTransparent })),
        setErrorDistributionCol: (val) =>
          set(() => ({ errorDistributionCol: val })),
        setErrorDistributionError: (val) =>
          set(() => ({ errorDistributionError: val })),
        setIsEmbeddingsPointActive: (val) =>
          set(() => ({ isEmbeddingsPointActive: val })),
        setFirstImageType: (val: ImageType) =>
          set(() => ({
            firstImage: val
          })),
        setSecondImageType: (val: ImageType) =>
          set(() => ({
            secondImage: val
          })),
        setModalIndex: (val: number) => set(() => ({ modalIndex: val })),
        setModalTitle: (val: string) => set(() => ({ modalTitle: val }))
      },
      ...defaultState
    }),
    shallow
  );
