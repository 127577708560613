import {
  ActionIcon,
  Box,
  Checkbox,
  Group,
  Paper,
  TextInput,
  Tooltip
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconPencil } from '@tabler/icons-react';
import { ChangeEvent, useState } from 'react';

interface EditableCheckboxProps {
  onCheckboxChange: (
    label: string,
    isChecked: boolean,
    newLabel: string
  ) => void;
  checked?: boolean;
  label: string;
  id: string;
  secondaryText?: string | React.ReactNode;
  disabled?: boolean;
}

const EditableCheckbox = ({
  id,
  label,
  checked = false,
  onCheckboxChange,
  secondaryText,
  disabled
}: EditableCheckboxProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [columnText, setColumnText] = useState(label);

  const { hovered, ref } = useHover();

  const updateColumnText = () => {
    setIsEditing(false);
    onCheckboxChange(id, checked, columnText);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(id, e.target.checked, columnText);
  };

  const renderEditIcon = Boolean(hovered && !isEditing && !disabled);

  return (
    <Paper
      withBorder
      data-testid='editable-checkbox'
      mb={4}
      px='xs'
      radius='md'
      ref={ref}
      style={{
        background: hovered
          ? 'var(--mantine-color-gray-1)'
          : 'var(--mantine-color-gray-0)'
      }}
    >
      <Group className='w-100' justify='space-between'>
        <Box className='align-center'>
          <Checkbox
            checked={checked}
            color='brand'
            disabled={disabled}
            label={isEditing ? '' : columnText}
            mr={isEditing ? 0 : 8}
            p={4}
            py={6}
            size='sm'
            onChange={handleCheckboxChange}
          />

          {isEditing && (
            <TextInput
              autoFocus
              size='xs'
              value={columnText}
              onBlur={updateColumnText}
              onChange={(e) => setColumnText(e.target.value)}
            />
          )}

          {renderEditIcon && (
            <Tooltip
              withArrow
              withinPortal
              label='Edit column name'
              openDelay={500}
              zIndex={9999}
            >
              <ActionIcon
                data-testid='edit-icon'
                onClick={() => setIsEditing(true)}
              >
                <IconPencil size={18} />
              </ActionIcon>
            </Tooltip>
          )}
        </Box>

        {secondaryText}
      </Group>
    </Paper>
  );
};

export default EditableCheckbox;
