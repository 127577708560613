import { shallow } from 'zustand/shallow';

import { useParametersStore } from '../parameters.store';

export const useStoreDepValues = () => {
  const depObj = useParametersStore(
    (s) => ({ depLow: s.depLow, depHigh: s.depHigh }),
    shallow
  );

  return depObj;
};

export const getStoreDepValues = () => {
  const { depLow, depHigh } = useParametersStore.getState();

  return { depLow, depHigh };
};
