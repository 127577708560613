import { Text } from '@mantine/core';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useDataEdits } from '@/fine-tune/hooks/query-hooks/use-data-edits/use-data-edits';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';

/**
 * Counter
 *
 *
 *
 * @returns {React.Component} Counter
 */
const Counter = () => {
  const isNer = useComputedParameters('isNer');
  const { samples } = useDataEdits();

  const { sample_count, span_count } = samples?.data?.split_run_results || {};

  const count = isNer ? span_count : sample_count;
  const copy = isNer ? 'spans' : 'samples';

  return (
    <Text c='dimmed' size='sm'>
      {count ?? STRING_PLACEHOLDER} {copy} found
    </Text>
  );
};

export default Counter;
