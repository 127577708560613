import { IconDownload } from '@tabler/icons-react';
import Image from 'next/image';

import { GSCIcon, S3Icon } from '@/core/constants/brands.icons.constants';

import {
  TASK_TYPE,
  TaskType
} from '../../core/constants/tasks-and-frameworks.constants';
import databricks from '../../../public/databricks.png';
import python from '../../../public/python.png';
import snowflake from '../../../public/snowflake.png';

interface Destination {
  hidden: TaskType[];
  icon?: JSX.Element;
  id: string;
  label: string;
  remote?: boolean;
}

export const EXPORT_DESTINATIONS: Destination[] = [
  {
    label: 'Download',
    icon: <IconDownload size={20} />,
    id: 'local',
    hidden: []
  },
  {
    label: 'Get this dataframe',
    icon: <Image alt='python logo' height={20} src={python} width={20} />,
    id: 'dataframe',
    hidden: [TASK_TYPE.OD]
  },
  {
    label: 'Amazon S3',
    icon: <S3Icon />,
    remote: true,
    id: 's3',
    hidden: []
  },
  {
    label: 'Databricks',
    icon: <Image alt='python logo' height={20} src={databricks} width={20} />,
    id: 'databricks',
    hidden: [TASK_TYPE.OD]
  },
  {
    label: 'Snowflake',
    icon: (
      // TODO: Replace with SVG
      <Image alt='delta lake logo' height={20} src={snowflake} width={20} />
    ),
    id: 'snowflake',
    hidden: [TASK_TYPE.OD]
  },
  {
    label: 'GCS',
    icon: <GSCIcon />,
    id: 'gcs',
    hidden: [TASK_TYPE.OD]
  }
];

export const getExportTypes = (isHfNer?: boolean) => [
  {
    label: '.csv',
    id: 'csv',
    value: 'csv',
    disabled: isHfNer
  },
  {
    label: '.arrow',
    id: 'arrow',
    value: 'arrow',
    disabled: false
  },
  {
    label: '.parquet',
    id: 'parquet',
    value: 'parquet',
    disabled: false
  },
  {
    label: '.json',
    id: 'json',
    value: 'json',
    disabled: isHfNer
  }
];

export const OdExportTypes = [
  {
    label: 'YOLO',
    id: 'yolo',
    value: 'yolo'
  },
  {
    label: 'HuggingFace',
    id: 'huggingface',
    value: 'huggingface',
    disabled: true
  },
  {
    label: 'COCO',
    id: 'coco',
    value: 'coco',
    disabled: true
  }
];
