import { Checkbox, Group, Text } from '@mantine/core';

import {
  GroundTruthIcon,
  PredictedIcon
} from '@/core/constants/icons.constants';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';

export interface GoldPredToggleProps {
  goldChecked?: boolean;
  predChecked?: boolean;
  handleChange?: (val: string) => void;
}

/**
 * GoldPredToggle
 *
 *
 *
 * @returns {React.Component} GoldPredToggle
 */
const GoldPredToggle = ({
  goldChecked,
  predChecked,
  handleChange
}: GoldPredToggleProps) => {
  // Computer Vision Store
  const { hideGold, hidePred, actions } = useComputerVisionStore();

  return (
    <Group data-testid='gold-pred-toggle'>
      <Checkbox
        checked={goldChecked ?? !hideGold}
        className='grid-toggle'
        label={
          <Group>
            <GroundTruthIcon />
            <Text>Ground Truth</Text>
          </Group>
        }
        onChange={() =>
          handleChange ? handleChange('gold') : actions.toggleGold()
        }
      />
      <Checkbox
        checked={predChecked ?? !hidePred}
        className='grid-toggle'
        label={
          <Group>
            <PredictedIcon />
            <Text size='sm'>Predicted</Text>
          </Group>
        }
        onChange={() =>
          handleChange ? handleChange('pred') : actions.togglePred()
        }
      />
    </Group>
  );
};

export default GoldPredToggle;
