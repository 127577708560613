const generateMark = (value: string, replace?: string) => {
  const markClass = replace ? 'strike-through mark' : 'mark';

  const mark = `<mark class=${markClass} data-testid='mark'>${value}</mark>`;

  const rep = `<span class='replace' data-testid='replace'>${replace}</span>`;

  if (replace) {
    return mark + rep;
  }

  return mark;
};

interface HighlightWithReplaceProps {
  find: string;
  replace?: string;
  fullText: string;
}

/**
 * HighlightWithReplace
 *
 *
 *
 * @returns {React.Component} HighlightWithReplace
 */
const HighlightWithReplace = ({
  find,
  replace,
  fullText = ''
}: HighlightWithReplaceProps) => {
  const htmlString = fullText?.replace(new RegExp(find, 'gi'), (match) =>
    generateMark(match, replace)
  );
  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlString }}
      data-testid='highlight-with-replace'
    />
  );
};

export default HighlightWithReplace;
