import check from 'check-types';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export const parsePercentage = (
  decimalValue?: number | null,
  toFixedValue = 2
) => {
  if (typeof decimalValue !== 'number') {
    return STRING_PLACEHOLDER;
  }

  const raw = decimalValue * 100;

  if (check.infinity(raw) || check.not.number(raw)) {
    return STRING_PLACEHOLDER;
  }

  // Prevents '100.00%' from being returned
  // @ts-expect-error
  const parsed = raw !== 100 ? raw.toFixed(toFixedValue) : raw;

  return `${parsed}%`;
};
