import { Box, Grid, List, Text, Title } from '@mantine/core';

import ModKbd from '@/core/components/atoms/mod-kbd/mod-kbd';
import { useGlobalHotkeys } from '@/core/hooks/use-global-hotkeys/use-global-hotkeys';

interface HotKeyDefinition {
  title: string;
  description: string;
  binding: string;
  callback: () => void;
}

interface HotkeyDescriptionProps {
  character: string;
  withShift: boolean;
  hotkey: HotKeyDefinition;
  withoutMod?: boolean;
}

const HotkeyDescription = ({
  character,
  withShift,
  hotkey,
  withoutMod
}: HotkeyDescriptionProps) => {
  return (
    <List.Item
      icon={
        <ModKbd
          character={character}
          withoutMod={withoutMod}
          withShift={withShift}
        />
      }
    >
      <Title order={6}>{hotkey.title}</Title>
      <Text size='xs'>{hotkey.description}</Text>
    </List.Item>
  );
};

const KeyboardShortcutsModal = () => {
  const { hotkeys } = useGlobalHotkeys();

  const hotkeysWithShift = hotkeys.filter(({ binding }) =>
    binding.includes('shift')
  );
  const hotkeysWithoutShift = hotkeys.filter(
    ({ binding }) => !binding.includes('shift')
  );

  const getModKbdProps = (hotkey: HotKeyDefinition) => {
    const splitBinding = hotkey.binding.split('+');
    const withoutMod = splitBinding.length === 1;
    const withShift = splitBinding[1] === 'shift';

    const getCharacter = () => {
      if (withoutMod) {
        return splitBinding[0];
      }

      if (withShift) {
        return splitBinding[2];
      }

      return splitBinding[1];
    };

    return { character: getCharacter(), withShift, withoutMod };
  };

  return (
    <Box data-testid='keyboard-shortcuts-modal'>
      <Grid>
        <Grid.Col span={6}>
          <List withPadding spacing='md'>
            {hotkeysWithoutShift
              .sort((a, b) => a.binding.localeCompare(b.binding))
              .map((hotkey) => {
                const { character, withShift, withoutMod } =
                  getModKbdProps(hotkey);

                return (
                  <HotkeyDescription
                    character={character}
                    hotkey={hotkey}
                    key={hotkey.title}
                    withoutMod={withoutMod}
                    withShift={withShift}
                  />
                );
              })}
          </List>
        </Grid.Col>

        <Grid.Col span={6}>
          <List withPadding spacing='md'>
            {hotkeysWithShift
              .sort((a, b) => a.binding.localeCompare(b.binding))
              .map((hotkey) => {
                const { character, withShift, withoutMod } =
                  getModKbdProps(hotkey);

                return (
                  <HotkeyDescription
                    character={character}
                    hotkey={hotkey}
                    key={hotkey.title}
                    withoutMod={withoutMod}
                    withShift={withShift}
                  />
                );
              })}
          </List>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default KeyboardShortcutsModal;
