import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  Input,
  Paper,
  ScrollArea,
  Skeleton,
  Text,
  useMantineTheme
} from '@mantine/core';
import { ChangeEvent, useState } from 'react';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { ContextModalPropsWithRowId } from '@/core/hooks/use-modals/use-modals.types';
import Label from '@/fine-tune/components/label/label';
import Select from '@/fine-tune/components/select/select';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { CreateEditArgs } from '@/fine-tune/types/mutation.types';

const RelabelModal = ({ id, innerProps }: ContextModalPropsWithRowId) => {
  const [changeToLabel, setChangeToLabel] = useState('');
  const [reason, setReason] = useState('');
  const [changeToPred, setChangeToPred] = useState(true);

  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const isOd = useComputedParameters('isOd');

  const { createEditMutation, changesByLabel } = useEditCreate(
    innerProps?.rowId,
    isOd ? 'pred' : undefined
  );
  const labels = useLabels();

  const { colors } = useMantineTheme();
  const { closeModal } = useModals();

  const handleReasonUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleSubmit = () => {
    const body: CreateEditArgs = {
      note: reason,
      edit_action: changeToPred
        ? EDIT_ACTIONS.RELABEL_AS_PRED
        : EDIT_ACTIONS.RELABEL
    };

    if (changeToLabel) {
      body.new_label = changeToLabel;
    }

    createEditMutation.mutate(body);
    closeModal(id);
  };

  return (
    <Box>
      <Text c='gray' fw={500} size='sm'>
        From
      </Text>
      <Paper withBorder my={8} p='sm'>
        <ScrollArea style={{ height: 100 }}>
          {Object.entries(changesByLabel || {}).map(([label, amount]) => (
            <Group justify='space-between' key={label} mb={4}>
              <Label labelName={label || 'Ghost span'} size='sm' />
              <Text c='gray' size='sm'>
                <Text c='gray' component='span' fw={600} size='sm'>
                  <>
                    {allRowsSelected && '+'}
                    {amount}
                  </>
                </Text>{' '}
                selected
              </Text>
            </Group>
          ))}
        </ScrollArea>
      </Paper>
      <Checkbox
        checked={changeToPred}
        label='Relabel to Predicted'
        mt='sm'
        onChange={() => setChangeToPred((old) => !old)}
      />
      <Text c='gray' fw={500} mt={12} size='sm'>
        To
      </Text>
      <div className='col-span-3 pt-0.5 text-sm text-gray-800'>
        {labels.isLoading ? (
          <Skeleton height={40} />
        ) : (
          <Select
            comboboxProps={{ withinPortal: false }}
            data={labels?.data?.labels || []}
            disabled={changeToPred}
            mr='sm'
            size='sm'
            value={changeToLabel}
            onChange={(value) => setChangeToLabel(value as string)}
          />
        )}
      </div>
      <Text c='gray' fw={500} mt={12} size='sm'>
        Reason for change (optional)
      </Text>
      <Input onChange={handleReasonUpdate} />
      <Grid mt={12}>
        <Grid.Col span={6}>
          <Text c={colors.gray[6]} size='xs'>
            You can always undo this change from the data change log
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button color='brand' variant='subtle' onClick={() => closeModal(id)}>
            Cancel
          </Button>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button
            color='brand'
            disabled={!changeToPred && !changeToLabel}
            id='relabel-modal-submit'
            variant='filled'
            onClick={handleSubmit}
          >
            Change
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default RelabelModal;
