import { CodeHighlight } from '@mantine/code-highlight';
import { Box, Radio, Text } from '@mantine/core';
import { useState } from 'react';

import { useGetPromptTemplates } from '@/evaluate/hooks/query-hooks/use-prompt-template/use-get-prompt-templates/use-get-prompt-templates';
import useCreateTemplateCodeSnippet from '@/evaluate/hooks/use-create-template-code-snipppet/use-create-template-code-snippet';
import { IPromptRunParameters } from '@/evaluate/hooks/use-prompt-run-parameters/use-prompt-run-parameters';
import { getTemplateKeywords } from '@/evaluate/utils/get-template-keywords/get-template-keywords';

interface TemplateCodeModalProps {
  innerProps: {
    promptRunParams: IPromptRunParameters;
  };
}

const TemplateCodeModal = ({ innerProps }: TemplateCodeModalProps) => {
  const { promptRunParams } = innerProps;

  // Local State
  const [value, setValue] = useState('LangChain');

  // Hooks
  const { langChainSnippet, openAISnippet, cURLSnippet } =
    useCreateTemplateCodeSnippet();

  const { data: templates } = useGetPromptTemplates();

  // Computed
  const currentTemplate = templates?.find(
    (template) => template.id === promptRunParams.internal.templateId
  );

  const currentTemplateVersion = currentTemplate?.all_versions?.find(
    (version) => version.id === promptRunParams.internal.templateVersionId
  );

  const buildSnippetValues = () => {
    const { templateKeywords } = getTemplateKeywords(
      currentTemplateVersion?.template || '',
      9999
    );

    return {
      template: currentTemplateVersion?.template ?? '',
      templateKeywords
    };
  };

  const { template, templateKeywords } = buildSnippetValues();

  let snippet = langChainSnippet(promptRunParams, template, templateKeywords);
  let language: any = 'python'; // Language interface not exported

  if (value === 'OpenAI') {
    snippet = openAISnippet(promptRunParams, template, templateKeywords);
  }

  if (value === 'cURL') {
    language = 'bash';
    snippet = cURLSnippet(promptRunParams, template, templateKeywords);
  }

  return (
    <Box data-testid='template-code-modal'>
      <Text c='contrast.4' size='sm'>
        Options
      </Text>
      <Radio.Group
        required
        display='block'
        mb='lg'
        py='sm'
        value={value}
        w='100%'
        onChange={setValue}
      >
        <Radio
          className='box-outline'
          display='inline-block'
          label='LangChain'
          mr='sm'
          value='LangChain'
        />
        <Radio
          className='box-outline'
          display='inline-block'
          label='OpenAI'
          mr='sm'
          value='OpenAI'
        />
        <Radio
          className='box-outline'
          display='inline-block'
          label='cURL'
          mr='sm'
          value='cURL'
        />
      </Radio.Group>
      <CodeHighlight code={snippet} language={language} />
    </Box>
  );
};

export default TemplateCodeModal;
