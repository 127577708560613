import { useQuery } from '@tanstack/react-query';

import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const IS_DV_ENABLED_KEY = 'IS_DV_ENABLED';

export const DV_ENABLED_COPY =
  'Pre-trained model embeddings capturing the general semantic relationships data';

export const DV_DISABLED_COPY =
  'Pre-trained model embeddings capturing general semantic relationships within the data. To see data embeddings in your run, set create_data_embs=True when calling dq.finish or dq.auto';
export const MV_DISABLED_COPY =
  'Fine-tuned embeddings provided by the user. To enable these make sure to log embeddings in dataquality';
import api from '@/core/api';
const GET_ARE_EMBEDDINGS_ENABLED =
  '/projects/{project_id}/runs/{run_id}/split/{split}/embeddings/available';

export const useAreEmbeddingsEnabled = () => {
  const { runId, projectId } = usePathParameters();

  const split = useParametersStore((s) => s.split);

  const enabled = Boolean(projectId && runId && split);

  const fetchAreEmbeddingsEnabled = async () => {
    const res = await api.GET(GET_ARE_EMBEDDINGS_ENABLED, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [IS_DV_ENABLED_KEY, { projectId, runId, split }],
    fetchAreEmbeddingsEnabled,
    { enabled, onError: () => null }
  );

  return response;
};
