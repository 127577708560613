// This is experimental - noticed keeping track of specific colors
// for given items was getting a bit complex. Hoping this might help
// maintain a single source of truth. Happy to abandon if more PITA than help

import { ExtendedCustomColors } from '@/core/themes/module';

// Only colors that are reused and need to remain consistent should go here

interface ColorData {
  color: ExtendedCustomColors;
  shade: number;
}
export const DATA_COLORS = [
  '#20795d',
  '#9791f3',
  '#104793',
  '#1fd0bf',
  '#660a0a',
  '#00c2ff',
  '#9db600',
  '#b6006e',
  '#9aea3e',
  '#8b572a',
  '#ef80a0',
  '#2f8dfa',
  '#dfbf03',
  '#de4b16',
  '#5df174',
  '#55008c',
  '#f559b6',
  '#00bb8f',
  '#ba26ff',
  '#e8871a'
];

export const DRIFTED_COLOR: ColorData = {
  color: 'orange',
  shade: 2
};

export const ON_THE_BOUNDARY_COLOR: ColorData = {
  color: 'yellow',
  shade: 2
};

export const HARD_COLOR: ColorData = {
  color: 'red',
  shade: 4
};
export const EASY_COLOR: ColorData = {
  color: 'green',
  shade: 4
};
export const MISCLASSIFIED_COLOR: ColorData = {
  color: 'yellow',
  shade: 2
};

export const DEFAULT_BAR_CHART_COLOR: ColorData = {
  color: 'brand',
  shade: 3
};

export const MISLABELED_COLOR: ColorData = {
  color: 'orange',
  shade: 1
};
