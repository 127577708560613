import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export type DateConversion<OptionsType = undefined> = (
  value: Date,
  options?: OptionsType
) => Date;

// Top-level keys are the input unit types
// Second-level keys are the output unit types
export type DateConversionMap = {
  date: {};
  // Add other conversions here...
};

// Type guarding is done in the constructor of the Unit class.
export const dateConversions: DateConversionMap = {
  date: {}
};

export class DateUnit<InputUnitType extends keyof DateConversionMap = 'date'> {
  private readonly initialValue: Date | String | Number | undefined;
  private value: Date | undefined;

  private unitType: InputUnitType;

  /**
   *
   * @param {String | Date} value - The value to convert
   * @param {InputUnitType} unitType - The unit type of the value to convert
   */
  constructor(
    value: Date | String | undefined,
    unitType: InputUnitType = 'string' as InputUnitType
  ) {
    // Store the initial value for error messages.
    this.initialValue = value;

    // Set the unitType of the value.
    this.unitType = unitType;

    // Set the initial value.
    this.value = this.typeGuard(value);
  }

  // Method to convert the value to a string.
  // ex. const date = new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738));
  //     new DateUnit(date).toString() => '12/20/20'
  //     new DateUnit(date).toString({{ dateStyle: 'short', timeStyle: 'long' }}) => '12/19/20, 10:23:00 PM PST'
  public toString(
    options: Intl.DateTimeFormatOptions = {},
    fallback: string = STRING_PLACEHOLDER
  ): string {
    if (!this.value) {
      return fallback;
    }

    return new Intl.DateTimeFormat('en-US', options).format(this.value);
  }

  // Method to type guard the initial value.
  // This is used in the constructor to ensure that the initial value is properly guarded.
  private typeGuard(value: unknown) {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      value instanceof Date
    ) {
      // Try to create a Date object from the value
      const dateObject = new Date(value);
      const isDate = !isNaN(dateObject.getTime());

      if (isDate) {
        return dateObject;
      }
      this.throw(`Invalid Date: Cannot convert Date '${value}'`);
      return undefined;
    }

    this.throw(`Invalid Date: Cannot convert Date '${value}'`);
    return undefined;
  }

  private throw(msg?: string) {
    msg;
    // console.warn(
    //   msg ??
    //     `Invalid value: Cannot convert value '${this.initialValue}' to unit type '${this.unitType}'`
    // );
  }
}
