import { useParametersStore } from '../parameters.store';

export const useActiveFilters = (asObj = false) => {
  const { getActiveFilters } = useParametersStore((s) => s.differs);

  return getActiveFilters(asObj);
};

export const getActiveFilters = (asObj = false) => {
  return useParametersStore.getState().differs.getActiveFilters(asObj);
};
