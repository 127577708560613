import { Textarea } from '@mantine/core';

import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';

/**
 * Notes
 *
 *
 *
 * @returns {React.Component} Notes
 */
const Notes = () => {
  const { setNotes } = useDataEditsStore((state) => state.actions);

  return (
    <Textarea
      autosize
      label='Reason for change (optional)'
      maxRows={4}
      minRows={4}
      mt='sm'
      placeholder='Write here'
      onChange={(e) => setNotes(e.currentTarget.value)}
    />
  );
};

export default Notes;
