import { ActionIcon, Box, Group, Image, Text } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconClipboardCheck, IconClipboardPlus } from '@tabler/icons-react';
import { useEffect } from 'react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import ClampedLine from '@/core/components/atoms/clamped-line/clamped-line';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { getFileName } from '@/core/utils/get-file-name/get-file-name';
import DepOrConfidence from '@/fine-tune/components/dep-or-confidence/dep-or-confidence';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import { PointRow } from '@/fine-tune/types/embeddings.types';
import { Meta } from '@/fine-tune/types/query.types';

interface CvModalProps {
  innerProps: {
    sample: PointRow;
  };
}

const CvModal = ({ innerProps }: CvModalProps) => {
  let { sample } = innerProps || {};

  // Computer Vision State
  const { modalIndex, actions } = useComputerVisionStore((s) => ({
    modalIndex: s.modalIndex,
    actions: s.actions
  }));

  const { setModalTitle } = actions;

  // Hooks
  const clipboard = useClipboard({ timeout: 2000 });

  const {
    data: insightsRowsData,
    hasNextPage,
    fetchNextPage
  } = useInsightsRows();

  // Computed
  const samples =
    insightsRowsData?.pages?.flatMap((page) => page?.data_rows) || [];

  sample = sample || samples[modalIndex];

  const { image, gold, meta, pred } = sample || {};

  const metaColumnNames = Object.keys(meta || {}) || [];

  const mappedMetaColumns =
    metaColumnNames?.map((column) => {
      return (
        <Box key={column} maw={450} miw={75}>
          <Text c='dimmed'>{column}</Text>
          <ClampedLine
            size='sm'
            // @ts-expect-error - API types meta values as never
            text={meta?.[column as keyof Meta]?.toString()}
          />
        </Box>
      );
    }) || [];

  const altString = `Image with predicted label: ${pred} and ground truth: ${gold}`;

  // Event Handlers
  const copyToClipboard = (e: React.MouseEvent, value: string) => {
    e?.stopPropagation();
    clipboard.copy(value);

    showNotification({
      title: 'Success!',
      message: 'Image Path copied to clipboard'
    });
  };

  // Effects
  useEffect(() => {
    const fileName =
      (sample?.image && getFileName(sample.image)) || STRING_PLACEHOLDER;
    setModalTitle(fileName);

    if (modalIndex === samples.length - 1 && hasNextPage) {
      fetchNextPage();
    }
  }, [modalIndex, sample?.image]);

  return (
    <Box data-testid='cv-modal'>
      <Box
        h={500}
        style={({ colors }) => ({
          overflow: 'hidden',
          background: colors.gray[1]
        })}
      >
        <Image
          alt={altString}
          fit='contain'
          height='500px'
          role='img'
          src={image}
        />
      </Box>
      <Box className='horizontal-flex-scroll' p='md'>
        <Box w={175}>
          <Text c='dimmed'>Ground Truth</Text>
          {gold ? <LabelBadge value={gold} /> : <DataNotAvailable />}
        </Box>
        <Box w={175}>
          <Text c='dimmed'>Predicted</Text>
          {pred ? <LabelBadge value={pred} /> : <DataNotAvailable />}
        </Box>
        <Box w={100}>
          <DepOrConfidence color='dimmed' component='div' sample={sample} />
        </Box>
        {mappedMetaColumns}
        <Box maw={450}>
          <Group wrap='nowrap'>
            <ActionIcon
              color={clipboard.copied ? 'green' : 'gray'}
              mb={2}
              variant={clipboard.copied ? 'filled' : 'light'}
              onClick={(e: React.MouseEvent) =>
                copyToClipboard(e, sample?.image || '')
              }
            >
              {clipboard.copied ? (
                <IconClipboardCheck size={16} />
              ) : (
                <IconClipboardPlus size={16} />
              )}
            </ActionIcon>

            <Text c='dimmed'>Image Path</Text>
          </Group>

          <ClampedLine clamp={1} size='sm' text={sample?.image || ''} />
        </Box>
      </Box>
    </Box>
  );
};

export default CvModal;
