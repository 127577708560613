import type {
  ButtonProps,
  ElementProps,
  FloatingPosition
} from '@mantine/core';
import { Tooltip } from '@mantine/core';
import { Button as MantineButton } from '@mantine/core';
import { forwardRef } from 'react';

interface BProps extends ButtonProps, ElementProps<'a', keyof ButtonProps> {
  copy: string | React.ReactNode | null;
  // FIXME: Figure out how to define ElementType<any>
  component?: any;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  tooltipCopy?: string | React.ReactNode;
  tooltipPlacement?: FloatingPosition;
  isLoading?: boolean;
  href?: string;
  target?: string;
  id?: string;
}

const Button = forwardRef(
  (
    {
      copy,
      component = 'button',
      onClick,
      variant = 'filled',
      leftSection,
      rightSection,
      fullWidth = false,
      tooltipCopy = null,
      tooltipPlacement = 'top',
      size = 'xs',
      isLoading = false,
      disabled = false,
      href,
      target,
      id,
      color = 'brand.5',
      ...styling
    }: BProps,
    ref
  ) => {
    return (
      <Tooltip
        multiline
        withArrow
        disabled={Boolean(!tooltipCopy)}
        label={tooltipCopy}
        maw={250}
        position={tooltipPlacement}
        zIndex={999999}
      >
        <span>
          {/*  We need to add this span to allow tooltips to show when button is disabled https://github.com/mantinedev/mantine/issues/2959 */}
          <MantineButton
            color={color}
            component={component}
            disabled={disabled}
            fullWidth={fullWidth}
            href={href}
            id={id}
            leftSection={leftSection}
            loaderPosition='right'
            loading={isLoading}
            ref={ref}
            rightSection={rightSection}
            size={size}
            target={target}
            variant={variant}
            onClick={onClick}
            {...styling}
          >
            {copy}
          </MantineButton>
        </span>
      </Tooltip>
    );
  }
);

export default Button;
