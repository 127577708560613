import { isJson } from '@/core/utils/is-json/is-json';

import { RouterParameters } from './use-query-parameters.types';

export function toRouter<T extends Record<string, any>>(
  params: Partial<T>
): RouterParameters {
  const routerParams: Record<string, string> = {};

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value) || (typeof value === 'object' && value != null)) {
      routerParams[key] = JSON.stringify(value);
    } else {
      routerParams[key] = value as string;
    }
  }

  return routerParams;
}

export function fromRouter<T extends Record<string, any>>(
  params: RouterParameters
): Partial<T> {
  const newParams: Partial<T> = {};

  for (const [key, value] of Object.entries(params)) {
    const typedKey = key as keyof T;

    // If value is a JSON string, parse it
    if (isJson(value)) {
      newParams[typedKey] = JSON.parse(value as string);
    }
    // If value is a boolean, parse it
    else if (value === 'true' || value === 'false') {
      newParams[typedKey] = parseBool(value) as T[keyof T];
    }
    // If value is a number, parse it
    else if (typeof value === 'string' && value.match(/^-{0,1}\d+$/)) {
      newParams[typedKey] = parseInt(value as string, 10) as T[keyof T];
    }
    // Edge cases
    else if (value === '[]') {
      newParams[typedKey] = [] as T[keyof T];
    }
    // If value is a string, assign it directly
    else {
      newParams[typedKey] = value as T[keyof T];
    }
  }

  return newParams;
}

export function parseBool(value: 'true' | 'false') {
  return value === 'true';
}
