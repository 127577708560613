import { useSessionStorage } from '@mantine/hooks';

import { FlagTags } from '@/core/types/feature-flags.types';

export const useABFlags = () => {
  const [rbacM2Flag, setRbacM2Flag] = useSessionStorage({
    key: 'rbac_m2',
    defaultValue: false
  });

  return {
    RBAC_M2: {
      description: 'Toggle RBAC User and Group M2.',
      isEnabled: rbacM2Flag,
      tag: [FlagTags.Feature],
      title: 'RBAC M2',
      toggle: () => setRbacM2Flag(!rbacM2Flag)
    }
  };
};
