import { components } from '../../core/types/api';

export type S2SSegment = components['schemas']['Seq2SeqSegment'];

export interface KTokens {
  prob: number;
  token: string;
}

export const S2S_EXTRA_COLUMNS = [
  'generated_output',
  'generated_uncertainty',
  'bleu',
  'rouge'
];

export interface S2SRow {
  id: number;
  confidence: number;
  edited: boolean;
  data_error_potential: number;
  input: string;
  generated_output: string;
  target: string;
  target_segments: S2SSegment[];
  generated_segments: S2SSegment[];
  insertion_errors: number;
  deletion_errors: number;
  substitution_errors: number;
  generated_uncertainty: number;
  bleu: number;
  rouge: number;
  bert_score: number;
  input_cutoff?: number;
  target_cutoff?: number;
  system_prompts?: Record<string, string>;
}
