export const CONFIDENCE_ACCESSOR = 'confidence';
export const DEP_ACCESSOR = 'data_error_potential';
export const GHOST_SPAN_ACCESSOR = 'ghost_span';
export const GOLD_ACCESSOR = 'gold';
export const ID_ACCESSOR = 'id';
export const MAX_DEP_ACCESSOR = 'max_dep';
export const MAX_DEP_LABEL_ACCESSOR = 'max_dep_label';
export const MISSED_LABEL_ACCESSOR = 'missed_label';
export const NOISE_TYPE_ACCESSOR = 'noise_type';
export const PRED_ACCESSOR = 'pred';
export const SAMPLE_ID_ACCESSOR = 'sample_id';
export const SPAN_SHIFT_ACCESSOR = 'span_shift';
export const TEXT_ACCESSOR = 'text';
export const TOTAL_ERRORS_ACCESSOR = 'total_errors';
export const WRONG_TAG_ACCESSOR = 'wrong_tag';
