import { DESC } from '@/core/constants/query-params.constants';

import { EvaluateParameters } from './query-parameters.types';

export const defaultStoreParams: EvaluateParameters = {
  chainNodeId: undefined,
  chainRootId: undefined,
  compareIds: [],
  datasetId: undefined,
  chainsRunsColumns: [
    'name',
    'total_responses',
    'average_cost',
    'total_cost',
    'average_latency',
    '_ratings',
    'average_factuality',
    'average_groundedness',
    'created_at',
    'created_by'
  ],
  chainsColumns: [
    'prompt',
    'response',
    '_ratings',
    'latency',
    'factuality',
    'groundedness'
  ],
  inputIndex: 0,
  insightsPanel: true,
  filters: [],
  projectId: undefined,
  promptColumns: [
    'prompt',
    'response',
    'target',
    'factuality',
    'groundedness',
    'toxicity',
    'context_relevance',
    'pii',
    'tone',
    'sexist',
    'latency',
    '_rating'
  ],
  rowId: undefined,
  runColumns: [
    'name',
    'model_alias',
    'template_name_with_version',
    'total_responses',
    'average_cost',
    'total_cost',
    'average_factuality',
    'average_groundedness',
    'average_toxicity',
    'average_relevance',
    'average_latency',
    'ratings',
    'created_at',
    'created_by'
  ],
  resultsRunId: undefined,
  runId: undefined,
  selectedMetrics: [
    'latency',
    'cost',
    'bleu',
    'rouge',
    'factuality',
    'context_adherence',
    'completeness'
  ],
  showRawValues: false,
  sortBy: 'created_at',
  sortDirection: DESC,
  templateId: undefined,
  templateVersionId: undefined,
  templateVersionNum: 0,
  tokenHighlight: undefined
};

// // TODO: Type out Evaluate specific paths
// export const persistedParams: Partial<
//   Record<Paths, Array<keyof EvaluateParameters>>
// > = {
//   [Paths.PROMPT_PROMPTS]: ['insightsPanel']
// };
