import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  Popover,
  Select,
  Text
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import {
  IconFilter,
  IconSortAscending,
  IconSortDescending
} from '@tabler/icons-react';
import { useState } from 'react';

import {
  useParametersStore,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { ERRORS_DICT } from '@/fine-tune/stores/parameters-store/parameters.store.types';
import { useErrorTypesMetaFilter } from '@/fine-tune/stores/parameters-store/use-store-meta-filters/use-store-meta-filters';
import { MetaFilter } from '@/fine-tune/types/query.types';

// Exporting for testing only
export const generateInitialValues = (errorFilter: MetaFilter | undefined) => {
  return Object.entries(ERRORS_DICT).map(([key, value]) => {
    const isIn = errorFilter?.isin as string[];
    return {
      label: value,
      checked: !isIn || isIn?.length === 0 || isIn?.includes(key),
      value: key
    };
  });
};
/**
 * ErrorTypeFilter
 *
 *
 *
 * @returns {React.Component} ErrorTypeFilter
 */
const ErrorTypeFilter = () => {
  const sortBy = useParametersStore((state) => state.sortBy);
  const sortDirection = useParametersStore((state) => state.sortDirection);

  const { setMetaParameters, setParameters } = useParametersStoreActions();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const errorMetaFilter = useErrorTypesMetaFilter();

  const [errorFilters, handlers] = useListState(
    generateInitialValues(errorMetaFilter)
  );

  const [sortByType, setSortByType] = useState(sortBy);
  const [sortByDirection, setSortByDirection] = useState(sortDirection);

  const applyFilters = () => {
    const checkedFilters = errorFilters.filter((f) => f.checked);

    if (checkedFilters.length === 4) {
      setMetaParameters([{ name: 'galileo_error_type', isin: [] }]);
    } else {
      setMetaParameters([
        { name: 'galileo_error_type', isin: checkedFilters.map((f) => f.value) }
      ]);
    }

    if (sortByType !== sortBy || sortByDirection !== sortDirection) {
      setParameters({ sortBy: sortByType, sortDirection: sortByDirection });
    }
  };

  const togglePopover = () => {
    if (popoverOpen) {
      applyFilters();
      setPopoverOpen(false);
    } else {
      setPopoverOpen(true);
    }
  };

  const handleSortChange = () => {
    const newSort = sortByDirection === 'asc' ? 'desc' : 'asc';
    setSortByDirection(newSort);
  };

  return (
    <Popover
      withArrow
      withinPortal
      aria-label='error type filter'
      data-testid='error-type-filter'
      opened={popoverOpen}
      position='bottom'
      width={240}
      onClose={togglePopover}
    >
      <Popover.Target>
        <ActionIcon>
          <IconFilter
            data-testid='error-type-target'
            size={16}
            onClick={togglePopover}
          />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Group gap='xs' justify='space-between'>
          <Text c='gray.5' fw={600} size='xs'>
            Error Types
          </Text>
          <Button
            color='brand.6'
            variant='subtle'
            onClick={() =>
              handlers.apply((filter) => ({
                ...filter,
                checked: false
              }))
            }
          >
            Deselect All
          </Button>
        </Group>
        {errorFilters.map(({ value, label, checked }, index) => (
          <Checkbox
            checked={checked}
            color='brand'
            key={value}
            label={label}
            mt='xs'
            onChange={(event) =>
              handlers.setItemProp(
                index,
                'checked',
                event.currentTarget.checked
              )
            }
          />
        ))}
        <Box className='position-relative'>
          <Select
            data={errorFilters}
            label='Sort by error type'
            mt='xs'
            placeholder='Select error type'
            role='searchbox'
            styles={{
              root: {
                width: '85%'
              }
            }}
            value={sortByType}
            width='80%'
            onChange={(value) => setSortByType(value as string)}
          />
          <ActionIcon
            style={{
              position: 'absolute',
              bottom: 4,
              right: 0
            }}
            onClick={handleSortChange}
          >
            {sortByDirection === 'asc' && <IconSortAscending size={20} />}
            {sortByDirection === 'desc' && <IconSortDescending size={20} />}
          </ActionIcon>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ErrorTypeFilter;
