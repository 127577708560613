import { Text, useMantineTheme } from '@mantine/core';
import chroma from 'chroma-js';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import {
  CONFIDENCE_KEY,
  DEP_KEY,
  GOLD_KEY,
  MISCLASSIFIED_KEY
} from '@/fine-tune/constants/embeddings.constants';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { ColorBy, PointRow } from '@/fine-tune/types/embeddings.types';
import { getConfidenceRangeKey } from '@/fine-tune/utils/build-bucket-ranges/build-bucket-ranges';

import { useThresholds } from '../query-hooks/use-thresholds/use-thresholds';

export const getColorThresholds = (values: number[]) => {
  let sum = values.reduce((a, b) => a + b, 0);
  let mean = sum / values.length;
  // Calculate the standard deviation
  let variance =
    values.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / values.length;
  let std = Math.sqrt(variance);

  let highThresh = mean + std;

  return {
    lowThresh: mean,
    highThresh
  };
};

export const getColorByThreshold = (
  value: number,
  lowThresh: number,
  highThresh: number
) => {
  if (value < lowThresh) {
    return '#FB6B49';
  } else if (value < highThresh) {
    return '#FEC53D';
  } else {
    return '#4ACC1E';
  }
};

export const getUncertaintyColor = (value: number) => {
  let color = '#FB7474';
  if (value < 0.025) {
    color = '#90e790';
  } else if (value < 0.05) {
    color = '#FDEEAF';
  }

  return color;
};

export const getBleuColor = (value: number) => {
  let color = 'green';

  // 0-100 scale
  if (value >= 0 && value < 25) {
    color = 'red';
  }

  if (value >= 25 && value < 50) {
    color = 'yellow';
  }

  return color;
};

export const getRougeColor = (value: number) => {
  let color = 'green';

  // 0-1 scale
  if (value >= 0 && value < 0.25) {
    color = 'red';
  }

  if (value >= 0.25 && value < 0.5) {
    color = 'yellow';
  }
  return color;
};

export const formatBleu = (value: number, hideSuffix?: boolean) => {
  if (typeof value !== 'number') return <DataNotAvailable />;

  let color = getBleuColor(value);

  return (
    <Text c={color} fw={700} size='sm'>
      {value?.toFixed(2)}
      {!hideSuffix && (
        <Text c='dimmed' display='inline' fw={400} size='sm'>
          {' '}
          out of 100
        </Text>
      )}
    </Text>
  );
};

export const formatRouge = (value: number, hideSuffix?: boolean) => {
  if (typeof value !== 'number') return <DataNotAvailable />;

  let color = getRougeColor(value);
  return (
    <Text c={color} fw={700} size='sm'>
      {value?.toFixed(2)}
      {!hideSuffix && (
        <Text c='dimmed' display='inline' fw={400}>
          {' '}
          out of 1
        </Text>
      )}
    </Text>
  );
};

export const useColors = () => {
  const { isInference } = useComputedParameters();
  const thresholds = useThresholds();
  const theme = useMantineTheme();

  const colorMap = useStore((state) => state.colorMap);
  const bucketRange = useEmbeddingsStore((state) => state.bucketRange);

  const {
    easy_samples_threshold: easyThreshold,
    hard_samples_threshold: hardThreshold
  } = thresholds?.data || {};

  const getDepColor = (dep: number) => {
    if (dep > (hardThreshold || 0)) {
      return 'red';
    } else if (dep < (easyThreshold || 0)) {
      return 'green';
    }
    return 'yellow';
  };

  const getDepOrConfidenceColor = (depOrConf: number | null | undefined) => {
    const fallbackColor = theme.colors.yellow[5];
    if (isInference) {
      if (!depOrConf) return fallbackColor;

      const key = getConfidenceRangeKey(depOrConf);

      const range = bucketRange?.find((range) => range.min === +key);

      return range?.color || theme.colors.yellow[5];
    }

    return depOrConf ? getDepColor(depOrConf) : fallbackColor;
  };

  const getClassOverlapColor = (overlapScore: number) => {
    const range = chroma
      .scale([theme.colors.yellow[5], theme.colors.red[5]])
      .domain([0, 5]);

    return overlapScore ? range(overlapScore).hex() : 'black';
  };

  const getTooltipBorderColor = (
    colorBy: ColorBy,
    sample: PointRow
  ): string => {
    const {
      gold,
      pred,
      data_error_potential: dep,
      confidence: conf
    } = sample || {};

    switch (colorBy) {
      case DEP_KEY:
      case CONFIDENCE_KEY:
        return getDepOrConfidenceColor(dep || conf);

      case MISCLASSIFIED_KEY:
        return gold === pred ? 'green' : 'red';

      case GOLD_KEY:
        return colorMap[gold as string]?.background;

      // pred
      default:
        return colorMap[pred as string]?.background;
    }
  };

  return {
    formatBleu,
    getClassOverlapColor,
    getDepColor,
    getDepOrConfidenceColor,
    getTooltipBorderColor,
    getUncertaintyColor
  };
};
