import {
  Box,
  Container,
  Grid,
  Group,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { useEffect, useState } from 'react';

import ClampedLine from '@/core/components/atoms/clamped-line/clamped-line';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { getFileName } from '@/core/utils/get-file-name/get-file-name';
import GoldPredToggle from '@/fine-tune/components/cv-grid/gold-pred-toggle/gold-pred-toggle';
import DepOrConfidence from '@/fine-tune/components/dep-or-confidence/dep-or-confidence';
import FiltersCheckbox from '@/fine-tune/components/filters-checkbox/filters-checkbox';
import OdImage from '@/fine-tune/components/od-image/od-image';
import { OD_ERROR_MAP } from '@/fine-tune/constants/object-detection.constants';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import useStore from '@/fine-tune/stores/store';
import { InsightsRow, Meta } from '@/fine-tune/types/query.types';

interface OdModalProps {
  innerProps: {
    pointId: number;
  };
}

type Error = string | undefined;
type Object = number | undefined;

const OdModal = ({ innerProps }: OdModalProps) => {
  const { pointId } = innerProps || {};

  // Global State
  const colorMap = useStore((state) => state.colorMap);

  // CV Store
  const { isEmbeddingsPointActive, modalIndex, actions } =
    useComputerVisionStore((s) => ({
      isEmbeddingsPointActive: s.isEmbeddingsPointActive,
      modalIndex: s.modalIndex,
      actions: s.actions
    }));

  const { setModalTitle } = actions;

  // Local State
  const [hiddenErrors, setHiddenErrors] = useState<Array<Error>>([]);
  const [hiddenObjects, setHiddenObjects] = useState<Array<Object>>([]);

  // Hooks
  const {
    data: insightsRowsData,
    hasNextPage,
    fetchNextPage
  } = useInsightsRows({
    pointIds: pointId ? [pointId] : undefined
  });

  const filterParams = useFilterParams();

  // Computed
  const samples =
    insightsRowsData?.pages?.flatMap((page) => page?.data_rows) || [];

  const sample = samples[modalIndex];

  const [showFilteredBoxes, setShowFilteredBoxes] = useState(false);

  // Computed
  const pointSample = pointId
    ? insightsRowsData?.pages[0]?.data_rows[0]
    : sample;
  const { meta } = pointSample || {};
  const boxes = pointSample?.boxes || [];

  const activeFilters = Object.keys(filterParams);
  const errorTypes = Object.keys(OD_ERROR_MAP);

  const activeBoxes = showFilteredBoxes
    ? boxes
    : boxes?.filter((box) => box.is_active);

  const filteredBoxIds =
    activeBoxes
      ?.filter((box) => !hiddenErrors.includes(box?.error_type as Error))
      ?.map((box) => Number(box?.id)) || [];

  const metaColumnNames = Object.keys(meta || {}) || [];

  // Effects
  useEffect(() => {
    const fileName =
      (sample?.image && getFileName(sample.image)) || STRING_PLACEHOLDER;
    setModalTitle(fileName);

    if (modalIndex === samples.length - 1 && hasNextPage) {
      fetchNextPage();
    }
  }, [modalIndex]);

  // Helpers
  const errors = Object.entries(OD_ERROR_MAP).map((err) => {
    const errorType = err[0];
    const { icon, label, tooltip } = err[1];
    // @ts-expect-error
    // Count at the image level
    let count = pointSample?.[errorType] || 0;

    // Count at the box level
    if (showFilteredBoxes) {
      count = pointSample?.boxes?.reduce(
        (sum, box) => (box?.error_type === errorType ? sum + 1 : sum),
        0
      );
    }

    const innerText = `${count} ${label}`;

    if (count) {
      return (
        <Tooltip multiline key={label} label={tooltip} w={250}>
          <UnstyledButton
            aria-label={errorType}
            opacity={hiddenErrors.includes(errorType) ? 0.5 : 1}
            onClick={() => toggleError(errorType)}
          >
            <Group gap='xs' wrap='nowrap'>
              {icon}
              <Text size='sm'>{innerText}</Text>
            </Group>
          </UnstyledButton>
        </Tooltip>
      );
    }
  });

  const objects = activeBoxes.map((box) => {
    const { error_type, gold, pred, id, is_gold } = box;
    const key = Number(id);
    const label = is_gold ? gold : pred;
    const color = (label && colorMap?.[label]?.background) || 'black';
    const colorDot = <Box bg={color} p={5} style={{ borderRadius: '50%' }} />;

    // @ts-expect-error
    const icon = OD_ERROR_MAP?.[error_type]?.icon;
    // @ts-expect-error
    const tooltip = OD_ERROR_MAP?.[error_type]?.tooltip;

    return (
      <UnstyledButton
        aria-label={label || ''}
        key={key}
        opacity={hiddenObjects.includes(key) ? 0.5 : 1}
        onClick={() => toggleObject(key)}
      >
        <Group gap='xs' wrap='nowrap'>
          {colorDot}
          <Tooltip.Group>
            <Tooltip withinPortal label={label} position='top-start'>
              <Text size='sm'>{label}</Text>
            </Tooltip>
            <Text c='dimmed' size='xs'>
              {is_gold ? 'Ground Truth' : 'Predicted'}
            </Text>
            <Tooltip multiline label={tooltip} w={200}>
              <Box>{icon}</Box>
            </Tooltip>
          </Tooltip.Group>
        </Group>
      </UnstyledButton>
    );
  });

  // Handlers
  const toggleAllErrors = () => {
    if (hiddenErrors.length === 0) {
      setHiddenErrors(errorTypes);
      setHiddenObjects(
        activeBoxes
          ?.filter((box) => box.error_type !== 'None')
          ?.map((box) => Number(box.id)) || []
      );
    } else {
      setHiddenErrors([]);
      setHiddenObjects(
        activeBoxes
          ?.filter((box) => box.error_type === 'None')
          ?.map((box) => Number(box.id)) || []
      );
    }
  };

  const toggleError = (errorType: string) => {
    // Click on an error and none are hidden or all are hidden, only show that error
    if (!hiddenErrors.length || hiddenErrors.length === errorTypes.length) {
      setHiddenErrors(errorTypes.filter((err) => err !== errorType));
      setHiddenObjects(
        activeBoxes
          .filter((box) => box.error_type !== errorType)
          .map((box) => Number(box.id))
      );
      return;
    }

    // Show all errors if there's only one visible and you click it again
    if (
      hiddenErrors.length === errorTypes.length - 1 &&
      !hiddenErrors.includes(errorType)
    ) {
      setHiddenErrors([]);
      setHiddenObjects([]);
      return;
    }

    // Toggle object on/off if one or more object is visible
    if (hiddenErrors.length < errorTypes.length) {
      // if hidden, show. Remove hidden error, filter boxes with errors for all hidden errors
      if (hiddenErrors.includes(errorType)) {
        const _hiddenErrors = hiddenErrors.filter((err) => err !== errorType);

        setHiddenErrors(_hiddenErrors);
        setHiddenObjects(
          activeBoxes
            .filter(
              (box) =>
                _hiddenErrors.includes(box.error_type as Error) ||
                box.error_type === 'None'
            )
            .map((box) => Number(box.id))
        );
      } else {
        // if visible, hide. Add error type to hidden errors. Add boxes with error type to hidden objects.
        setHiddenErrors([...hiddenErrors, errorType]);
        setHiddenObjects([
          ...hiddenObjects,
          ...activeBoxes
            .filter((box) =>
              [...hiddenErrors, errorType].includes(box.error_type as Error)
            )
            .map((box) => Number(box.id))
        ]);
      }
    }
  };

  const toggleAllObjects = () => {
    if (hiddenObjects.length === 0) {
      setHiddenErrors(errorTypes);
      setHiddenObjects(filteredBoxIds);
    } else {
      setHiddenErrors([]);
      setHiddenObjects([]);
    }
  };

  const toggleObject = (id: number) => {
    // Always reset hidden errors on object click
    setHiddenErrors([]);

    // Click on an object and none or all are hidden, only show that object
    if (!hiddenObjects.length || hiddenObjects.length === activeBoxes.length) {
      return setHiddenObjects(
        activeBoxes
          ?.filter((box) => Number(box.id) !== id)
          ?.map((box) => Number(box.id)) || []
      );
    }

    // Show all objects if there's only one visible object and you click it again
    if (
      hiddenObjects.length === activeBoxes.length - 1 &&
      !hiddenObjects.includes(id)
    ) {
      return setHiddenObjects([]);
    }

    // Toggle object on/off if one or more object is visible
    if (hiddenObjects.length < activeBoxes.length) {
      hiddenObjects.includes(id)
        ? setHiddenObjects(hiddenObjects.filter((objectId) => objectId !== id))
        : setHiddenObjects([...hiddenObjects, id]);
    }
  };

  if (!pointSample) {
    return (
      <Container>
        {Array.from({ length: 14 }, (_, i) => (
          <Skeleton data-testid='skeleton' height={40} key={i} mb='xs' />
        ))}
      </Container>
    );
  }

  return (
    <Grid columns={12} gutter='xl'>
      <Grid.Col span={10}>
        <OdImage
          showEdits
          showLabel
          bg='gray.1'
          height='70vh'
          hiddenFeatures={[...hiddenErrors, ...hiddenObjects]}
          // @ts-expect-error - FIXME
          sample={pointSample}
          showFilteredBoxes={showFilteredBoxes}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        {Boolean(activeFilters?.length || isEmbeddingsPointActive) && (
          <FiltersCheckbox
            checked={!showFilteredBoxes}
            onChange={() => setShowFilteredBoxes(!showFilteredBoxes)}
          />
        )}
        <Stack mt='xs' pb='xs'>
          <Text c='dimmed' size='xs' tt='uppercase'>
            Errors
            {errors.length && (
              <UnstyledButton
                pl='lg'
                style={{ fontSize: '14px' }}
                onClick={toggleAllErrors}
              >
                {hiddenErrors.length === 0 ? 'Hide' : 'Show'} all errors
              </UnstyledButton>
            )}
          </Text>
          <Stack pb='md'>{errors}</Stack>
        </Stack>
        <Stack>
          <Text c='dimmed' size='xs' tt='uppercase'>
            Objects
            <UnstyledButton
              pl='lg'
              style={{ fontSize: '14px' }}
              onClick={toggleAllObjects}
            >
              {hiddenObjects.length === 0 ? 'Hide' : 'Show'} all objects
            </UnstyledButton>
          </Text>
          <ScrollArea.Autosize mah='30vh'>
            <Stack>{objects}</Stack>
          </ScrollArea.Autosize>
        </Stack>
      </Grid.Col>
      <Grid.Col span={10}>
        <Group justify='space-between'>
          <Group gap='xl'>
            <DepOrConfidence
              component='div'
              // @ts-expect-error - FIXME
              sample={pointSample as InsightsRow}
              variant='filled'
            />
            <Group gap='lg'>
              <Text c='dimmed' w='100%'>
                Meta data
              </Text>
              {metaColumnNames?.map((column) => (
                <Box key={column} maw={450} miw={75}>
                  <Text c='dimmed'>{column}</Text>
                  <ClampedLine
                    size='sm'
                    // @ts-expect-error - FIXME
                    text={meta?.[column as keyof Meta]?.toString()}
                  />
                </Box>
              ))}
            </Group>
          </Group>
          <GoldPredToggle />
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default OdModal;
