import { Box, Group, Text, UnstyledButton } from '@mantine/core';
import _isEqual from 'lodash/isEqual';
import _pickBy from 'lodash/pickBy';
import { useMemo } from 'react';

import FilterChip from '@/core/components/atoms/filter-chip/filter-chip';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { GoldSpanHighlighterData } from '@/fine-tune/components/gold-span-highlighter/gold-span-highlighter';
import BaseTable from '@/fine-tune/components/table/base-table/base-table';
import { parseApiResponseToParams } from '@/fine-tune/data-parsers/parse-api-response-to-params/parse-api-response-to-params';
import { useActiveFilterDictionary } from '@/fine-tune/hooks/use-active-filters-dictionary/use-active-filters-dictionary';
import {
  useComputedParameters,
  useParametersStoreActions
} from '@/fine-tune/stores/parameters-store';
import { ParametersValues } from '@/fine-tune/stores/parameters-store/parameters.store.types';
import { DEFAULT_INSIGHTS_PARAMS } from '@/fine-tune/types/query.types';
import { EditWithContent } from '@/fine-tune/types/response.types';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';
import { Column } from '@/fine-tune/types/table.types';

import { getColumns } from './edits-detail-columns';
import ApprovedEdits, {
  ApprovedEditsProps
} from '../approved-edits/approved-edits';

export interface EditDetail {
  content?: string[] | GoldSpanHighlighterData[];
  edit_action: 'delete' | 'relabel';
  new_label?: string;
  spans?: SpanHighlightData[];
  text?: string;
  search_string?: string;
  text_replacement?: string;
}

type EditsDetailProps = ApprovedEditsProps & {
  detail?: EditWithContent;
  isLoading: boolean;
};

const EditsDetail = ({
  detail,
  isLoading,
  reviewers,
  onEditApprove
}: EditsDetailProps) => {
  const defaultValues = DEFAULT_INSIGHTS_PARAMS;
  const { clearAndUpdateParameters } = useParametersStoreActions();
  const { closeAll } = useModals();
  const isNer = useComputedParameters('isNer');
  const activeFiltersDictionary = useActiveFilterDictionary();

  const columns: Column[] = useMemo(() => getColumns(detail), [isNer, detail]);

  const tableData =
    isNer && detail?.new_label
      ? detail?.content?.map((line: {}) => ({
          ...line,
          new_label: detail.new_label
        }))
      : detail?.content;

  const { params } = parseApiResponseToParams(detail?.filter);

  const nonDefaultParams = _pickBy(params, (v, k) => {
    return (
      !_isEqual(v, defaultValues[k as keyof ParametersValues]) && Boolean(v)
    );
  });

  const editFilters = Object.entries(nonDefaultParams).map(
    ([label, value]) => ({
      // @ts-expect-error
      ...activeFiltersDictionary(label, value)
    })
  );

  const applyEditFilters = () => {
    clearAndUpdateParameters(
      {
        ...params
      },
      false,
      ['split', 'metric', 'inferenceName', 'sortBy', 'groupedBy']
    );
    closeAll();
  };

  return (
    <div className='c-edits-cart-table'>
      <ApprovedEdits reviewers={reviewers} onEditApprove={onEditApprove} />
      {editFilters.length ? (
        <Box mb='xs'>
          <Text c='gray.6' size='sm'>
            Filters
          </Text>
          <Group gap='xs'>
            {editFilters.map(({ parsedLabel, parsedValue }) => (
              <FilterChip
                key={parsedLabel}
                label={parsedLabel}
                value={parsedValue}
              />
            ))}
            <UnstyledButton ml={4} onClick={applyEditFilters}>
              <Text c='brand.6' fw={600} size='sm'>
                See in dataset
              </Text>
            </UnstyledButton>
          </Group>
        </Box>
      ) : null}
      <BaseTable
        columns={columns}
        data={tableData}
        height='450px'
        isLoading={isLoading}
        minRowHeight={65}
      />
    </div>
  );
};

export default EditsDetail;
