import {
  ActionIcon,
  Box,
  Kbd,
  MantineSize,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { IconCode, IconCornerDownLeft, IconSearch } from '@tabler/icons-react';
import { forwardRef, KeyboardEvent } from 'react';

import ModKbd from '@/core/components/atoms/mod-kbd/mod-kbd';

interface SearchBarProps {
  placeholder?: string;
  isRegexSearch?: boolean;
  defaultValue?: string;
  mr?: MantineSize | number;
  icon?: React.ReactNode;
  onRegexToggle?: () => void;
  onEnter?: (str: string) => void;

  id?: string;
  'data-testid'?: string;
  size?: MantineSize;
  maxWidth?: number | string;
  autoFocus?: boolean;
}

/**
 * SearchBar
 *
 *
 */
const SearchBar = forwardRef(
  (
    {
      placeholder = 'Text',
      isRegexSearch = false,
      defaultValue = '',
      icon,
      id,
      size = 'xs',
      onRegexToggle,
      onEnter,
      maxWidth = 350,
      mr = 0,
      autoFocus = false,
      'data-testid': dataTestId
    }: SearchBarProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const mantineId = useId();
    const _id = id || mantineId;

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onEnter?.(e.currentTarget.value);
      }
    };

    return (
      <Box ref={ref}>
        <TextInput
          autoFocus={autoFocus}
          data-testid={dataTestId}
          defaultValue={defaultValue}
          id={_id}
          leftSection={icon ?? <IconSearch size={16} />}
          mr={mr}
          placeholder={`${
            isRegexSearch ? 'RegEx' : ''
          } ${placeholder} search`.trim()}
          rightSection={
            <>
              {onRegexToggle && (
                <Tooltip
                  withArrow
                  label={
                    <Box className='text-center' mb={2}>
                      <Text size='sm'>Use RegEx Search</Text>
                      <ModKbd withShift character='u' />
                    </Box>
                  }
                >
                  <ActionIcon
                    aria-label='Regex Search'
                    color='gray'
                    data-testid='regex-toggle'
                    mr={4}
                    size='sm'
                    variant={isRegexSearch ? 'filled' : 'default'}
                    onClick={onRegexToggle}
                  >
                    <IconCode size={16} />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip
                withArrow
                withinPortal
                className='flex'
                label='Click Enter to search'
              >
                <Kbd className='flex' mr={2} style={{ lineHeight: 1.25 }}>
                  <IconCornerDownLeft size={16} />
                </Kbd>
              </Tooltip>
            </>
          }
          rightSectionWidth={onRegexToggle ? 60 : 30}
          size={size}
          style={{
            maxWidth,
            minWidth: 175,
            rightSection: { pointerEvents: 'none', cursor: 'default' },
            flex: 1
          }}
          styles={{
            input: {
              fontWeight: 700,
              borderColor: 'var(--mantine-color-gray-2)',
              width: 220
            },
            section: {
              pointerEvents: 'all',
              display: 'flex',
              alignContent: 'center'
            }
          }}
          onBlur={(e) => {
            onEnter?.(e.currentTarget.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </Box>
    );
  }
);

export default SearchBar;
