export enum ImageType {
  RAW = '2',
  GOLD = '0',
  PRED = '1',
  DEP = '3'
}
export interface ComputerVisionStoreState {
  errorDistributionCol: string | null;
  errorDistributionError: string | null;
  firstImage: ImageType;
  hideGold: boolean;
  hidePred: boolean;
  isEmbeddingsPointActive: boolean;
  isTransparent: boolean;
  modalIndex: number;
  modalTitle: string;
  secondImage: ImageType;

  actions: {
    clearState: () => void;

    setErrorDistributionCol: (val: string) => void;
    setErrorDistributionError: (val: string) => void;
    setFirstImageType: (val: ImageType) => void;
    setIsEmbeddingsPointActive: (val: boolean) => void;
    setModalIndex: (val: number) => void;
    setModalTitle: (val: string) => void;
    setSecondImageType: (val: ImageType) => void;
    toggleGold: () => void;
    toggleIsTransparent: () => void;
    togglePred: () => void;
  };
}
