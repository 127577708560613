import {
  UserPermissionBlock,
  UserPermissions
} from '@/core/types/user-permissions.types';

import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

export const defaults: UserPermissions = {
  update: {
    allowed: false,
    message: ''
  },
  delete: {
    allowed: false,
    message: ''
  }
};

export function useUserPermissions() {
  const { userPermissions } = useFeatureFlags();
  const isEnabled = userPermissions.isEnabled;
  const flagPermissions = userPermissions.values;

  function getUserPermissions(
    permissions: UserPermissionBlock[]
  ): UserPermissions {
    if (isEnabled) {
      return flagPermissions;
    }

    const permissionBlock = Object.fromEntries(
      permissions.map(({ action, ...permission }) => [action, permission])
    ) as UserPermissions;

    return {
      update: permissionBlock.update || defaults.update,
      delete: permissionBlock.delete || defaults.delete
    };
  }

  return {
    getUserPermissions
  };
}
