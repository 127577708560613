import { useQueryParameters as useGenericQueryParameters } from '@/core/hooks/use-query-parameters/use-query-parameters';

import { defaultStoreParams } from './query-parameters';
import { EvaluateParameters } from './query-parameters.types';

export function useQueryParameters() {
  const { isReady, set, get, reset } =
    useGenericQueryParameters<EvaluateParameters>(defaultStoreParams);

  return {
    isReady,
    set,
    get,
    reset
  };
}
