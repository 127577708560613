import { CHECKBOX_COL_WIDTH } from '@/fine-tune/constants/table.constants';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import { Column } from '@/fine-tune/types/table.types';

const SHADOW_STYLES = {
  borderRight: '1px solid #E9E8ED',
  clipPath: 'inset(0px -15px 0px 0px)',
  boxShadow: '0 0 15px rgba(28, 27, 34, 0.12)'
};

export const getShadowStyles = (
  col: Column,
  idx: number,
  allCols: Column[]
) => {
  const isLastStickyColumn = col?.isSticky && !allCols?.[idx + 1]?.isSticky;

  return isLastStickyColumn ? SHADOW_STYLES : {};
};

export const getLeftValue = (
  width: number,
  idx: number,
  withoutCheckbox?: boolean
) => {
  if (withoutCheckbox) {
    return 0;
  }

  const isSD = useParametersStore.getState().computed?.isSD();
  const isNER = useParametersStore.getState().computed?.isNer();
  const paddingCorrectingValue = isNER ? 0 + idx * 2 : 0;

  // Add valued to account for the px, prevents white space between checkbox and column
  const checkboxColumn = document?.getElementById('checkbox-column');
  const checkboxColumnWidth = checkboxColumn?.clientWidth || CHECKBOX_COL_WIDTH;

  const correctedPadding = checkboxColumnWidth + paddingCorrectingValue;

  // For SD, the first column is 76px shorter than the rest
  const correctedWidth = isSD && idx !== 0 ? 76 : 0;

  const leftValue = width * idx + correctedPadding - correctedWidth;

  return leftValue;
};

interface RenderCellArgs {
  // TODO: Update make this generic
  data: any;
  column: Column;
  isTruncated: boolean;
  showTokenLevelHallucination?: boolean;
  showTokenLevelInputUncertainty?: boolean;
}

// TODO: Can this be merged with the renderCell in InfiniteScrollTable?
export const renderCell = ({ data, column, isTruncated }: RenderCellArgs) => {
  const { accessor, cell } = column || {};

  let value = data?.[accessor];

  if (accessor?.includes('.')) {
    const fields = accessor.split('.');

    value = data;
    fields.forEach((field) => {
      value = value?.[field];
    });
  }

  if (typeof cell === 'function') {
    return cell({
      value,
      data,
      column,
      isTruncated
    });
  }

  return value;
};
