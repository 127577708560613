import { Box, Group } from '@mantine/core';
import { VirtuosoGrid } from 'react-virtuoso';

import IcCard from '@/fine-tune/components/cv-cards/ic-card/ic-card';
import { useDataframeHeight } from '@/fine-tune/hooks/use-element-height/use-element-height';
import { EditWithContent } from '@/fine-tune/types/response.types';

import ApprovedEdits, {
  ApprovedEditsProps
} from '../approved-edits/approved-edits';

type CvEditsDetailProps = ApprovedEditsProps & {
  detail?: EditWithContent;
  isLoading: boolean;
};

const CvEditsDetail = ({
  detail,
  reviewers,
  onEditApprove
}: CvEditsDetailProps) => {
  const rawSamples = detail?.content ?? [];
  const samples = detail?.new_label
    ? rawSamples.map((sample) => ({ ...sample, new_label: detail.new_label }))
    : rawSamples;

  const height = useDataframeHeight();

  return (
    <div data-testid='cv-edits-detail'>
      <ApprovedEdits reviewers={reviewers} onEditApprove={onEditApprove} />
      <VirtuosoGrid
        components={{
          // @ts-expect-error
          Item: Box,
          // @ts-expect-error
          List: Group
        }}
        data={samples}
        itemContent={(index) => {
          const sample = samples[index];
          if (!sample) return;

          return (
            <IcCard
              canSelect={false}
              // @ts-expect-error
              sample={sample}
              showEdit={true}
              width={250}
            />
          );
        }}
        listClassName='justify-flex-start'
        overscan={900}
        style={{
          height,
          width: '100%'
        }}
        totalCount={samples.length}
      />
    </div>
  );
};

export default CvEditsDetail;
