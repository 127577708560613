import { RouterParameters } from '@/core/hooks/use-query-parameters/use-query-parameters.types';
import { isJson } from '@/core/utils/is-json/is-json';

import {
  ObserveParameters,
  ObserveParametersKey
} from './query-parameters.types';

const OBSERVE_FILTER_DELIMITER = '+__+';

function fromRouter(params: RouterParameters): Partial<ObserveParameters> {
  const newParams: Partial<ObserveParameters> = {};

  for (const [key, value] of Object.entries(params)) {
    const typedKey = key as ObserveParametersKey;

    // Special case for promptMonitorFilters and promptMonitorChartOrder
    if (key === 'promptMonitorFilters') {
      const promptMonitorFilters = (value as unknown as string)
        ?.split(OBSERVE_FILTER_DELIMITER)
        ?.map((filter) => JSON.parse(decodeURI(filter)));

      newParams['promptMonitorFilters'] = promptMonitorFilters;
    } else if (key === 'promptMonitorChartOrder') {
      if (isJson(value)) {
        const promptMonitorChartOrder = JSON.parse(decodeURI(value as string));
        newParams[typedKey] = promptMonitorChartOrder;
      }
    }
    // If value is a JSON string, parse it
    if (isJson(value)) {
      newParams[typedKey] = JSON.parse(value as string);
    }
    // If value is a boolean, parse it
    else if (value === 'true' || value === 'false') {
      // @ts-expect-error FIXME: Unsure how to type this
      newParams[typedKey] = parseBool(value);
    }
    // If value is a number, parse it
    else if (typeof value === 'string' && value.match(/^-{0,1}\d+$/)) {
      // @ts-expect-error FIXME: Unsure how to type this
      newParams[typedKey] = parseInt(value as string, 10);
    }
    // Edge cases
    else if (value === '[]') {
      // @ts-expect-error FIXME: Unsure how to type this
      newParams[typedKey] = [];
    }
    // If value is a string, assign it directly
    else {
      // @ts-expect-error FIXME: Unsure how to type this
      newParams[typedKey] = value;
    }
  }

  return newParams;
}

function parseBool(value: 'true' | 'false'): boolean {
  return value === 'true';
}

export { fromRouter };
