import {
  DefaultOptions,
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Paths } from '@/core/constants/routes.constants';
import { Error } from '@/core/types/response.types';

const fifteenMinutes = 15 * 60 * 1000;

interface ReactQueryProviderProps {
  children?: React.ReactNode;
  pageProps: {
    dehydratedState?: object;
  };
  withAuth: boolean;
}

export const ReactQueryProvider = ({
  children,
  pageProps,
  withAuth
}: ReactQueryProviderProps) => {
  const router = useRouter();

  // Default config for React Query
  const defaultOptions: DefaultOptions = {
    queries: {
      staleTime: Infinity,
      cacheTime: fifteenMinutes,
      retry: 1,
      refetchOnWindowFocus: false,
      // @ts-expect-error FIXME:
      onError: (error: Error) => {
        // @ts-expect-error FIXME: When we remove axios, we can update the types to reflect a native Fetch req
        const status = error?.status || error?.response?.status;

        if (status === 401) {
          return withAuth
            ? router.push({
                pathname: Paths.SIGN_IN
              })
            : null;
        }
      }
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  );
};
