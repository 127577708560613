import { Box, Group, Stack, Text } from '@mantine/core';

import Metric from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';
import { NumberUnit } from '@/core/utils/unit-conversions';

interface AggregateTagsRatingProps {
  counts: {
    [key: string]: number;
  };
  title?: string;
}

export const AggregateTagsRating = ({
  counts = {},
  title
}: AggregateTagsRatingProps) => {
  const countEntries = Object.entries(counts) ?? [];

  const mostSelectedTag = countEntries?.reduce(
    (a, b) => {
      return a[1] > b[1] ? a : b;
    },
    ['', 0]
  )[0];

  const otherTags = Object.entries(counts)?.filter(
    ([key]) => key !== mostSelectedTag
  );

  const total = Object.values(counts)?.reduce((a, b) => a + b, 0);

  return (
    <MetricPopover
      body={
        <Box p='sm'>
          <Text c='gray.2' fw={600} pb='sm' size='sm'>
            {title}
          </Text>
          <Stack gap='xs'>
            <Group gap='xs' justify='space-between' wrap='nowrap'>
              <Box maw='75%'>
                <Metric value={mostSelectedTag} variant='filled' />
              </Box>
              <Metric
                color='dark'
                value={new NumberUnit(
                  counts?.[mostSelectedTag] / total
                ).toString({ style: 'percent' })}
                variant='filled'
              />
            </Group>
            {otherTags?.map((tag) => {
              const ratio = tag[1] / total;
              const formattedRatio = new NumberUnit(ratio)
                .to('percentage', { places: 2, asDecimal: true })
                .toString({ style: 'percent', maximumFractionDigits: 2 });

              return (
                <Group justify='space-between' key={tag[0]}>
                  <Box maw='75%'>
                    <Metric value={tag[0]} variant='filled' />
                  </Box>
                  <Metric
                    color='dark'
                    value={formattedRatio}
                    variant='filled'
                  />
                </Group>
              );
            })}
          </Stack>
        </Box>
      }
    >
      <Group data-testid='aggregate-tags-rating' gap='xs' wrap='nowrap'>
        <Metric
          color='gray'
          maxWidth={100}
          value={mostSelectedTag}
          variant='filled'
        />
        <Metric
          value={new NumberUnit(counts?.[mostSelectedTag] / total).toString({
            style: 'percent'
          })}
          variant='subtle'
        />
      </Group>
    </MetricPopover>
  );
};
