import check from 'check-types';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _pickBy from 'lodash/pickBy';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { Tab } from '@/core/constants/query-params.constants';
import { TASK_TYPE } from '@/core/constants/tasks-and-frameworks.constants';
import { getDefaultColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { parseRouterParams } from '@/fine-tune/hooks/use-query-parser/use-query-parser.support';
import {
  DEFAULT_INSIGHTS_PARAMS,
  INSIGHTS_HIDDEN_FILTERS,
  INSIGHTS_NON_CLEARABLE_PARAMETERS
} from '@/fine-tune/types/query.types';
import { getInitialMetric } from '@/fine-tune/utils/parse-run-response/parse-run-response';

import { persistStateInUrl } from './middleware/persist-in-url-middleware';
import {
  ParametersKeys,
  ParametersState,
  ParametersValues,
  PartialParameters
} from './parameters.store.types';

export const useParametersStore = createWithEqualityFn<ParametersState>()(
  persistStateInUrl((set, get) => ({
    ...get(),
    computed: {
      defaultValues: () => {
        const { isInference, isIc, isSS, isS2S } = get().computed;

        // Insights: TODO: Don't treat this as the default.
        return {
          ...DEFAULT_INSIGHTS_PARAMS,
          metric: getInitialMetric(isInference(), isIc(), isSS(), isS2S())
        };
      },
      nonClearableValues: () => {
        return INSIGHTS_NON_CLEARABLE_PARAMETERS;
      },
      buildGrayPoints: () => {
        const { split, computed } = get();
        if (split === 'inference' && Boolean(get().comparedTo)) {
          return true;
        }

        if (computed.isOoc()) {
          return true;
        }

        return false;
      },
      isDataView: () => get().tab === Tab.DATA_VIEW,
      isOoc: () =>
        get().isDrifted && ['test', 'validation'].includes(get().split),
      isNer: () => get().taskType === TASK_TYPE.NER,
      isMltc: () => get().taskType === TASK_TYPE.MLTC,
      isTextClassification: () => get().taskType === TASK_TYPE.MCTC,
      isInference: () => get().split === 'inference',
      isInferenceNer: () =>
        get().taskType === TASK_TYPE.NER && get().split === 'inference',
      isTestOrValidation: () => ['test', 'validation'].includes(get().split),
      isSD: () => get().taskType === TASK_TYPE.SD,
      isIc: () => get().taskType === TASK_TYPE.IC,
      isOd: () => get().taskType === TASK_TYPE.OD,
      isSS: () => get().taskType === TASK_TYPE.SS,
      isS2S: () => get().taskType === TASK_TYPE.S2S,
      params: () => _omit(get(), ['computed', 'actions', 'differs', 'metadata'])
    },

    differs: {
      getDiff: () => {
        const params = get().computed.params();
        const defaultValues = get().computed.defaultValues();

        return _pickBy(params, (v, k) => {
          if (k === 'dataframeColumns' && params.taskType) {
            if (!check.array(v)) return false;
            const defaultColumnsForTask = getDefaultColumns(params.taskType);
            if (!check.array(v)) return false;
            return !_isEqual(
              ((v as string[]) || [])?.sort(),
              defaultColumnsForTask?.sort()
            );
          }
          return !_isEqual(v, defaultValues?.[k as ParametersKeys]);
        });
      },
      // @ts-expect-error FIXME:
      getActiveFilters: (asObj?: boolean) => {
        const params = _omit(get(), [
          'computed',
          'actions',
          'differs',
          'metadata'
        ]);
        // Assuming here that default values will only ever be Insights
        const active = _pickBy(params, (v, k) => {
          return (
            !_isEqual(v, DEFAULT_INSIGHTS_PARAMS?.[k as ParametersKeys]) &&
            !INSIGHTS_HIDDEN_FILTERS.includes(k)
          );
        });

        if (asObj) {
          return active;
        }

        const activeFilters = Object.entries(active) as [
          keyof ParametersValues,
          any
        ][];

        return activeFilters;
      }
    },

    actions: {
      resetStore: async ({ defaults, paramsToPersist, projectType }) => {
        return set((store) => {
          const { parsedMetaParams, parsedParams } =
            parseRouterParams(defaults);

          // Only allow persisting parameters when the project type remains the same
          const canPersistParameters = projectType === store.projectType;

          // Only persist parameters if they're defined within the store; otherwise, let the default parameters take effect
          const persistedParams = (paramsToPersist ?? []).reduce(
            (acc, param) => {
              if (store[param] != null) {
                return {
                  ...acc,
                  [param]: store[param]
                };
              } else {
                return acc;
              }
            },
            {}
          ) as PartialParameters;

          // This is an entire new store in the default state (by project type's `defaults`)
          const defaultStoreValues = {
            ...defaults,
            ...parsedParams,
            metaFilter: parsedMetaParams,
            actions: get().actions,
            computed: get().computed,
            differs: get().differs
          };

          // Mixin the persisted parameters to overwrite default values
          const withPersistedValues = {
            ...defaultStoreValues,
            ...(canPersistParameters ? persistedParams : {})
          };

          return withPersistedValues;
        }, true);
      },

      setParameters: (newParams) => {
        return set(() => newParams);
      },

      setMetaParameters: (newMetaParams) =>
        set((state) => {
          const newMetaNames = newMetaParams.map((param) => param.name);
          const newMetaFilters =
            state.metaFilter?.filter(
              (filter) => !newMetaNames.includes(filter.name)
            ) || [];

          newMetaParams.forEach((newMetaParam) => {
            const shouldAddNewMetaParam =
              newMetaParam?.isin?.length ||
              check.number(newMetaParam?.greater_than) ||
              check.number(newMetaParam?.less_than) ||
              check.number(newMetaParam?.is_equal);

            if (shouldAddNewMetaParam) {
              newMetaFilters.push(newMetaParam);
            }
          });

          return { metaFilter: newMetaFilters };
        }),

      clearParameters: (preserveKeys) =>
        set((state) => {
          const { defaultValues, nonClearableValues } = state.computed;
          let newValues = defaultValues() ?? {};

          // We don't want to clear these values
          [...(preserveKeys || []), ...nonClearableValues()].forEach((key) => {
            delete newValues[key];
          });
          return { ...newValues };
        }),

      clearAndUpdateParameters: (
        newParams,
        clearAll = false,
        preserveKeys = []
      ) =>
        set((state) => {
          const { defaultValues, nonClearableValues } = state.computed;

          const defaultParamsKeys = Object.keys(
            defaultValues() || {}
          ) as ParametersKeys[];

          const updatedParams = defaultParamsKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: newParams?.[key] || defaultValues()?.[key]
            }),
            {} as ParametersValues
          );

          if (clearAll) {
            return updatedParams;
          }

          [...nonClearableValues(), ...tabsValues, ...preserveKeys].forEach(
            (key) => {
              delete updatedParams?.[key];
            }
          );

          return updatedParams;
        })
    }
  })),
  shallow
);

const tabsValues: ParametersKeys[] = ['tab', 'insightsTab'];

export const isDifferent = (value: any, defaultValue: any) => {
  if (check.array(defaultValue)) {
    return !_isEqual(defaultValue, value);
  }
  return defaultValue !== value;
};
