import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useQueryParameters } from '@/evaluate/hooks/use-query-parameters/use-query-parameters';

export const GET_PROMPT_TEMPLATES = 'GET_PROMPT_TEMPLATES';

export const useGetPromptTemplates = () => {
  // Parameters Store
  const { projectId } = usePathParameters();

  const { get } = useQueryParameters();
  const templateId = get('templateId');

  // API path
  const path = '/projects/{project_id}/templates';

  // Dependencies
  const dependencies = [projectId];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Config
  const config = { enabled };

  // Query
  const queryKey = [GET_PROMPT_TEMPLATES, { projectId, templateId }];
  const queryFn = async () => {
    const res = await api.GET(path, {
      params: {
        path: {
          project_id: projectId!
        }
      }
    });

    return res.data;
  };

  // Response
  const res = useQuery(queryKey, queryFn, config);

  const isSelected = (templateId: string, versionId: string) => {
    return (
      res.data?.find((t) => t.id === templateId)?.selected_version_id ===
      versionId
    );
  };

  return { ...res, isSelected };
};
