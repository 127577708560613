import createClient, { ParamsOption, RequestBodyOption } from 'openapi-fetch';

import { authMiddleware, CSPMiddleware, NlpRequestMiddleware } from './fetcher';
import { paths } from '../types/api';

export type UseQueryOptions<T> = ParamsOption<T> &
  RequestBodyOption<T> & {
    reactQuery: {
      enabled: boolean;
    };
  };

const api = createClient<paths>({
  baseUrl: process.env.NEXT_PUBLIC_API_URL
});

// Heads up: Order sets the priority of middleware
api.use(CSPMiddleware);
api.use(authMiddleware);
api.use(NlpRequestMiddleware);

export default api;
