import {
  ActionIcon,
  Box,
  Button,
  Center,
  Code,
  Divider,
  Group,
  Notification,
  Stack,
  Text
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';
import {
  IconArrowNarrowRight,
  IconBrandPython,
  IconChartLine,
  IconCheck,
  IconCopy,
  IconFileText,
  IconSettings
} from '@tabler/icons-react';
import Link from 'next/link';
import { useState } from 'react';

import { Paths } from '@/core/constants/routes.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

const ENVIRONMENT_VARIABLES = `GALILEO_CONSOLE_URL
GALILEO_USERNAME
GALILEO_PASSWORD`;

// NOTE indentation here is important for python
function getGettingStartedText(projectName: string) {
  return `from galileo_observe import GalileoObserveCallback
from langchain.chat_models import ChatOpenAI
  
llm = ChatOpenAI(
  callbacks=[GalileoObserveCallback(project_name="${projectName}")],
)`;
}

function CopiableCodeBlock({ code }: { code: string }) {
  const [showNotification, setShowNotification] = useState(false);
  const clipboard = useClipboard({ timeout: 2000 });

  const handleCopy = () => {
    clipboard.copy(code);
    setShowNotification(true);
  };

  return (
    <Box my='lg' style={{ position: 'relative' }}>
      <Code block>{code}</Code>
      <ActionIcon
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        variant='subtle'
        onClick={handleCopy}
      >
        <IconCopy size='1rem' />
      </ActionIcon>
      {showNotification && (
        <Notification
          color='green'
          icon={<IconCheck size='1rem' />}
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            right: '10px'
          }}
          title='Success!'
          onClose={() => setShowNotification(false)}
        >
          Code copied to clipboard
        </Notification>
      )}
    </Box>
  );
}

const Step = ({
  children,
  action
}: React.PropsWithChildren<{ action: JSX.Element }>) => {
  return (
    <Stack
      align='center'
      justify='space-between'
      miw='300px'
      p='20px'
      style={{ background: '#f9f9fb', borderRadius: '3px', flex: 1 }}
    >
      <Stack align='center'>{children}</Stack>
      {action}
    </Stack>
  );
};

const ObserveHelpModal = ({ id }: ContextModalProps) => {
  const { data: currentProject } = useCurrentProject();

  const { projectId } = usePathParameters();

  const { closeModal } = useModals();

  const gettingStartedText = getGettingStartedText(
    currentProject?.name ?? 'observe_project'
  );

  return (
    <Box data-testid='observe-help-modal'>
      <Box style={{ overflow: 'auto' }}>
        <Group
          align='stretch'
          justify='center'
          mb='lg'
          style={{ width: 'fit-content' }}
          wrap='nowrap'
        >
          <Step
            action={
              <Link
                className='unstyled-anchor'
                href={{
                  pathname: Paths.OBSERVE_SETTINGS,
                  query: {
                    projectId
                  }
                }}
                target='_blank'
              >
                <Button leftSection={<IconSettings size='1rem' />}>
                  Open Project Settings
                </Button>
              </Link>
            }
          >
            <IconSettings size='3rem' />
            <Text fw='bold' size='lg'>
              Configure Your Metrics
            </Text>
            <Text size='sm'>
              Before ingesting prompts, it is important that you decide which
              metrics you wish to be calculated. This includes any{' '}
              <b>custom metrics</b> you wish to configure.
            </Text>
          </Step>
          <IconArrowNarrowRight
            color='#e1e1e3'
            size='3rem'
            style={{ alignSelf: 'center', flexShrink: 0 }}
          />
          <Step
            action={
              <Button
                leftSection={<IconFileText size='1rem' />}
                onClick={() => {
                  window.open(
                    'https://docs.rungalileo.io/galileo/gen-ai-studio-products/galileo-observe',
                    '_blank'
                  );
                }}
              >
                Open Documentation
              </Button>
            }
          >
            <IconBrandPython size='3rem' />
            <Text fw='bold' size='lg'>
              Setup Your Application
            </Text>
            <Text size='sm'>
              Below you can find example code to setup your application. You can
              find more help in our Documentation pages.
            </Text>
          </Step>
          <IconArrowNarrowRight
            color='#e1e1e3'
            size='3rem'
            style={{ alignSelf: 'center', flexShrink: 0 }}
          />
          <Step
            action={
              <Button
                leftSection={<IconChartLine size='1rem' />}
                onClick={() => closeModal(id)}
              >
                Go to Dashboard
              </Button>
            }
          >
            <IconChartLine size='3rem' />
            <Text fw='bold' size='lg'>
              Monitor Your Prompts
            </Text>
            <Text size='sm'>
              View the <b>metrics charts</b> to watch live performance over
              time, or inspect the <b>data table</b> to see granualar details of
              your most recent requests.
            </Text>
          </Step>
        </Group>
      </Box>
      <Divider mb='lg' />
      <Box style={{ overflow: 'auto' }}>
        <Center miw='600px'>
          <Box style={{ flex: '0 1 1000px' }}>
            <Text fw='bold' py='sm' size='lg'>
              Example Setup In Your Application
            </Text>
            <Text size='sm'>Copy the code below into your LLM App</Text>
            <CopiableCodeBlock code={gettingStartedText} />
            <Text size='sm'>
              Set the following environment variables to authenticate yourself
              into Galileo
            </Text>
            <Code block my='lg'>
              {ENVIRONMENT_VARIABLES}
            </Code>
          </Box>
        </Center>
      </Box>
      <Group justify='flex-end'>
        <Button onClick={() => closeModal(id)}>Done</Button>
      </Group>
    </Box>
  );
};

export default ObserveHelpModal;
