import { Text } from '@mantine/core';
import { useState } from 'react';

import LabelledWord from '@/fine-tune/components/labelled-word/labelled-word';
import useStore from '@/fine-tune/stores/store';

import { GoldSpanHighlighterData } from '../gold-span-highlighter/gold-span-highlighter';

export interface ShiftSpanHighlighterProps {
  data: GoldSpanHighlighterData;
  searchTerm?: string;
  shiftStart?: number;
  shiftEnd?: number;
}

interface ShiftedWordsProps {
  text: string;
  color: string;
  beforeLabel?: boolean;
}

const ShiftedWords = ({
  text,
  color,
  beforeLabel = true
}: ShiftedWordsProps) => (
  <Text
    component='a'
    style={{
      borderWidth: beforeLabel ? '2px 0px 2px 2px' : '2px 2px 2px 0px',
      borderStyle: 'dashed',
      borderColor: color,
      borderRadius: beforeLabel ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
      padding: '4px 6px 10px 6px'
    }}
  >
    {text}
  </Text>
);

/**
 * ShiftSpanHighlighter
 *
 *
 *
 * @returns {React.Component} ShiftSpanHighlighter
 */
const ShiftSpanHighlighter = ({
  data,
  searchTerm,
  shiftStart,
  shiftEnd
}: ShiftSpanHighlighterProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const colorMap = useStore((state) => state.colorMap);

  const { text, spans } = data || {};

  let prevEnd = 0;

  const shiftedSpan = spans?.find(({ span_start, span_end, is_active }) => {
    const spanText = text?.substring(span_start, span_end);
    return is_active && spanText?.includes(searchTerm || '');
  });

  const SpanContent = () => {
    if (!shiftedSpan) {
      return text;
    } else {
      const {
        gold = '',
        span_start = 0,
        span_end = 0,
        id: spanId
      } = shiftedSpan || {};

      let leading = text?.substring(prevEnd, span_start).trim();
      const extraWordsBefore = leading?.split(' ').slice(shiftStart).join(' ');
      leading = leading?.split(' ').slice(0, shiftStart).join(' ');

      let trailing = text?.substring(span_end).trim();
      const extraWordsAfter = trailing?.split(' ').slice(0, shiftEnd).join(' ');
      trailing = trailing?.split(' ').slice(shiftEnd).join(' ');

      const color = colorMap[gold as string]?.text;

      return (
        <>
          <Text component='a'>{leading} </Text>
          {extraWordsBefore && (
            <ShiftedWords color={color} text={extraWordsBefore} />
          )}
          <LabelledWord
            color={color}
            label={gold}
            spanId={spanId}
            word={text?.substring(span_start, span_end)}
            onClick={() => setIsExpanded((prev) => !prev)}
          />
          {extraWordsAfter && (
            <ShiftedWords
              beforeLabel={false}
              color={color}
              text={extraWordsAfter}
            />
          )}
          <Text component='a'> {trailing}</Text>
        </>
      );
    }
  };

  return (
    <Text
      className='cursor-pointer'
      data-testid='shift-span-highlighter'
      lineClamp={isExpanded ? undefined : 1}
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      {SpanContent()}
    </Text>
  );
};

export default ShiftSpanHighlighter;
