/** Finds the first `maxKeywords` and wraps them with <mark> */
export function getTemplateKeywords(text: string, maxKeywords: number) {
  let templateKeywords: string[] = [];
  // Contains a curly bracket wrapped word with letters, numbers, and underscores only
  for (const match of text.matchAll(/{[a-zA-Z0-9_]+}/g)) {
    const templateKeyword = match[0].substring(1, match[0].length - 1);
    templateKeywords.push(templateKeyword);

    if (templateKeywords.length >= maxKeywords) {
      break;
    }
  }

  templateKeywords = [...new Set(templateKeywords)];

  return { templateKeywords };
}
