import {
  Box,
  Button,
  Group,
  PasswordInput,
  Switch,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';

import TitleWithIcon from '@/core/components/atoms/title-with-icon/title-with-icon';
import {
  INTEGRATION_CARDS,
  IntegrationCard
} from '@/core/constants/integrations.constants';
import { useUpdateIntegration } from '@/core/hooks/query-hooks/use-update-integration/use-update-integration';

interface IntegrationModalBodyProps {
  innerProps: { config: IntegrationCard };
}

export interface UpdateIntegrationMutationBody {
  endpoint?: string;
  url?: string;
  token?: string;
  isEnterprise?: boolean;
  organization?: string;
  isDatabricks?: boolean;
  sqlPath?: string;
  catalogName?: string;
  principalId?: string;
  region?: string;
  credentialType?: string;
  aws_access_key_id?: string;
  aws_secret_access_key?: string;
  aws_role_arn?: string;
}

const IntegrationsModal = ({ innerProps }: IntegrationModalBodyProps) => {
  const { icon, title, id, showDisclaimer, onSuccessCallback } =
    innerProps?.config || {};

  // Hooks
  const updateIntegration = useUpdateIntegration(id, onSuccessCallback);

  // Form
  const form = useForm({
    initialValues: {
      credentialType: 'key_secret',
      url: '',
      token: '',
      isEnterprise: true,
      isConsented: false,
      organization: '',
      sqlPath: '',
      principalId: '',
      catalogName: '',
      region: 'us-west-2',
      bedrock: false,
      sagemaker: false,
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_role_arn: ''
    }
  });

  // Computed
  const isOpenAI = id === 'openai';
  const isLabelStudio = id === 'labelstudio';
  const isDatabricks = id === 'databricks';
  const isVertex = id === 'vertex_ai';
  const isWriter = id === 'writer';

  const needsURL = !isOpenAI && !isVertex && !isWriter;

  // Handlers
  const handleUpdateIntegration = (values: typeof form.values) => {
    const body: UpdateIntegrationMutationBody = {
      url: values.url,
      token: values.token
    };

    if (isLabelStudio) {
      body.isEnterprise = values.isEnterprise;
    }

    if (isOpenAI || isWriter) {
      body.organization = values.organization;
    }

    if (isDatabricks) {
      body.sqlPath = values.sqlPath;
      body.catalogName = values.catalogName;
      body.principalId = values.principalId;
    }

    updateIntegration.mutate(body, { onSuccess: () => closeAllModals() });
  };

  const { isConsented, token, sqlPath, principalId, url } = form.values;

  const isDisabled =
    (showDisclaimer && !isConsented) ||
    !token ||
    (isDatabricks && (!sqlPath || !principalId || !url));

  const integrationName = INTEGRATION_CARDS.find((int) => int.id === id)?.name;

  return (
    <form
      data-testid='integrations-modal'
      onSubmit={form.onSubmit(handleUpdateIntegration)}
    >
      <TitleWithIcon icon={icon} mt='xs' title={title} />

      {needsURL && (
        <TextInput
          autoFocus
          required
          label={isDatabricks ? 'Hostname' : 'URL'}
          mb='xs'
          mt='sm'
          placeholder={isDatabricks ? 'Hostname' : 'Integration URL'}
          {...form.getInputProps('url')}
        />
      )}

      {(isOpenAI || isWriter) && (
        <TextInput
          label='Organization ID'
          mb='xs'
          mt='sm'
          placeholder='Organization ID'
          {...form.getInputProps('organization')}
        />
      )}

      {isDatabricks && (
        <>
          <TextInput
            required
            label='Path'
            mb='xs'
            mt='sm'
            placeholder='Path'
            {...form.getInputProps('sqlPath')}
          />
          <TextInput
            required
            label='Principal ID'
            mb='xs'
            mt='sm'
            placeholder='Principal ID'
            {...form.getInputProps('principalId')}
          />
          <TextInput
            label='Catalog name'
            mb='xs'
            mt='sm'
            placeholder='Catalog name'
            {...form.getInputProps('catalogName')}
          />
        </>
      )}

      <PasswordInput
        required
        autoComplete='new-password'
        label={isVertex ? 'Credentials' : 'API Token'}
        placeholder='Token'
        {...form.getInputProps('token')}
      />

      {isLabelStudio && (
        <Group justify='space-between' mt='xl'>
          <Switch
            defaultChecked
            label='Enterprise Account'
            {...form.getInputProps('isEnterprise', { type: 'checkbox' })}
          />
        </Group>
      )}

      <Box mb={50} mt='lg'>
        {showDisclaimer && (
          <Switch
            label={`I acknowledge that by using this feature, I am sending my data to ${integrationName} through its APIs.`}
            {...form.getInputProps('isConsented', { type: 'checkbox' })}
          />
        )}
      </Box>
      <Box
        bg='gray.1'
        style={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          borderTop: '1px solid #E9E8ED',
          bottom: 0,
          left: 0,
          position: 'absolute',
          textAlign: 'right',
          width: '100%'
        }}
      >
        <Button
          bg='white'
          color='gray'
          mr='xs'
          my='sm'
          radius='md'
          variant='outline'
          onClick={() => closeAllModals()}
        >
          Cancel
        </Button>
        <Button
          data-testid='save-integrations-button'
          disabled={isDisabled}
          loading={updateIntegration.isLoading}
          mx='sm'
          my='auto'
          radius='md'
          type='submit'
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

export default IntegrationsModal;
