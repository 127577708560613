import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export type StringConversionFunction<OptionsType = undefined> = (
  value: string,
  options?: OptionsType
) => string;

export type TitleCaseOptions = {
  ignore: string[];
};

export type StringConversionMap = {
  string: {
    title_case: StringConversionFunction<TitleCaseOptions>;
  };
  // Add other conversions here...
};

// Type guarding is done in the constructor of the Unit class.
export const stringConversions: StringConversionMap = {
  string: {
    title_case: (
      value,
      options = { ignore: ['of', 'and', 'the', 'to', 'in', 'is', 'a'] }
    ) => {
      const { ignore } = options;

      const ignoreWords = new Set(ignore);

      return value?.replace(/\w+/g, (word, i) => {
        word = word.toLowerCase();

        if (i && ignoreWords.has(word)) {
          return word;
        }

        return word[0].toUpperCase() + word.slice(1);
      });
    }
  }
};

export class StringUnit<
  InputUnitType extends keyof StringConversionMap = 'string'
> {
  private readonly initialValue: string;
  private value: string;

  private unitType: InputUnitType;

  /**
   *
   * @param {String} value - The value to convert
   * @param {InputUnitType} unitType - The unit type of the value to convert
   */
  constructor(
    value: string,
    unitType: InputUnitType = 'string' as InputUnitType
  ) {
    // Store the initial value for error messages.
    this.initialValue = value;

    // Set the unitType of the value.
    this.unitType = unitType;

    // Set the initial value.
    this.value = this.typeGuard(value);
  }

  public to<
    OutputUnitType extends keyof StringConversionMap[InputUnitType],
    OptionsType
  >(unit: OutputUnitType, options?: OptionsType) {
    const conversionFunction = stringConversions[this.unitType][
      unit
    ] as StringConversionFunction<OptionsType>;

    this.value = conversionFunction(this.value, options);
    return this;
  }

  // Method to convert the value to a string.
  public toString(fallback: string = STRING_PLACEHOLDER): string {
    if (typeof this.value === 'string') {
      return this.value;
    }

    return fallback;
  }

  // Method to type guard the initial value.
  // This is used in the constructor to ensure that the initial value is properly guarded.
  private typeGuard(value: unknown) {
    if (typeof value !== 'string') {
      this.throw(
        `Invalid value: Cannot convert value '${value}' to type '${this.unitType}'`
      );
      return this.initialValue;
    }

    return value as any;
  }

  private throw(msg?: string) {
    msg;
    // console.warn(
    //   msg ??
    //     `Invalid value: Cannot convert value '${this.initialValue}' to unit type '${this.unitType}'`
    // );
  }
}
