import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { GlobalStore, GlobalStoreValues } from './global.store.types';

const defaultValues: GlobalStoreValues = {
  isProjectMenuOpen: false,
  lastProjectMenuOpen: undefined,
  sortProps: undefined,
  filterProps: undefined
};

export const useGlobalStore = createWithEqualityFn<GlobalStore>()(
  (set) => ({
    ...defaultValues,

    actions: {
      setIsProjectMenuOpen: (value) =>
        set((state) => {
          const isOpening = !!value;
          const isOpeningDifferentMenu =
            isOpening && state.lastProjectMenuOpen !== value;
          return {
            isProjectMenuOpen: value,
            lastProjectMenuOpen: isOpening ? value : state.lastProjectMenuOpen,
            sortProps: isOpeningDifferentMenu
              ? defaultValues.sortProps
              : state.sortProps,
            filterProps: isOpeningDifferentMenu
              ? defaultValues.filterProps
              : state.filterProps
          };
        }),
      setSortProps: (value) => set(() => ({ sortProps: value })),
      setFilterProps: (value) => set(() => ({ filterProps: value }))
    }
  }),
  shallow
);
