import { UseInfiniteQueryResult } from '@tanstack/react-query';

import {
  InsightsRow,
  InsightsRowsResponse
} from '@/fine-tune/types/query.types';

interface GetProcDataMetricsResponse {
  data: InsightsRow[] | null;
  error: string | null | {};
  isIdle: boolean;
}

export const getProcDataMetrics = (
  insightsRows: UseInfiniteQueryResult<InsightsRowsResponse, unknown>
): GetProcDataMetricsResponse => {
  const { data, error, fetchStatus } = insightsRows;

  const isIdle = fetchStatus === 'idle';

  if (error) {
    return { error, data: null, isIdle };
  }

  const noDataForQuery = !data?.pages?.[0]?.data_rows?.length;

  if (noDataForQuery) {
    return { error: 'No data.', data: null, isIdle };
  }

  const result = data?.pages?.flatMap((page) => page?.data_rows) || [];

  return {
    // @ts-expect-error - FIXME
    data: result,
    error: isIdle || result?.length ? null : 'No data.',
    isIdle
  };
};
