import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { MetricGroup } from '@/core/types/metric-config.types';

export const METRIC_GROUPS = '/prompts/rows/groups';

export const useMetricGroups = () => {
  // Query
  const queryKey = [METRIC_GROUPS];

  const queryFn = async () => {
    const res = await api.GET(METRIC_GROUPS);

    return res.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled: true,
    onError: () => {}
  });

  const groups: MetricGroup[] =
    response.data?.pages[0]?.map((group) => ({
      name: group.group_name!,
      label: group.group_label,
      description: group.group_description ?? undefined,
      icon: group.group_icon,
      metricKeys: group.columns
    })) ?? [];

  return {
    ...response,
    groups
  };
};
