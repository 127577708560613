import { useGenericPermissionsFlags } from './use-generic-permissions-flags/use-generic-permissions-flags';
import { useProjectPermissionsFlags } from './use-project-permissions-flags/use-project-permissions-flags';
import { useUserGroupMembersPermissionsFlags } from './use-user-group-members-permissions-flags/use-user-group-members-permissions-flags';
import { useUserGroupPermissionsFlags } from './use-user-group-permissions-flags/use-user-group-permissions-flags';
import { useUserPermissionsFlags } from './use-user-permissions/use-user-permissions-flags';

export const usePermissionsFlags = () => {
  const genericFlags = useGenericPermissionsFlags();
  const groupFlags = useUserGroupPermissionsFlags();
  const groupMembersFlags = useUserGroupMembersPermissionsFlags();
  const projectFlags = useProjectPermissionsFlags();
  const userFlags = useUserPermissionsFlags();

  return {
    ...genericFlags,
    ...projectFlags,
    ...groupFlags,
    ...groupMembersFlags,
    ...userFlags
  };
};
