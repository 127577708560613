import {
  cleanNotificationsQueue,
  showNotification as mantineShowNotification
} from '@mantine/notifications';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import check from 'check-types';
import React, { isValidElement } from 'react';

import { GENERIC_ERROR_MESSAGE } from '@/core/constants/strings.constants';
import { Error } from '@/core/types/response.types';

type Type = 'error' | 'success' | 'warning';
type Message = string | React.ReactNode;

// Exported for testing only
export const parseMessage = (message?: Message, type?: Type) => {
  if (!message) {
    return null;
  }

  if (check.string(message) || isValidElement(message)) {
    return message;
  }

  if (type === 'error') {
    return 'Something went wrong there. These errors are automatically tracked.';
  }

  return null;
};

interface ShowNotificationProps {
  autoSize?: boolean;
  title?: string | React.ReactNode;
  message?: Message;
  type?: Type;
  autoClose?: number | boolean;
  icon?: React.ReactNode;
  color?: string;
  loading?: boolean;
  onClose?: () => void;
}

export const showNotification = ({
  autoSize,
  title,
  loading,
  message,
  type,
  autoClose = 3500,
  icon,
  color,
  ...others
}: ShowNotificationProps) => {
  if (!title) {
    return;
  }

  const baseColor = color || 'green';

  let _icon = icon || <IconCheck size={16} />;
  let _color = loading ? 'brand' : baseColor;

  if (type === 'error') {
    _icon = <IconX size={16} />;
    _color = 'red';
  }

  if (type === 'warning') {
    _icon = <IconInfoCircle size={16} />;
    _color = 'yellow';
  }

  mantineShowNotification({
    loading,
    title,
    message: parseMessage(message, type),
    icon: _icon,
    color: _color,
    autoClose,
    styles: () => ({
      root: {
        maxHeight: autoSize ? 'unset !important' : 300
      }
    }),
    ...others
  });

  // Prevents tons of notifications from showing when many endpoints fail for the same reason
  cleanNotificationsQueue();
};

export const showErrorNotification = (
  title: string,
  error: Error,
  onClose?: () => void
) => {
  showNotification({
    title,
    message: error?.response?.data?.detail || GENERIC_ERROR_MESSAGE,
    type: 'error',
    onClose: onClose
  });
};
