// Worth noting that these values align with what's returned by the API, so be careful changing them
export const STATUS = {
  SUCCESS: 'success',
  COMPLETED: 'completed',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  COMPUTING: 'computing',
  PENDING: 'pending',
  UNSTARTED: 'unstarted',
  NOT_COMPUTED: 'not_computed',
  NOT_APPLICABLE: 'not_applicable',
  ERROR: 'error'
} as const;
