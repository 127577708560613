import useStore from '@/fine-tune/stores/store';

interface LabelProps {
  labelName: string;
  size?: string;
}

const Label = ({ labelName, size = 'md' }: LabelProps) => {
  const colorMap = useStore((state) => state.colorMap);

  const fontSize = size === 'md' ? '16px' : '14px';

  return (
    <span
      className='truncate w-max-200'
      data-testid='label'
      style={{
        padding: '1px 6px',
        borderRadius: '6px',
        fontSize,
        color: colorMap?.[labelName]?.text || 'gray',
        border: `1px solid ${colorMap?.[labelName]?.text || 'black'}`
      }}
      title={labelName}
    >
      {labelName}
    </span>
  );
};

export default Label;
