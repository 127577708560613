/*
 * downloadFile
 *
 * Handler for downloading a file.
 */

export const downloadFile = (name: string, content: Blob) => {
  const dummyElement = document.createElement('a');

  const file = new Blob([content], { type: content?.type || 'text/plain' });

  dummyElement.href = URL.createObjectURL(file);
  dummyElement.download = name;

  dummyElement.click();
  dummyElement.remove();
};
