import { InsightsRowsResponse } from '@/fine-tune/types/query.types';

interface UpdateEditedRowsProps {
  oldData: { pages: InsightsRowsResponse[] };
  samplesIds: number[];
  isDeleting: boolean;
  isRelabeling?: boolean;
  isNer?: boolean;
}
export const updateEditedRows = ({
  oldData,
  samplesIds,
  isDeleting,
  isRelabeling,
  isNer
}: UpdateEditedRowsProps) => {
  const updatedDataPages = oldData?.pages.map((page) => ({
    ...page,
    data_rows:
      page.data_rows.map((row) => {
        const isCurrentRowEdited = row.edited;
        let isIncludedInEdit = samplesIds.includes(row.id);

        if (isNer) {
          isIncludedInEdit = Boolean(
            row?.spans?.some((span) => samplesIds.includes(span.id))
          );
        }

        const newEditedValue = isDeleting
          ? isCurrentRowEdited && !isIncludedInEdit
          : isCurrentRowEdited || isIncludedInEdit;

        const newRelabeledValue =
          row.edit_relabeled || (isRelabeling && isIncludedInEdit);

        return {
          ...row,
          edited: newEditedValue,
          edit_relabeled: newRelabeledValue
        };
      }) || []
  }));
  return {
    ...oldData,
    pages: updatedDataPages
  };
};
