import { IconBrandAzure } from '@tabler/icons-react';
import Image from 'next/image';
import bedrockLogo from 'public/bedrock_logo.png';
import sagemakerLogo from 'public/sagemaker_logo.png';

import { components } from '@/core/types/api';

import {
  DataBricksIcon,
  LabelsStudioIcon,
  OpenAIIcon,
  VertexAIIcon,
  WriterLogoIcon
} from './brands.icons.constants';

export type IntegrationIds = components['schemas']['IntegrationName'];

export interface IntegrationCard {
  id: IntegrationIds;
  title: string;
  icon: React.ReactNode;
  description: string;
  name: string;
  type: 'labeling' | 'Data Storage' | 'LLM APIs' | 'AWS';
  showDisclaimer?: boolean;
  onSuccessCallback?: () => void;
}

export const INTEGRATION_CARDS: IntegrationCard[] = [
  {
    id: 'aws_bedrock',
    type: 'LLM APIs',
    name: 'AWS Bedrock',
    title: '',
    icon: (
      <Image alt='Bedrock Logo' height={24} src={bedrockLogo} width={131} />
    ),
    description: 'Integrate into models on AWS Bedrock'
  },
  {
    id: 'aws_sagemaker',
    type: 'LLM APIs',
    name: 'AWS Sagemaker',
    title: '',
    icon: (
      <Image alt='Sagemaker Logo' height={24} src={sagemakerLogo} width={150} />
    ),
    description: 'Integrate into models on AWS Sagemaker.'
  },
  {
    id: 'labelstudio',
    type: 'labeling',
    name: 'Label Studio',
    title: '',
    icon: <LabelsStudioIcon />,
    description: 'Open source data labeling platform'
  },
  {
    id: 'databricks',
    type: 'Data Storage',
    name: 'Databricks',
    title: '',
    icon: <DataBricksIcon />,
    description: 'Unified platform for large scale data management'
  },
  {
    id: 'azure',
    type: 'LLM APIs',
    name: 'Azure',
    title: 'Azure',
    icon: <IconBrandAzure color='black' size={22} />,
    description: "Use Azure's OpenAI service for interacting with your LLMs"
  },
  {
    id: 'openai',
    type: 'LLM APIs',
    name: 'OpenAI',
    title: '',
    icon: <OpenAIIcon />,
    description:
      'Use OpenAI APIs to interact with AI models developed by OpenAI'
  },
  {
    id: 'vertex_ai',
    type: 'LLM APIs',
    name: 'Vertex AI',
    title: 'Vertex AI',
    icon: <VertexAIIcon />,
    description: "Use Google's PaLM models"
  },
  {
    id: 'writer',
    type: 'LLM APIs',
    name: 'Writer',
    title: 'Writer',
    icon: <WriterLogoIcon />,
    description: "Use Writer's open source and proprietary LLMs"
  }
];
