import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const GET_EDITS_LABELS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/edits/labels';

export const useEditLabels = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  let isEnabled = Boolean(projectId && runId && split);

  const fetchEditLabels = async () => {
    const res = await api.GET(GET_EDITS_LABELS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [GET_EDITS_LABELS, { projectId, runId, split }],
    fetchEditLabels,
    {
      enabled: isEnabled
    }
  );

  return response;
};
