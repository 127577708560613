import {
  ClassificationErrorIcon,
  FalsePositiveIcon,
  LocalizationClassificationErrorIcon,
  MissedIcon
} from '../../core/constants/icons.constants';

interface ErrorData {
  label: string;
  text: string;
  icon?: JSX.Element;
}

export type ErrorKeys =
  | 'classification'
  | 'missed'
  | 'background'
  | 'class_confusion'
  | 'None';

export const SS_ERROR_MAPPING: Record<ErrorKeys, ErrorData> = {
  classification: {
    label: 'Classification Error',
    text: 'The labels assigned to the segmented regions in the Ground Truth and Prediction are inconsistent or incorrect.',
    icon: <ClassificationErrorIcon />
  },
  missed: {
    label: 'Missed error',
    text: 'An object was not identified by the model, resulting in the Ground Truth object being incorrectly classified as background.',
    icon: <MissedIcon />
  },
  background: {
    label: 'Background error',
    text: 'A segmentation mask was applied to the background in an area where no object was present in the Ground Truth.',
    icon: <FalsePositiveIcon />
  },
  class_confusion: {
    label: 'Class Confusion Error',
    text: 'Over 50% of the area in the ground truth polygon has the incorrect classification in the associated prediction.',
    icon: <LocalizationClassificationErrorIcon />
  },
  None: {
    label: 'None',
    text: ''
  }
};
