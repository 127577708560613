import { Group, HoverCard } from '@mantine/core';

import { InsightsRow } from '@/fine-tune/types/query.types';

import LabelBadge from '../label-badge';
import DepOrConfidence from '../../dep-or-confidence/dep-or-confidence';

interface LabelBadgeWithPopoverProps {
  isFilled?: boolean;
  isGhostOrMissed?: boolean;
  maxWidth?: number;
  sample: Partial<InsightsRow>;
  value: string;
}

const LabelBadgeWithPopover = ({
  isFilled = false,
  isGhostOrMissed = false,
  maxWidth = 250,
  sample,
  value
}: LabelBadgeWithPopoverProps) => {
  const variant = isFilled ? 'filled' : 'outline';

  return (
    <HoverCard
      withArrow
      data-testid='label-badge-with-popover'
      offset={2}
      shadow='md'
    >
      <HoverCard.Target>
        <LabelBadge
          withoutTooltip
          isGhostOrMissed={isGhostOrMissed}
          maxWidth={maxWidth}
          value={value}
          variant={variant}
        />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Group justify='space-between' wrap='nowrap'>
          <DepOrConfidence component='div' sample={sample} />
          <LabelBadge maxWidth={maxWidth} value={value} variant={variant} />
        </Group>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default LabelBadgeWithPopover;
