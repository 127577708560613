import { useSessionStorage } from '@mantine/hooks';
import { useEffect } from 'react';

import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { FlagTags } from '@/core/types/feature-flags.types';

// Flags coming from API.
export const useExternalFlags = () => {
  // Feature Flag Store
  const {
    getObserveAlertsFlag,
    getPromptFlag,
    getObserveFlag,
    getPromptLevelUncertaintyFlag,
    getCSVTrainingFlag,
    getGleapFlag,
    getHideCredentialsLoginFlag,
    getHideRequestAccessFlag
  } = useFeatureFlagsStore((s) => s.computed);

  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);

  // TODO: Combine this with the above
  const [observeAlertsFlag, setObserveAlertsFlag] = useSessionStorage({
    key: 'observeAlertsFlag',
    defaultValue: getObserveAlertsFlag()
  });

  const [csvTrainingFlag, setCSVTrainingFlag] = useSessionStorage({
    key: 'csvTrainingFlag',
    defaultValue: getCSVTrainingFlag()
  });

  const [promptFlag, setPromptFlag] = useSessionStorage({
    key: 'promptFlag',
    defaultValue: getPromptFlag()
  });

  const [observeFlag, setObserveFlag] = useSessionStorage({
    key: 'observeFlag',
    defaultValue: getObserveFlag()
  });

  const [promptLevelUncertaintyFlag, setPromptLevelUncertaintyFlag] =
    useSessionStorage({
      key: 'promptLevelUncertaintyFlag',
      defaultValue: getPromptLevelUncertaintyFlag()
    });

  const [gleapFlag, setGleapFlag] = useSessionStorage({
    key: 'gleapFlag',
    defaultValue: getGleapFlag()
  });

  const [hideCredentialsLoginFlag, setHideCredentialsLoginFlag] =
    useSessionStorage({
      key: 'hideCredentialsLoginFlag',
      defaultValue: getHideCredentialsLoginFlag()
    });

  const [hideRequestAccessFlag, setHideRequestAccessFlag] = useSessionStorage({
    key: 'hideRequestAccessFlag',
    defaultValue: getHideRequestAccessFlag()
  });

  useEffect(() => {
    if (featureFlags) {
      setObserveAlertsFlag(getObserveAlertsFlag());
      setPromptFlag(getPromptFlag());
      setObserveFlag(getObserveFlag());
      setPromptLevelUncertaintyFlag(getPromptLevelUncertaintyFlag());
      setCSVTrainingFlag(getCSVTrainingFlag());
      setHideCredentialsLoginFlag(getHideCredentialsLoginFlag());
      setHideRequestAccessFlag(getHideRequestAccessFlag());
    }
  }, [featureFlags]);

  return {
    observeAlerts: {
      description: 'Alerts for Observe',
      isEnabled: observeAlertsFlag,
      tag: [FlagTags.External],
      title: 'Observe Alerts',
      toggle: () => setObserveAlertsFlag(!observeAlertsFlag)
    },
    prompt: {
      description: 'Toggle Evaluate',
      isEnabled: promptFlag,
      title: 'Evaluate',
      toggle: () => setPromptFlag(!promptFlag),
      tag: [FlagTags.External]
    },
    promptLevelUncertainty: {
      description: 'Toggle Prompt Level Uncertainty',
      isEnabled: promptLevelUncertaintyFlag,
      title: 'Prompt Level Uncertainty',
      toggle: () => setPromptLevelUncertaintyFlag(!promptLevelUncertaintyFlag),
      tag: [FlagTags.External]
    },
    observe: {
      description: 'Toggle Observe',
      isEnabled: observeFlag,
      title: 'Observe',
      toggle: () => setObserveFlag(!observeFlag),
      tag: [FlagTags.External]
    },
    csvTraining: {
      description: 'Toggle CSV Training',
      isEnabled: csvTrainingFlag,
      title: 'CSV Training',
      toggle: () => setCSVTrainingFlag(!csvTrainingFlag),
      tag: [FlagTags.External]
    },
    gleap: {
      description: 'Toggle Gleap',
      isEnabled: gleapFlag,
      title: 'Gleap',
      toggle: () => setGleapFlag(!gleapFlag),
      tag: [FlagTags.External]
    },
    hideCredentialsLogin: {
      description: 'Hide Credentials Login',
      isEnabled: hideCredentialsLoginFlag,
      title: 'Credentials Login',
      toggle: () => setHideCredentialsLoginFlag(!hideCredentialsLoginFlag),
      tag: [FlagTags.External]
    },
    hideRequestAccess: {
      description: 'Hide Request Access',
      isEnabled: hideRequestAccessFlag,
      title: 'Request Access',
      toggle: () => setHideRequestAccessFlag(!hideRequestAccessFlag),
      tag: [FlagTags.External]
    }
  };
};
