import {
  Box,
  Grid,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Text
} from '@mantine/core';

import OdEditsCard from '@/fine-tune/components/cv-cards/od-card/od-edits-cart-card';
import { EditWithContent } from '@/fine-tune/types/response.types';

interface OdEditsDetailProps {
  detail?: EditWithContent;
  isLoading: boolean;
}

const OdEditsDetail = ({ detail, isLoading }: OdEditsDetailProps) => {
  const edits = detail?.content || [];

  if (isLoading) {
    return (
      <SimpleGrid>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SimpleGrid>
    );
  }

  return (
    <ScrollArea.Autosize mah={600}>
      <Grid columns={2} data-testid='od-edits-cart'>
        <Grid.Col span={1}>
          <Text c='gray.6' fw={600} size='sm' ta='left'>
            Original
          </Text>

          <Box mb='sm'>
            {edits?.map((edit) => (
              <Box key={edit.id} mb='sm'>
                <OdEditsCard sample={edit} />
              </Box>
            ))}
          </Box>
        </Grid.Col>
        <Grid.Col span={1}>
          <Text c='gray.6' fw={600} size='sm' ta='left'>
            Updated
          </Text>
          {edits?.map((edit) => (
            <Box key={edit.id} mb='sm'>
              <OdEditsCard detail={detail} sample={edit} />
            </Box>
          ))}
        </Grid.Col>
      </Grid>
    </ScrollArea.Autosize>
  );
};

export default OdEditsDetail;
