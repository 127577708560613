import { PROJECT_PATH, PROJECTS_PATH } from '@/core/constants/api.constants';
import {
  IProjectFilter,
  IProjectSort
} from '@/core/types/project-filters.types';
import { ProjectType } from '@/core/types/projects.types';

/**
 * A single source of truth for react query keys so that updating query caches stays
 * in sync with the correct queries.
 *
 * NOTE:
 *  - use `path` when updating multiple queries or searching for a query containing a specific entry
 *  - use `queryKey` when updating an individual query
 */
export default function QueryKeyFactory() {
  return {
    project: {
      path: PROJECT_PATH,
      queryKey: ({ projectId }: { projectId: string | null | undefined }) => [
        PROJECT_PATH,
        { projectId }
      ]
    },
    projects: {
      path: PROJECTS_PATH,
      queryKey: ({
        type,
        filters,
        sort
      }: {
        type: ProjectType;
        filters?: IProjectFilter[] | undefined;
        sort?: IProjectSort | undefined;
      }) => [PROJECTS_PATH, { type, filters, sort }]
    },
    bookmarkedProjects: {
      path: PROJECTS_PATH,
      queryKey: ({
        type,
        sort,
        filters
      }: {
        type: ProjectType;
        sort?: IProjectSort | undefined;
        filters?: IProjectFilter[] | undefined;
      }) => [`${PROJECTS_PATH}-bookmarked`, { type, sort, filters }]
    }
  };
}
