import { ActionIcon, TextInput, TextInputProps } from '@mantine/core';
import { useDebouncedValue, useId, useInputState } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import { forwardRef, useEffect } from 'react';

import SkeletonLoader from '@/core/components/atoms/skeleton-loader/skeleton-loader';

interface LiveSearchBarProps extends Omit<TextInputProps, 'onChange'> {
  label?: string;
  onChange?: (str: string) => void;
  onClear?: (str?: string) => void;
  debounceTime?: number;
  icon?: React.ReactNode;
  isVisible?: boolean;
  isLoading?: boolean;
}

const LiveSearchBar = forwardRef(
  (
    {
      defaultValue = '',
      onChange,
      onClear,
      onKeyDown,
      debounceTime = 250,
      icon = null,
      isLoading,
      isVisible = true,
      rightSection,
      ...props
    }: LiveSearchBarProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = useInputState<string>(defaultValue as string);
    const [debounced] = useDebouncedValue(value, debounceTime);

    const uuid = useId();

    const handleClearInput = () => {
      const input = document.getElementById(uuid);
      onClear?.(value);

      setValue('');
      input?.focus();
    };

    useEffect(() => {
      setValue(defaultValue as string);
    }, [defaultValue]);

    useEffect(() => {
      onChange?.(debounced);
    }, [debounced]);

    useEffect(() => {
      if (isVisible) {
        const input = document.getElementById(uuid);

        input?.focus();
      }
    }, [isVisible]);

    if (isLoading) {
      return <SkeletonLoader height={35} length={1} width={500} />;
    }

    return (
      <TextInput
        className='flex-1'
        id={uuid}
        leftSection={icon || <IconSearch size={16} />}
        ref={ref}
        rightSection={
          <>
            {rightSection}
            <ActionIcon
              aria-label='Clear search'
              color='gray'
              data-testid='clear-btn'
              mr='xs'
              size='xs'
              variant='subtle'
              onClick={handleClearInput}
            >
              <IconX size={16} />
            </ActionIcon>
          </>
        }
        styles={{
          wrapper: {
            flex: 1
          },
          input: {
            borderColor: '#E9E8ED'
          }
        }}
        value={value}
        onChange={setValue}
        onKeyDown={onKeyDown}
        {...props}
      />
    );
  }
);

export default LiveSearchBar;
