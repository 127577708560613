import { Box, Kbd } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons-react';
import _capitalize from 'lodash/capitalize';

interface ModKbdProps {
  character?: string;
  withShift?: boolean;
  withoutMod?: boolean;
  lineHeight?: number | 'initial';
  withAlt?: boolean;
  binding?: string;
}

/**
 * ModKbd
 *
 * Helper to consistently create keyboard shortcut component
 *
 */
const ModKbd = ({
  withShift = false,
  character = '',
  withoutMod = false,
  lineHeight = 'initial',
  withAlt = false,
  binding
}: ModKbdProps): JSX.Element => {
  const modKey = '⌘ / Ctrl';

  const parseCharacter = (char: string) => {
    switch (char) {
      case 'ArrowUp':
        return <IconArrowUp size={14} />;

      case '/':
        return '/';

      default:
        return char.toUpperCase();
    }
  };

  if (binding) {
    return (
      <Box>
        {binding?.split('+')?.map((key) => {
          const parsedKey = key === 'mod' ? modKey : _capitalize(key);
          return (
            <Kbd key={key} ml={4} style={{ lineHeight, width: 'max-content' }}>
              {parsedKey}
            </Kbd>
          );
        })}
      </Box>
    );
  }

  return (
    <Box className='align-center'>
      {withoutMod ? null : (
        <Kbd key='mod-key' style={{ lineHeight }}>
          {modKey}
        </Kbd>
      )}
      {withAlt && (
        <Kbd key='alt-key' ml={4} style={{ lineHeight, width: 'max-content' }}>
          Alt
        </Kbd>
      )}
      {withShift && (
        <Kbd
          key='shift-key'
          ml={4}
          style={{ lineHeight, width: 'max-content' }}
        >
          Shift
        </Kbd>
      )}
      {Boolean(character) && (
        <Kbd
          className='align-center '
          key='character-key'
          ml={4}
          style={{
            lineHeight,
            width: 'max-content'
          }}
        >
          {parseCharacter(character)}
        </Kbd>
      )}
    </Box>
  );
};

export default ModKbd;
