import _snakeCase from 'lodash/snakeCase';

import { useParametersStore } from '../parameters.store';
import {
  CamelToSnakeCaseMap,
  SnakeCaseParameters
} from '../parameters.store.types';

const autoConvertedParams = [
  'misclassifiedOnly',
  'correctlyClassified',
  'lmPercentile',
  'goldFilter',
  'predFilter',
  'classFilter',
  'driftScoreThreshold',
  'isDrifted',
  'mapThreshold',
  'clusterIds',
  'compareIds'
].reduce((map, key) => ({ ...map, [key]: _snakeCase(key) }), {});

// @ts-expect-error
const camelToSnakeCaseMap: CamelToSnakeCaseMap = {
  isRegex: 'regex',
  searchTerm: 'text_pat',
  spanSearch: 'span_text',
  isSpanRegex: 'span_regex',
  depHigh: 'data_error_potential_high',
  depLow: 'data_error_potential_low',
  likelyMislabelled: 'likely_mislabeled',
  isOnTheBoundary: 'is_otb',
  ...autoConvertedParams
};

export const useSnakeCaseParameters = (): Partial<SnakeCaseParameters> => {
  // NOTE: we select the entire store so that `differs` will re-compute when any parameter changes
  const params = useParametersStore();

  const {
    differs: { getDiff }
  } = params;

  const diff = getDiff();

  return Object.entries(diff).reduce((acc, [key, value]) => {
    if (key in camelToSnakeCaseMap) {
      return {
        ...acc,
        [camelToSnakeCaseMap[key as keyof CamelToSnakeCaseMap]]: value
      };
    }

    return acc;
  }, {});
};
