import {
  Center,
  HoverCard,
  Image,
  ImageProps,
  useMantineTheme
} from '@mantine/core';
import { useHover, useWindowEvent } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';

import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import useStore from '@/fine-tune/stores/store';
import { InsightsRow, OdBoundingBox } from '@/fine-tune/types/query.types';

import OdPopover from '../od-popover/od-popover';

export interface OdImageProps extends ImageProps {
  hiddenFeatures: Array<string | number | undefined>;
  sample: InsightsRow;
  showEdits?: boolean;
  showFilteredBoxes?: boolean;
  showLabel: boolean;
}

/**
 * OdImage
 *
 *
 *
 * @returns {React.Component} OdImage
 */
const OdImage = ({
  h,
  hiddenFeatures, // Hidden features are hidden at the per-image level.
  w,
  sample,
  showEdits = false,
  showFilteredBoxes = false, // Override option. Set to true and show all boxes regardless of is_active
  showLabel = true
}: OdImageProps) => {
  // Global Store
  const colorMap = useStore((state) => state.colorMap);

  // Computer Vision Store
  const { hideGold, hidePred } = useComputerVisionStore((s) => ({
    hideGold: s.hideGold,
    hidePred: s.hidePred
  }));

  // State
  const [renderedSVG, setRenderedSVG] = useState<JSX.Element>();

  // Ref
  const imgRef = useRef<HTMLImageElement>(null);
  const { ref, hovered } = useHover();

  // Hooks
  const { colors } = useMantineTheme();

  useEffect(() => {
    renderSVG();
  }, [hiddenFeatures, hideGold, hidePred, sample, showFilteredBoxes]);

  // Utilities
  const buildLabel = (
    x: number,
    y: number,
    fontSize: number,
    stroke: string,
    box: OdBoundingBox | InsightsRow
  ) => {
    const { gold, pred, confidence } = box || {};

    const label = gold ?? pred;
    const confidenceStr = pred ? confidence?.toFixed(3) : '';
    const textString = `${label} ${confidenceStr}`;

    return (
      <g>
        <defs>
          <filter height='1' id={`${stroke}-fill`} width='1' x='0' y='0'>
            <feFlood fill='white' floodColor={stroke} />
            <feComposite in='SourceGraphic' operator='atop' />
          </filter>
        </defs>
        <text
          color='white'
          fill='white'
          filter={`url(#${stroke}-fill)`}
          fontSize={fontSize}
          opacity={showLabel ? 1 : 0}
          x={x}
          y={y}
        >
          {textString}
        </text>
      </g>
    );
  };

  const getScale = (img: HTMLImageElement) => {
    const { width, height, naturalWidth, naturalHeight } = img;

    const imageAspectRatio = naturalWidth / naturalHeight;
    const containerAspectRatio = width / height;

    const [scaledWidth, scaledHeight] =
      imageAspectRatio >= containerAspectRatio
        ? [width, width / imageAspectRatio]
        : [height * imageAspectRatio, height];

    const widthMultiplier = scaledWidth / naturalWidth;
    const heightMultiplier = scaledHeight / naturalHeight;
    const scaledText = 16;

    return {
      widthMultiplier,
      heightMultiplier,
      scaledHeight,
      scaledWidth,
      scaledText
    };
  };

  const getStroke = (box: OdBoundingBox | InsightsRow) => {
    // gold - solid, pred - dashed
    const { gold, pred } = box;

    const isGold = Object.hasOwn(box, 'is_gold')
      ? (box as OdBoundingBox)?.is_gold
      : Boolean(gold);

    const dashArray = isGold ? 0 : 5;

    let stroke = isGold
      ? colorMap?.[gold!]?.background
      : colorMap?.[pred!]?.background;

    if (!stroke) {
      stroke = colors.brand[6];
    }

    return {
      dashArray,
      stroke
    };
  };

  const mapPolygons = (
    heightMultiplier: number,
    widthMultiplier: number,
    scaledText: number
  ) => {
    const boxes = sample?.boxes || [sample];
    const polys = [];

    if (!boxes) {
      return;
    }

    // Loop through the points and draw a rectangle for each one
    for (let i = 0; i < boxes.length; i++) {
      // @ts-expect-error
      const { id, bbox, gold, pred, is_active } = boxes[i] || {};
      const [xmin, ymin, xmax, ymax] = bbox || [0, 0, 0, 0];

      if (!showFilteredBoxes && !is_active) {
        continue;
      }

      // @ts-expect-error TODO: Fix me, types are tangled up
      if (hiddenFeatures.includes(boxes[i]?.error_type)) {
        continue;
      }

      if (hideGold && gold) {
        continue;
      }

      if (hidePred && pred) {
        continue;
      }

      if (hiddenFeatures.includes(Number(id))) {
        continue;
      }

      const { dashArray, stroke } = getStroke(boxes[i]);

      // Calculate the polygon vertices based on the bounding box
      // TODO: Convert this into a loop for Image Segmentation
      const x1 = xmin * widthMultiplier;
      const y1 = ymin * heightMultiplier;
      const x2 = xmax * widthMultiplier;
      const y2 = ymin * heightMultiplier;
      const x3 = xmax * widthMultiplier;
      const y3 = ymax * heightMultiplier;
      const x4 = xmin * widthMultiplier;
      const y4 = ymax * heightMultiplier;

      let textX = Math.min(x1, x2, x3, x4);
      let textY = Math.min(y1, y2, y3, y4);

      // Position text to the right if it's close to the left edge
      if (textX < 10) {
        textX = Math.max(x1, x2, x3, x4) - 80; // Subtract 80 to keep label within the bounds of the box
      }

      // Position text to the bottom if it's close to the top edge
      if (textY < 10) {
        textY = Math.max(y1, y2, y3, y4) - 5; // Subtract 5 to keep label inside the bounds of the box
      }

      // TODO: I can't get forward ref to work here. Need to figure out why and dedupe the code
      if (!showEdits) {
        polys.push(
          <g key={Number(id)}>
            <polygon
              data-testid='od-polygon'
              fill='transparent'
              points={`${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`}
              stroke={stroke}
              strokeDasharray={dashArray}
              strokeWidth={2}
            />
            {showLabel &&
              buildLabel(textX, textY, scaledText, stroke, boxes[i])}
          </g>
        );
      } else {
        polys.push(
          <HoverCard
            withinPortal
            radius='md'
            // @ts-expect-error
            ref={ref}
            shadow='md'
            width={280}
            zIndex={9999}
          >
            <HoverCard.Target>
              <g className='od-polygon' key={Number(id)}>
                <polygon
                  data-testid='od-polygon'
                  fill={stroke}
                  points={`${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`}
                  stroke={stroke}
                  strokeDasharray={dashArray}
                  strokeWidth={hovered ? 8 : 2}
                />
                {showLabel &&
                  buildLabel(textX, textY, scaledText, stroke, boxes[i])}
              </g>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <OdPopover row={sample} sample={boxes[i] as OdBoundingBox} />
            </HoverCard.Dropdown>
          </HoverCard>
        );
      }
    }

    return polys;
  };

  const renderSVG = () => {
    const img = imgRef.current;

    if (!img?.complete) {
      return;
    }

    // Take the image and get the scaled values based on aspect ratios.
    const {
      heightMultiplier,
      scaledHeight,
      scaledWidth,
      scaledText,
      widthMultiplier
    } = getScale(img);

    // Map the polygons
    const mappedPolygons = mapPolygons(
      heightMultiplier,
      widthMultiplier,
      scaledText
    );

    setRenderedSVG(
      <svg
        height={scaledHeight}
        style={{ position: 'absolute' }}
        width={scaledWidth}
      >
        {mappedPolygons}
      </svg>
    );
  };

  // Redraw the SVGs when the window resizes
  useWindowEvent('resize', renderSVG);

  return (
    <Center h='100%' pos='relative' w='100%'>
      <Image
        alt='Original Image'
        fit='contain'
        h={h}
        m='auto'
        ref={imgRef}
        src={sample?.image}
        w={w}
        onLoad={() => renderSVG()}
      />
      {renderedSVG}
    </Center>
  );
};

export default OdImage;
