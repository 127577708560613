import type { MantineSize } from '@mantine/core';
import { Text } from '@mantine/core';
import check from 'check-types';
import { useEffect, useState } from 'react';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

interface ClampedLineProps {
  clamp?: number;
  noClamp?: boolean;
  text?: string | number;
  size?: MantineSize;
}

const ClampedLine = ({
  text = '',
  noClamp,
  clamp = 2,
  size = 'md'
}: ClampedLineProps) => {
  const [lineClamp, setLineClamp] = useState(noClamp ? undefined : clamp);

  useEffect(() => {
    if (noClamp) {
      setLineClamp(undefined);
    } else if (!lineClamp) {
      setLineClamp(2);
    }
  }, [noClamp]);

  const toggleLine = () => {
    setLineClamp((prevClamp) => (!prevClamp ? clamp || 2 : undefined));
  };

  const isValidText = check.not.emptyString(text);

  return (
    <div
      style={{ maxHeight: '200px', overflow: 'hidden auto', cursor: 'pointer' }}
    >
      <Text
        c={!lineClamp ? 'contrast.1' : 'contrast.2'}
        data-testid='clamped-line'
        lineClamp={lineClamp}
        size={size}
        style={{
          fontStyle: isValidText ? 'normal' : 'italic'
        }}
        onClick={toggleLine}
      >
        {isValidText ? text : STRING_PLACEHOLDER}
      </Text>
    </div>
  );
};

export default ClampedLine;
