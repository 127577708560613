import { EmbeddingsState } from '@/fine-tune/stores/embeddings-store/embeddings.store.types';
import { PartialParameters } from '@/fine-tune/stores/parameters-store/parameters.store.types';
import { EditWithContent } from '@/fine-tune/types/response.types';

interface StoreValues {
  // Parameter Store Values
  params: PartialParameters;

  // TODO: Update typing
  // Base Store Params
  base: any;

  // Embeddings Store
  embeddings: Partial<EmbeddingsState>;
}
type filter = EditWithContent['filter'];
export const parseApiResponseToParams = (
  logic?: filter
): Partial<StoreValues> => {
  if (!logic) {
    return {};
  }

  const {
    class_filter,
    cluster_ids,
    data_error_potential_high,
    data_error_potential_low,
    drift_score_threshold,
    gold_filter,
    ids,
    lasso,
    likely_mislabeled,
    likely_mislabeled_dep_percentile,
    // @ts-expect-error: TODO: Detangle map_threshold
    map_threshold,
    meta_filter,
    misclassified_only,
    num_similar_to,
    pred_filter,
    regex,
    similar_to,
    span_regex,
    span_sample_ids,
    span_text,
    text_pat,
    is_drifted,
    is_otb

    // TODO: Figure out what to do with these params
    // data_embs,
    // exclude_ids,
  } = logic || {};

  // Clusters are being sent as meta filters from the API but used as filter params in the UI
  const clusterMetaIndex = (meta_filter || [])?.findIndex(
    (meta) => meta.name === 'cluster_id'
  );
  const hasClusterMeta = clusterMetaIndex !== -1;

  let _clusterIds = cluster_ids || [];

  if (hasClusterMeta) {
    _clusterIds = [
      ..._clusterIds,
      ...(meta_filter?.[clusterMetaIndex as number].isin as number[])
    ];
    meta_filter?.splice(clusterMetaIndex as number, 1);
  }

  const params = {
    classFilter: class_filter || [],
    clusterIds: _clusterIds,
    depHigh: data_error_potential_high ?? 1,
    depLow: data_error_potential_low ?? 0,
    driftScoreThreshold: is_drifted ? drift_score_threshold ?? 0.95 : null,
    goldFilter: gold_filter || [],
    isRegex: Boolean(regex),
    isSpanRegex: Boolean(span_regex),
    likelyMislabelled: !!likely_mislabeled,
    lmPercentile: likely_mislabeled_dep_percentile,
    mapThreshold: map_threshold || 0.5,
    metaFilter: meta_filter || [],
    misclassifiedOnly: !!misclassified_only,
    predFilter: pred_filter || [],
    searchTerm: text_pat || '',
    spanSearch: span_text || '',
    isDrifted: Boolean(is_drifted),
    isOnTheBoundary: Boolean(is_otb),
    sortBy: is_drifted ? 'drift_score' : 'data_error_potential'
  };

  const base = {
    similarToQuantity: num_similar_to ?? undefined,
    selectedRows: ids,
    selectedSpans: span_sample_ids,
    similarToIds: similar_to ?? undefined
  };

  const embeddings = {
    shapeSelection: lasso
  };

  // @ts-expect-error: TODO: Detangle map_threshold
  return { params, base, embeddings };
};
