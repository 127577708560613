/** Returns NaN if value could not be parsed */
export function getValueAsNumber(
  value: string | number | null | undefined
): number {
  return typeof value === 'string'
    ? parseFloat(value)
    : typeof value === 'number'
      ? value
      : NaN;
}
