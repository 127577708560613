import { components } from '@/core/types/api';
import { useEmbeddingsStore } from '@/fine-tune/stores/embeddings-store/embeddings.store';
import {
  useComputedParameters,
  useMetaFilters,
  useParametersStore,
  useSnakeCaseParameters
} from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';

export const useFilterParams = () => {
  const { isDataView, isInference, isOoc, isS2S } = useComputedParameters();

  const shapeSelection = useEmbeddingsStore((s) => s.shapeSelection);
  const tabSelection = useEmbeddingsStore((s) => s.tabSelection);
  const shapeIds = useEmbeddingsStore((s) => s.shapeIds);
  const tab = useParametersStore((s) => s.tab);

  const similarToQuantity = useStore((s) => s.similarToQuantity);
  const similarToIds = useStore((s) => s.similarToIds);
  const similarFromIds = useStore((s) => s.similarFromIds);
  const mlcTaskAndIds = useStore((s) => s.mlcTaskAndIds);
  const showEditedRows = useStore((s) => s.showEditedRows);

  const storeParams = useSnakeCaseParameters();

  const metaFilters = useMetaFilters();

  let filter_params = {
    ...storeParams
  } as components['schemas']['FilterParams'];

  if (storeParams.likely_mislabeled) {
    filter_params.likely_mislabeled = true;
    filter_params.likely_mislabeled_dep_percentile =
      100 - (storeParams.lm_percentile ?? 100);
  }

  if (!showEditedRows) {
    filter_params.is_edited = false;
  }

  if (isInference) {
    delete filter_params.data_error_potential_high;
    delete filter_params.data_error_potential_low;
  }

  if (similarToIds?.length) {
    filter_params.similar_to = similarToIds;
    filter_params.num_similar_to = similarToQuantity;
  }

  if (mlcTaskAndIds?.ids?.length) {
    filter_params.ids = mlcTaskAndIds?.ids;
  }

  if (similarFromIds?.length) {
    // Since we are not using similar to, similar quantity won't be used by the API so we need to limit it in the UI
    filter_params.ids = similarToQuantity
      ? similarFromIds.slice(0, similarToQuantity)
      : similarFromIds;
    // similarToIds will contain ids from other split, so if we add it as parameter it will return no results
    filter_params.similar_to = undefined;
  }

  if (shapeSelection) {
    const dontApplyShape = isS2S && tabSelection !== tab;
    if (dontApplyShape) {
      filter_params.ids = shapeIds;
    } else {
      filter_params.lasso = shapeSelection;
    }

    filter_params.data_embs = isDataView;
  }

  if (metaFilters?.length) {
    filter_params.meta_filter = metaFilters;
  }

  if (isOoc) {
    filter_params.is_drifted = true;
  }

  if (storeParams.is_drifted || storeParams.is_otb) {
    filter_params.drift_score_threshold = storeParams.drift_score_threshold;
  } else {
    delete filter_params.drift_score_threshold;
  }

  return filter_params;
};
