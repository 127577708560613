import { useSessionStorage } from '@mantine/hooks';

import { defaults as defaultGroupPermissions } from '@/core/hooks/use-permissions/use-user-group-permissions';
import { FlagTags } from '@/core/types/feature-flags.types';
import {
  GroupAction,
  GroupPermissions
} from '@/core/types/user-permissions.types';

export function useUserGroupPermissionsFlags() {
  const [groupFlag, setGroupFlag] = useSessionStorage({
    key: 'group',
    defaultValue: false
  });

  const [groupPermissions, setGroupPermissions] =
    useSessionStorage<GroupPermissions>({
      key: 'group-permissions',
      defaultValue: defaultGroupPermissions
    });

  return {
    groupPermissions: {
      description: 'Select Group permissions',
      isEnabled: groupFlag,
      tag: [FlagTags.Permission],
      title: 'Group Permissions',
      values: groupPermissions,
      toggle: () => {
        const newValue = !groupFlag;
        setGroupFlag(newValue);
        if (newValue === false) {
          setGroupPermissions(defaultGroupPermissions);
        }
      },
      subFlags: Object.keys(groupPermissions || []).map((key) => ({
        description: `toggle ${key} permissions`,
        isEnabled: groupPermissions[key as GroupAction].allowed,
        tag: [FlagTags.Permission],
        title: key,
        toggle: () =>
          setGroupPermissions((current) => ({
            ...current,
            [key]: {
              ...current[key as GroupAction],
              allowed: !current[key as GroupAction].allowed
            }
          }))
      }))
    }
  };
}
