export const getDriftScoreColor = (driftScore?: number | null) => {
  const range = DRIFT_SCORE_BUCKET_RANGES.find(
    (bucket) =>
      (driftScore || 0) >= bucket.min && (driftScore || 0) <= bucket.max
  );
  return range?.color || '#000';
};

export const DRIFTED_COLORS = [
  '#FA4D4D',
  '#FB6B49',
  '#FC8945',
  '#FDA741',
  '#FEC53D',
  '#E5D337',
  '#B1D12F',
  '#7ECE26',
  '#4ACC1E',
  '#16CA16'
];

const DRIFTED_COLOR_BOUNDS = [
  1, 0.99, 0.97, 0.95, 0.93, 0.9, 0.87, 0.82, 0.75, 0.5, 0
];

export const DRIFT_SCORE_BUCKET_RANGES = DRIFTED_COLORS.map((color, i) => {
  const max = DRIFTED_COLOR_BOUNDS[i];
  const min = DRIFTED_COLOR_BOUNDS[i + 1];

  return {
    color,
    min,
    max,
    label: `${min.toFixed(2)} - ${max.toFixed(2)}`
  };
});
