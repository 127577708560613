import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { InsightsState } from './insights.store.types';

export const useInsightsStore = createWithEqualityFn<InsightsState>()(
  (set) => ({
    activeInsightsCard: null,
    disabledInsightsCards: [],

    isInsightsColumnVisible: true,

    actions: {
      setActiveInsightsCard: (id) => {
        set(() => ({ activeInsightsCard: id }));
      },

      setDisabledInsightsCards: (ids) => {
        set(() => ({ disabledInsightsCards: ids }));
      },

      setIsInsightColumnVisible: (bool) =>
        set(() => ({ isInsightsColumnVisible: bool }))
    },

    computed: {}
  }),
  shallow
);
