import { components } from './api';

export const PROJECT_TYPE: Record<string, ProjectType> = {
  TRAINING_INFERENCE: 'training_inference',
  PROMPT_EVALUATION: 'prompt_evaluation',
  OBSERVE: 'llm_monitor',
  PROTECT: 'protect'
} as const;

export type ProjectType = components['schemas']['ProjectType'];

export type Project = components['schemas']['ProjectDBThin'];

export type Run = components['schemas']['RunDBThin'];
