import { IPromptRunParameters } from '../use-prompt-run-parameters/use-prompt-run-parameters';

// Changing the spacing will break the code snippets
const useCreateTemplateCodeSnippet = () => {
  const langChainSnippet = (
    parameters: IPromptRunParameters,
    template: string,
    templateKeywords?: string[]
  ) => {
    const keywords =
      templateKeywords?.map((keyword) => `${keyword} = "${keyword}"`) || [];

    return `from langchain.chat_models import ChatOpenAI
from langchain.prompts.chat import (
    ChatPromptTemplate,
    HumanMessagePromptTemplate,
)

template = """${template}"""

${keywords.join(`
`)}

prompt = ChatPromptTemplate.from_messages(
    [HumanMessagePromptTemplate.from_template(template)]
)

chat = ChatOpenAI(
    model_name="${parameters.configuration['Model']}",
    temperature=1.0,
    openai_api_key=os.getenv("OPENAI_API_KEY"),
    openai_organization=os.environ.get("OPENAI_ORGANIZATION"),
    max_tokens=${parameters.configuration['Max Length']},
    model_kwargs={
        "stop": ${parameters.configuration['Stop Sequences']?.join(', ')},
        "top_p": ${parameters.configuration['Top-P']},
        "frequency_penalty":${parameters.configuration['Frequency Penalty']},
        "presence_penalty": ${parameters.configuration['Presence Penalty']},},
    },
)

output = chat(prompt.format(${keywords
      .join(', ')
      .replaceAll('"', '')
      .replaceAll(' = ', '=')}).to_messages()).content`;
  };

  const openAISnippet = (
    parameters: IPromptRunParameters,
    template: string,
    templateKeywords?: string[]
  ) => {
    const keywords =
      templateKeywords?.map((keyword) => `${keyword} = "${keyword}"`) || [];

    return `import os
import openai

template = """${template}"""

${keywords.join(`
`)}

client = OpenAI(
    api_key=os.getenv("OPENAI_API_KEY"),
    organization=os.environ.get("OPENAI_ORGANIZATION"),
)

chat_completion = client.chat.completions.create(
    messages=[{"role": "user", "content": template.format(${keywords
      .join(', ')
      .replaceAll('"', '')})}],
    model="${parameters.configuration['Model']}",
    temperature=${parameters.configuration['Temperature']},
    max_tokens=${parameters.configuration['Max Length']},
    stop=${parameters.configuration['Stop Sequences'] || 'None'},
    top_p=${parameters.configuration['Top-P']},
    frequency_penalty=${parameters.configuration['Frequency Penalty']},
    presence_penalty=${parameters.configuration['Presence Penalty']},
)

output = chat_completion.choices[0].message.content`;
  };
  const cURLSnippet = (
    parameters: IPromptRunParameters,
    template: string,
    templateKeywords?: string[]
  ) => {
    const formattedTemplate =
      template?.replaceAll('{', '$')?.replaceAll('}', '') || '';

    const mappedKeywords = templateKeywords?.map(
      (keyword) => `$${keyword}=${keyword}`
    )?.join(`
`);
    return `${mappedKeywords || ''} 
    curl https://api.openai.com/v1/chat/completions /\

      -H "Content-Type: application/json" /\

      -H "Authorization: Bearer $OPENAI_API_KEY" /\

      -H "OpenAI-Organization: $OPENAI_ORGANIZATION" /\

      -d '{
        "model": "${parameters.configuration['Model']}",
        "messages": [{"role": "user", "content": "${formattedTemplate}"}],
        "temperature": ${parameters.configuration['Temperature']},
        "max_tokens": ${parameters.configuration['Max Length']},
        "stop": ${parameters.configuration['Stop Sequences'] || null},
        "top_p": ${parameters.configuration['Top-P']}},
        "frequency_penalty": ${parameters.configuration['Frequency Penalty']},
        "presence_penalty": ${parameters.configuration['Presence Penalty']}
      }'`;
  };

  return { langChainSnippet, openAISnippet, cURLSnippet };
};

export default useCreateTemplateCodeSnippet;
