import { Box, Button, Group, Text } from '@mantine/core';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { ContextModalPropsWithRowId } from '@/core/hooks/use-modals/use-modals.types';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { EDIT_ACTIONS } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';
import { CreateEditArgs } from '@/fine-tune/types/mutation.types';

const ReplaceWithGoldModal = ({
  id,
  innerProps
}: ContextModalPropsWithRowId) => {
  const rowId = innerProps?.rowId || 0;

  // Hooks
  const { createEditMutation } = useEditCreate(rowId);
  const { closeModal } = useModals();

  // Event Handlers
  const handleSubmit = () => {
    const body: CreateEditArgs = {
      edit_action: EDIT_ACTIONS.RELABEL_AS_PRED
    };

    createEditMutation.mutate(body);
    closeModal(id);
  };

  return (
    <Box data-testid='set-label-modal'>
      <Text c='colors.gray.6' size='xs'>
        You can always undo this change from the data change log
      </Text>
      <Group align='flex-end' justify='flex-end' mt='lg'>
        <Button color='brand' variant='subtle' onClick={() => closeModal(id)}>
          Cancel
        </Button>
        <Button color='brand' variant='filled' onClick={handleSubmit}>
          Set Labels
        </Button>
      </Group>
    </Box>
  );
};

export default ReplaceWithGoldModal;
