import {
  ActionIcon,
  ActionIconProps,
  CopyButton,
  Tooltip
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export interface HoverCopyButtonProps extends ActionIconProps {
  copyText?: string;
  copiedText?: string;
  copyIcon?: JSX.Element;
  copiedIcon?: JSX.Element;
  value?: string;
  isVisible: boolean;
}

export const HoverCopyButton = ({
  copyText = 'Copy',
  copiedText = 'Copied',
  copyIcon = <IconCopy size='1rem' />,
  copiedIcon = <IconCheck size='1rem' />,
  value = '',
  isVisible,
  ...others
}: HoverCopyButtonProps) => {
  return (
    <CopyButton value={value}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? copiedText : copyText}
          zIndex={Z_INDEX.TOOLTIPS}
        >
          <ActionIcon
            data-testid='copy-button'
            size='sm'
            style={{
              transition: '.1s ease-in-out',
              opacity: isVisible ? 1 : 0,
              zIndex: Z_INDEX.TOOLTIPS
            }}
            variant='transparent'
            onClick={copy}
            {...others}
          >
            {copied ? copiedIcon : copyIcon}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
};
