import { toLower } from 'lodash';

import {
  MetricsColumn,
  MetricsGroupedColumns
} from '@/core/types/metrics-table.types';

export const usePromptExportModal = () => {
  const filterColumns = (columns: MetricsColumn[], columnSearch: string) =>
    columns
      .map((column) =>
        toLower(column.label ?? column.name).includes(toLower(columnSearch))
          ? column
          : undefined
      )
      .filter((column) => column);

  const groupChecked = (
    group: MetricsGroupedColumns,
    selectedColumns: string[]
  ) => {
    return group.columns.every((column) =>
      selectedColumns.includes(column.name)
    );
  };

  const groupIndeterminate = (
    group: MetricsGroupedColumns,
    selectedColumns: string[]
  ) => {
    return group.columns.some((column) =>
      selectedColumns.includes(column.name)
    );
  };

  const handleCheck = (
    metric: string,
    selectedColumns: string[],
    setSelectedColumns: (columnIds: string[]) => void
  ) => {
    selectedColumns.includes(metric)
      ? setSelectedColumns(
          selectedColumns.filter((column) => column !== metric)
        )
      : setSelectedColumns([...selectedColumns, metric]);
  };

  return {
    filterColumns,
    groupChecked,
    groupIndeterminate,
    handleCheck
  };
};
