import { Box, Divider, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';
import Metric from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';
import { ThumbRating } from '@/core/components/molecules/thumb-rating/thumb-rating';
import { NumberUnit } from '@/core/utils/unit-conversions';

interface AggregateThumbRatingProps {
  dislikeRatio: number;
  explanation?: string;
  likeRatio: number;
  singular?: boolean;
  title?: string;
  unratedRatio: number;
}

export const AggregateThumbRating = ({
  likeRatio,
  dislikeRatio,
  unratedRatio,
  singular,
  title,
  explanation
}: AggregateThumbRatingProps) => {
  const formatRatio = (ratio: number) => {
    return new NumberUnit(ratio)
      .to('percentage', { places: 2, asDecimal: true })
      .toString({
        style: 'percent',
        maximumFractionDigits: 2
      });
  };
  const formattedLikeRatio = formatRatio(likeRatio);
  const formattedDislikeRatio = formatRatio(dislikeRatio);
  const formattedUnratedRatio = formatRatio(unratedRatio);

  const highlightDislike =
    dislikeRatio > likeRatio && dislikeRatio > unratedRatio;

  const highlightUnrated =
    unratedRatio > likeRatio && unratedRatio > dislikeRatio;

  return (
    <MetricPopover
      body={
        <Box>
          <Box p='sm'>
            <Text c='gray.2' fw={600} size='sm'>
              {title ?? 'Like/Dislike'}
            </Text>
            <Stack gap='xs'>
              <Group justify='space-between'>
                <ThemeIcon c='gray.5' variant='transparent'>
                  <IconThumbUpFilled />
                </ThemeIcon>
                <Metric color='dark' variant='filled'>
                  {formattedLikeRatio}
                </Metric>
              </Group>
              <Group justify='space-between'>
                <ThemeIcon c='red.6' variant='transparent'>
                  <IconThumbDownFilled />
                </ThemeIcon>
                <Metric color='dark' variant='filled'>
                  {formattedDislikeRatio}
                </Metric>
              </Group>
              <Group justify='space-between'>
                <DataNotAvailable />
                <Metric color='dark' variant='filled'>
                  {formattedUnratedRatio}
                </Metric>
              </Group>
            </Stack>
          </Box>
          {explanation && (
            <>
              <Divider />
              <Box p='sm'>
                <Text c='gray.2' size='sm'>
                  {explanation}
                </Text>
              </Box>
            </>
          )}
        </Box>
      }
    >
      <Group data-testid='aggregate-thumb-rating' gap={0} wrap='nowrap'>
        <ThumbRating rating='like' ratio={likeRatio} />
        {!singular && (
          <>
            <ThumbRating
              highlightResult={highlightDislike}
              rating='dislike'
              ratio={dislikeRatio}
            />
            <ThumbRating
              highlightResult={highlightUnrated}
              ratio={unratedRatio}
            />
          </>
        )}
      </Group>
    </MetricPopover>
  );
};
