import {
  GroupPermissionBlock,
  GroupPermissions
} from '@/core/types/user-permissions.types';

import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

export const defaults: GroupPermissions = {
  update: {
    allowed: false,
    message: ''
  },
  list_members: {
    allowed: false,
    message: ''
  },
  join: {
    allowed: false,
    message: ''
  },
  request_to_join: {
    allowed: false,
    message: ''
  }
};

export function useUserGroupPermissions() {
  const { groupPermissions } = useFeatureFlags();
  const isEnabled = groupPermissions.isEnabled;
  const flagPermissions = groupPermissions.values;

  function getUserGroupPermissions(
    permissions: GroupPermissionBlock[]
  ): GroupPermissions {
    if (isEnabled) {
      return flagPermissions;
    }

    const permissionBlock = Object.fromEntries(
      permissions.map(({ action, ...permission }) => [action, permission])
    ) as GroupPermissions;

    return {
      update: permissionBlock.update || defaults.update,
      list_members: permissionBlock.list_members || defaults.list_members,
      join: permissionBlock.join || defaults.join,
      request_to_join:
        permissionBlock.request_to_join || defaults.request_to_join
    };
  }

  return {
    getUserGroupPermissions
  };
}
