import { Box, Select, Stack, useMantineTheme } from '@mantine/core';

import Button from '@/fine-tune/components/button/button';
import { IButtonsData } from '@/fine-tune/hooks/use-action-buttons/use-action-buttons';
import { Splits } from '@/fine-tune/stores/parameters-store/parameters.store.types';

interface ButtonGroupProps {
  buttons?: IButtonsData[];
  isVisible?: boolean;
  variant?: 'light' | 'filled';
  vertical?: boolean;
}

/**
 * ButtonGroup
 *
 * Variable length group of icon buttons
 *
 * @param {object} config - config object
 * @param {Array} config.buttons - array of objects with configuration options
 * @param {boolean} config.isVisible - visibility toggle
 * @returns {React.Component} ButtonGroup
 */
const ButtonGroup = ({
  variant = 'filled',
  buttons = [],
  vertical = false
}: ButtonGroupProps) => {
  const { colors } = useMantineTheme();

  const btnStyles = (disabled: boolean) => ({
    border: 'none',
    borderBottomLeftRadius: 0,
    borderLeft: `1px solid ${disabled ? colors.gray[4] : 'white'}`,
    borderTopLeftRadius: 0,
    fontWeight: 700,
    minHeight: 30
  });

  const buttonEls = buttons?.map(
    ({
      id,
      label,
      icon,
      disabled,
      tooltipText,
      onClick,
      rightIcon,
      selectOptions,
      selectValue,
      handleSelectChange
    }) => (
      <Box
        display={selectOptions ? 'flex' : 'block'}
        key={id}
        style={{ flexGrow: vertical ? 1 : 0 }}
      >
        <Button
          aria-label='label'
          bg={
            disabled
              ? 'gray.3'
              : variant === 'light'
                ? colors.brand[1]
                : colors.brand[6]
          }
          color={disabled ? 'gray' : 'brand'}
          copy={label}
          data-testid={id}
          disabled={!vertical && disabled}
          id={id}
          leftSection={icon}
          mr={selectOptions ? undefined : 'xs'}
          rightSection={rightIcon ? rightIcon : null}
          tooltipCopy={disabled && tooltipText}
          variant={variant}
          w={vertical ? '100%' : undefined}
          onClick={() => onClick?.()}
        />
        {selectOptions && (
          <Select
            color='brand'
            data={selectOptions}
            disabled={!vertical && disabled}
            ml={-4}
            mr={vertical ? '0px' : 'xs'}
            role='combobox'
            size='xs'
            style={{
              svg: {
                color: !disabled ? 'white !important' : ''
              }
            }}
            styles={
              variant === 'light'
                ? {
                    input: {
                      background: colors.brand[1],
                      color: colors.brand[6],
                      ...btnStyles(Boolean(disabled))
                    }
                  }
                : {
                    input: {
                      background: colors.brand[6],
                      color: 'white',
                      ...btnStyles(Boolean(disabled))
                    },
                    dropdown: {
                      minWidth: '160px !important'
                    }
                  }
            }
            value={selectValue}
            onChange={(val) =>
              handleSelectChange && handleSelectChange(val as Splits)
            }
          />
        )}
      </Box>
    )
  );

  if (vertical) {
    return <Stack>{buttonEls}</Stack>;
  }

  return <Box className='justify-space-between'>{buttonEls}</Box>;
};

export default ButtonGroup;
