import {
  GenericPermissionBlock,
  GenericPermissions,
  GenericResource
} from '@/core/types/user-permissions.types';

import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

export const defaults = {
  generic_create: {
    allowed: false,
    message: ''
  },
  generic_read: {
    allowed: false,
    message: ''
  },
  generic_update: {
    allowed: false,
    message: ''
  },
  generic_delete: {
    allowed: false,
    message: ''
  }
};

export function useGenericPermissions() {
  const { genericPermissions } = useFeatureFlags();
  const isEnabled = genericPermissions.isEnabled;
  const flagPermissions = genericPermissions.values;

  function getGenericPermissions(
    permissions: GenericPermissionBlock[],
    resource: GenericResource
  ): GenericPermissions {
    if (isEnabled) {
      for (const key in flagPermissions) {
        // @ts-expect-error stupid typescript
        flagPermissions[key].resource = resource;
      }

      return flagPermissions;
    }

    // Filter permissions by resource
    const filteredPermissions = permissions.filter(
      ({ resource: res }) => res === resource
    );

    // Create permission block
    const permissionBlock = Object.fromEntries(
      filteredPermissions.map(({ action, ...permission }) => [
        action,
        permission
      ])
    ) as GenericPermissions;

    // Return permissions
    return {
      generic_create: permissionBlock.generic_create || defaults.generic_create,
      generic_read: permissionBlock.generic_read || defaults.generic_read,
      generic_update: permissionBlock.generic_update || defaults.generic_update,
      generic_delete: permissionBlock.generic_delete || defaults.generic_delete
    };
  }

  return {
    getGenericPermissions
  };
}
