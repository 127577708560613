import { Paper, Text } from '@mantine/core';
/**
 * FilterChip
 *
 *
 *
 * @returns {React.Component} FilterChip
 */
const FilterChip = ({
  label,
  value
}: {
  label: string;
  value: string | number[] | null;
}) => {
  return (
    <Paper
      px={8}
      py={2}
      radius='md'
      style={({ colors }) => ({
        backgroundColor: colors.gray[1]
      })}
    >
      <Text c='gray.8' component='span' fw={500} size='sm'>
        {label}
      </Text>
      {value && (
        <Text c='gray.6' component='span' fw={400} ml={4} size='sm'>
          {value}
        </Text>
      )}
    </Paper>
  );
};

export default FilterChip;
