import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

const GET_INFERENCE_NAMES =
  '/projects/{project_id}/runs/{run_id}/inference_names';

/**
 * useInferenceNames
 */
export const useInferenceNames = () => {
  const { runId, projectId } = usePathParameters();

  const fetchInferenceNames = async () => {
    const res = await api.GET(GET_INFERENCE_NAMES, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string
        }
      }
    });

    return res.data;
  };

  const response = useQuery(
    [GET_INFERENCE_NAMES, { projectId, runId }],
    fetchInferenceNames,
    { enabled: Boolean(projectId && runId) }
  );

  return response;
};
