import { Group, MantineSize, Paper, Text, UnstyledButton } from '@mantine/core';

interface AmountCardProps {
  amount?: string | number;
  amountText?: string;
  children?: React.ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
  onSecondaryClick?: () => void;
  percentage?: string | number;
  secondaryText?: string;
  title?: string;
  titleColor?: string;
  mr?: MantineSize | number;
  amountComponent?: React.ReactNode;
}

const AmountCard = ({
  amount,
  amountText,
  amountComponent,
  title,
  percentage,
  secondaryText,
  titleColor,
  onClick,
  onSecondaryClick,
  isSelected = false,
  children,
  mr,
  ...mantineProps
}: AmountCardProps) => (
  <Paper
    data-testid='amount-card'
    mr={mr}
    p={12}
    radius='md'
    style={{
      border: isSelected
        ? `2px solid var(--mantine-color-brand-2)`
        : `1px solid var(--mantine-color-contrast-6)`,
      backgroundColor: 'var(--mantine-color-contrast-8)'
    }}
    onClick={onClick}
    {...mantineProps}
  >
    <Group gap={4} justify='space-between'>
      <Text c={titleColor} fw={600} mb={2} size='sm'>
        {title}
      </Text>
      {onSecondaryClick && (
        <UnstyledButton onClick={onSecondaryClick}>
          <Text c='gray.6' size='sm'>
            Clear all edits
          </Text>
        </UnstyledButton>
      )}
    </Group>

    {amountComponent || (
      <Text c='contrast.2' fw={700} mb={2} size='xl'>
        {amount}{' '}
        {amountText && (
          <Text c='contrast.2' component='span' fw={400} mr={2} size='sm'>
            {amountText}
          </Text>
        )}
      </Text>
    )}
    {secondaryText && (
      <Text fw={500} size='xs' style={{ color: '#9B98AE' }}>
        <Text component='span' mr={2} size='xs'>
          {percentage && `${percentage}%`}
        </Text>
        {secondaryText}
      </Text>
    )}

    {children}
  </Paper>
);

export default AmountCard;
