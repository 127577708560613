import { Grid } from '@mantine/core';

import HighlightWithReplace from '@/core/components/atoms/highlight-with-replace/highlight-with-replace';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';

import { BORDER_SHADE, GOLD_COL, SAMPLE_COL } from '../data-edits-cart-modal';
import Search from '../search/search';

/**
 * TcSearchContent
 *
 *
 *
 * @returns {React.Component} TcSearchContent
 */
export const TcSearchContent = () => {
  return <Search withReplace />;
};

interface TcTableContentProps {
  data: {
    text?: string | null;
    gold?: string | null;
  };
}
export const TcTableContent = ({ data }: TcTableContentProps) => {
  const searchTerm = useDataEditsStore((state) => state.searchTerm);
  const replaceTerm = useDataEditsStore((state) => state.replaceTerm);

  return (
    <>
      <Grid.Col
        data-testid='tc-table-content'
        span={SAMPLE_COL}
        style={{
          borderRight: `1px solid var(--mantine-color-gray-${BORDER_SHADE})`
        }}
      >
        <HighlightWithReplace
          find={searchTerm}
          fullText={data?.text || ''}
          replace={replaceTerm}
        />
      </Grid.Col>
      <Grid.Col className='align-center justify-center' span={GOLD_COL}>
        <LabelBadge value={data?.gold || ''} />
      </Grid.Col>
    </>
  );
};
