import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { EVALUATE_COLUMNAR_RUNS_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { useQueryParameters } from '@/evaluate/hooks/use-query-parameters/use-query-parameters';

import { GET_PROMPT_TEMPLATES } from '../use-prompt-template/use-get-prompt-templates/use-get-prompt-templates';

export const useSelectPromptTemplate = () => {
  const queryClient = useQueryClient();
  // Parameters
  const { get } = useQueryParameters();
  const templateId = get('templateId');

  const { projectId } = usePathParameters();

  const queryKey = [GET_PROMPT_TEMPLATES, { projectId, templateId }];

  // API Path
  const path =
    '/projects/{project_id}/templates/{template_id}/versions/{version}';

  const handleSelectTemplate = async ({
    templateId,
    version
  }: {
    templateId: string;
    version: number;
  }) => {
    const res = await api.PUT(path, {
      params: {
        path: {
          project_id: projectId!,
          template_id: templateId!,
          version: version!
        }
      }
    });

    return res.data;
  };

  const mutation = useMutation(handleSelectTemplate, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([EVALUATE_COLUMNAR_RUNS_PATH]);
      queryClient.setQueryData(
        queryKey,
        // @ts-expect-error
        (oldData: components['schemas']['BasePromptTemplateResponse'][]) => {
          return oldData.map((template) => {
            if (template.id === response?.id) {
              return response;
            }

            return template;
          });
        }
      );
    },
    onError: () => {}
  });

  return mutation;
};
