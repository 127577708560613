import { Box, BoxProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { PropsWithChildren } from 'react';

import {
  HoverCopyButton,
  HoverCopyButtonProps
} from '../../atoms/hover-copy-button/hover-copy-button';

interface CopyBoxProps extends BoxProps {
  buttonProps?: Omit<HoverCopyButtonProps, 'isVisible'>;
  copyText: string;
  variant?: 'light' | 'dark';
}

/**
 *
 * CopyBox
 * @description An extension of a Mantine Box component that wraps children with a copy button.
 * @returns
 */
const CopyBox = ({
  children,
  buttonProps,
  copyText,
  variant = 'light',
  ...boxProps
}: PropsWithChildren<CopyBoxProps>) => {
  const { ref, hovered } = useHover();

  // Styles
  const variantStyles =
    variant === 'light'
      ? {
          color: 'gray.7',
          bg: 'transparent'
        }
      : {
          color: 'white',
          bg: 'transparent'
        };

  return (
    <Box data-testid='copy-box' pos='relative' ref={ref} {...boxProps}>
      <Box pr='sm'>{children}</Box>
      <HoverCopyButton
        pos='absolute'
        right={4}
        top={4}
        {...variantStyles}
        {...buttonProps}
        isVisible={Boolean(hovered)}
        value={copyText}
      />
    </Box>
  );
};

export default CopyBox;
