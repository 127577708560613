import {
  ErrorMetricStatus,
  MetricStatusType,
  NotApplicableMetricStatus,
  NotComputedMetricStatus,
  SuccessMetricStatus
} from '@/core/types/metric-status.types';

export function isMetricStatus(value: any): value is MetricStatusType {
  return (
    value != null &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    'status_type' in value
  );
}

export function isSuccessMetricStatus(
  value: any
): value is SuccessMetricStatus {
  return isMetricStatus(value) && value?.status_type === 'success';
}

export function isErrorMetricStatus(value: any): value is ErrorMetricStatus {
  return (
    isMetricStatus(value) &&
    (value?.status_type === 'error' || value?.status_type === 'failed')
  );
}

export function isNotApplicableMetricStatus(
  value: any
): value is NotApplicableMetricStatus {
  return isMetricStatus(value) && value.status_type === 'not_applicable';
}

export function isNotComputedMetricStatus(
  value: any
): value is NotComputedMetricStatus {
  return isMetricStatus(value) && value.status_type === 'not_computed';
}

export function isInvalidMetricValue(metricStatus: any): boolean {
  return (
    metricStatus == null ||
    (isMetricStatus(metricStatus)
      ? (isSuccessMetricStatus(metricStatus) && metricStatus.value == null) ||
        isNotComputedMetricStatus(metricStatus) ||
        isNotApplicableMetricStatus(metricStatus)
      : false)
  );
}
