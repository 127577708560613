import {
  ActionIcon,
  Badge as MantineBadge,
  BadgeVariant,
  MantineSize
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { forwardRef } from 'react';

interface BadgeProps {
  border?: 'solid' | 'none' | 'dashed';
  children?: React.ReactNode;
  clearable?: boolean;
  color?: string;
  customColor?: string;
  copy?: string;
  onClear?: () => void;
  rightSection?: React.ReactNode | number | string;
  variant?: BadgeVariant;
  radius?: MantineSize;
  style?: React.CSSProperties;
  size?: MantineSize;
  fullWidth?: boolean;
  key?: string;
  onClick?: () => void;
}

const Badge = forwardRef(
  (
    {
      clearable,
      children,
      copy,
      onClear,
      color = 'gray',
      fullWidth = false,
      ...styling
    }: BadgeProps,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const removeButton = (
      <ActionIcon
        color='contrast.3'
        data-testid='badge-clear-btn'
        radius='xl'
        size='xs'
        variant='transparent'
        onClick={() => onClear?.()}
      >
        <IconX size={16} />
      </ActionIcon>
    );

    return (
      <MantineBadge
        className='no-transform'
        color={color}
        data-testid='badge'
        fullWidth={fullWidth}
        mx={2}
        radius='sm'
        ref={ref}
        rightSection={clearable ? removeButton : null}
        size='lg'
        style={{
          backgroundColor: 'var(--mantine-color-contrast-7)',
          color: 'var(--mantine-color-contrast-1)',
          fontWeight: 500,
          padding: 8
        }}
        variant='filled'
        {...styling}
      >
        {copy || children}
      </MantineBadge>
    );
  }
);

export default Badge;
