import { ActionIcon, Box, Text, Tooltip } from '@mantine/core';
import { useClipboard, useHover } from '@mantine/hooks';
import {
  IconArrowBarDown,
  IconClipboardCheck,
  IconClipboardPlus
} from '@tabler/icons-react';
import { MouseEvent } from 'react';

import { showNotification } from '@/core/utils/show-notification/show-notification';

export interface TextSectionProps {
  isTruncated: boolean;
  splitLineBreaks?: boolean;
  value: string;
}

const TextSection = ({
  isTruncated,
  splitLineBreaks,
  value
}: TextSectionProps) => {
  const { ref, hovered } = useHover();
  // FIXME: This seems like it's causing a memory leak
  const clipboard = useClipboard({ timeout: 2000 });

  const copyToClipboard = (e: MouseEvent) => {
    e?.stopPropagation();
    clipboard.copy(value);

    showNotification({
      title: 'Success!',
      message: 'Sample text copied to clipboard'
    });
  };

  const scrollDown = (e: MouseEvent) => {
    e?.stopPropagation();

    const container = ref?.current;
    if (container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const isLongText = value?.length > 1000;
  const showScrollToEndButton = isLongText && !isTruncated;

  const truncatedEl = splitLineBreaks ? (
    <Text lineClamp={1} size='sm'>
      {value?.trim().split('\n')[0]}
    </Text>
  ) : (
    <Text lineClamp={1} size='sm'>
      {value?.trim()}
    </Text>
  );

  const untruncatedEl = splitLineBreaks ? (
    value?.split('\n')?.map((line, index) => (
      <Text key={index} size='sm' ta='start'>
        {line}
      </Text>
    ))
  ) : (
    <Text size='sm'>{value}</Text>
  );

  return (
    <Box className='position-relative' data-testid='text-section' ref={ref}>
      {hovered && (
        <Box className='position-absolute' right={0} top={0}>
          <Box
            className='position-sticky'
            style={{
              top: 50,
              left: 'calc(100% - 30px)',
              width: 30
            }}
          >
            <ActionIcon
              color={clipboard.copied ? 'green' : 'gray'}
              mb={2}
              variant={clipboard.copied ? 'filled' : 'light'}
              onClick={copyToClipboard}
            >
              {clipboard.copied ? (
                <IconClipboardCheck size={16} />
              ) : (
                <IconClipboardPlus size={16} />
              )}
            </ActionIcon>
            {showScrollToEndButton && (
              <Tooltip label='Scroll to the bottom' position='right-end'>
                <ActionIcon color='gray' variant='light' onClick={scrollDown}>
                  <IconArrowBarDown size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
      {isTruncated ? truncatedEl : untruncatedEl}
    </Box>
  );
};

export default TextSection;
