import { Group, Text } from '@mantine/core';

import Metric, { MetricProps } from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';
import { ScorerType } from '@/core/constants/scorer-type.constants';

import ScorerTypePill from '../../atoms/scorer-type-pill/scorer-type-pill';

const NotAvailableMetric = ({
  value = 'N/A',
  explanation = 'This metric is not available',
  scorerType,
  ...metricProps
}: {
  explanation?: string;
  scorerType?: ScorerType | null | undefined;
} & MetricProps) => {
  return (
    <MetricPopover
      body={
        <>
          <Text px='md' py='sm' size='sm'>
            {explanation}
          </Text>
        </>
      }
      header={
        <Group
          gap='md'
          justify='space-between'
          pr='0.5rem'
          w='100%'
          wrap='nowrap'
        >
          <Metric value={value} variant='subtle' />
          {scorerType != null && <ScorerTypePill type={scorerType} />}
        </Group>
      }
    >
      <Metric value={value} {...metricProps} />
    </MetricPopover>
  );
};

export default NotAvailableMetric;
