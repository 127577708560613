import { useRouter } from 'next/router';

import { RouterParameters } from './use-query-parameters.types';
import { fromRouter, toRouter } from './utils';

export function useQueryParameters<T extends Record<string, any>>(
  defaultStoreParams: T,
  options?: {
    parsers?: {
      fromRouter?: (query: RouterParameters) => Partial<T>;
      toRouter?: (params: Partial<T>) => Record<string, string | string[]>;
    };
  }
) {
  const { parsers } = options || {};
  const _fromRouter = parsers?.fromRouter || fromRouter;
  const _toRouter = parsers?.toRouter || toRouter;

  // Router
  const router = useRouter();
  const isReady = router.isReady;
  const query = router.query;
  const pathname = router.pathname?.split('?')[0];

  // Computed
  const parsedQueryParams = _fromRouter(query);

  // Private Methods
  function safePush(values: Partial<T>) {
    // Merge the new values with the existing query params
    const newParams = { ...parsedQueryParams, ...values } as Partial<T>;

    // Filter out default values
    for (const [key, value] of Object.entries(newParams)) {
      const typedKey = key as keyof T;

      if (
        Object.hasOwn(defaultStoreParams, key) &&
        value === defaultStoreParams[typedKey]
      ) {
        delete newParams[typedKey];
      }
    }

    const routerSafeParams = _toRouter(newParams as T & Partial<T>);

    router.push(
      {
        pathname,
        query: routerSafeParams
      },
      undefined,
      {
        shallow: true
      }
    );
  }

  // Methods
  function get<K extends keyof T>(key: K): T[K] {
    // Get the value from the parsed query params
    const value = parsedQueryParams[key];

    if (value === undefined) {
      return defaultStoreParams[key];
    }

    return value as T[K];
  }

  // TODO: Make this type safe
  function reset<K extends keyof T>(keys: K | K[]) {
    const newParams = fromRouter<T>({ ...router.query });
    if (Array.isArray(keys)) {
      keys.forEach((key) => {
        newParams[key] = defaultStoreParams[key];
      });
    }
    if (typeof keys === 'string') {
      newParams[keys] = defaultStoreParams[keys];
    }

    safePush(newParams);
  }

  function set(values: Partial<T>) {
    const filteredParams: Partial<T> = {};
    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof T;
      // Filter out default values, undefined values, and values that are not in the default store params
      if (key in defaultStoreParams) {
        filteredParams[typedKey] = value;
      }
    }

    safePush(filteredParams);
  }

  return {
    isReady,
    set,
    get,
    reset
  };
}
