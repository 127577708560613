import { Box, Text } from '@mantine/core';

import Metric from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';

interface ScoreRatingProps {
  explanation?: string;
  score?: string | number | JSX.Element;
  title?: string;
}

export const ScoreRating = ({
  explanation,
  score,
  title
}: ScoreRatingProps) => {
  return (
    <MetricPopover
      body={
        explanation && (
          <Box p='sm'>
            <Text c='gray.2' fw={600} pb='sm' size='sm'>
              {title ?? 'Score Rating'}
            </Text>
            <Text c='gray.2' size='sm'>
              {explanation}
            </Text>
          </Box>
        )
      }
    >
      <Metric data-testid='score-rating' value={score} />
    </MetricPopover>
  );
};
