import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export interface PathParameters {
  projectId?: string;
  runId?: string;
}

export const usePathParameters = () => {
  const [params, setParams] = useState<PathParameters>({});

  const router = useRouter();

  useEffect(() => {
    if (router.isReady && !isEqual(router.query, params)) {
      setParams(router.query);
    }
  }, [router.query.projectId, router.query.runId]);

  return params;
};
