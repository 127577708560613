import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import api from '@/core/api';
import { Paths } from '@/core/constants/routes.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

import QueryKeyFactory from '../query-key-factory/query-key-factory';

export const useCurrentProject = (refetchInterval: number | false = false) => {
  let { projectId } = usePathParameters();

  const router = useRouter();

  // Dependencies
  const dependencies = [projectId];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Query
  const queryKey = QueryKeyFactory().project.queryKey({ projectId });
  const queryFn = async () => {
    const res = await api.GET(QueryKeyFactory().project.path, {
      params: {
        path: {
          project_id: projectId!
        }
      }
    });

    return res.data;
  };

  // Response
  return useQuery({
    queryKey,
    queryFn,
    enabled,
    refetchInterval,
    onError: () => {
      router.push({
        pathname: Paths.NOT_FOUND
      });
    }
  });
};
