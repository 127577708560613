import { Badge, Tooltip } from '@mantine/core';

export interface TruncatedGroupProps {
  cutoff?: number;
  isTruncated: Boolean;
  elements: JSX.Element[];
  labels?: string[];
  variant?: 'square' | 'round';
}

export const TruncatedGroup = ({
  cutoff = 3,
  isTruncated,
  elements,
  labels,
  variant = 'round'
}: TruncatedGroupProps) => {
  const overflowCount = elements?.length - cutoff;
  const showOverflowBadge = overflowCount > 0 && isTruncated;

  const mappedEls = elements?.map((el, i) => {
    if (i >= cutoff && isTruncated) return;

    return el;
  });

  const tooltipText = labels?.slice(cutoff, labels.length)?.join(', ');

  const badgeEl =
    variant === 'square' ? (
      <Badge p='0 5px' radius={0} size='md' tt='unset' variant='light'>
        {`+${overflowCount} labels`}
      </Badge>
    ) : (
      <Badge p={6} radius='md' size='lg' variant='light'>
        {`+${overflowCount}`}
      </Badge>
    );

  return (
    <>
      {mappedEls}
      {showOverflowBadge && (
        <Tooltip withinPortal label={tooltipText}>
          {badgeEl}
        </Tooltip>
      )}
    </>
  );
};
