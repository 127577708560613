import { useEffect, useRef } from 'react';

/** Runs the given callback only once when the component mounts */
export function useOnMount(callback: () => void) {
  let executed = useRef(false);

  useEffect(() => {
    if (!executed.current) {
      callback();
      executed.current = true;
    }
  });
}
