export type EditAction =
  | 'add_span'
  | 'create_new_label'
  | 'delete'
  | 'relabel'
  | 'relabel_as_pred'
  | 'select_for_label'
  | 'shift_span'
  | 'text_replaced'
  | 'update_text';

export const EDIT_ACTIONS: Record<string, EditAction> = {
  ADD_SPAN: 'add_span',
  CREATE_NEW_LABEL: 'create_new_label',
  DELETE: 'delete',
  RELABEL: 'relabel',
  RELABEL_AS_PRED: 'relabel_as_pred',
  SELECT_FOR_LABEL: 'select_for_label',
  SHIFT_SPAN: 'shift_span',
  UPDATE_TEXT: 'update_text'
};

export interface DataEditsStoreState {
  changeLabelTo: undefined | string;
  currentTab: 'add' | 'shift';
  isRegexSearch: boolean;
  notes: string;
  replaceTerm: string;
  searchTerm: string;
  spanEndIndex: number;
  spanStartIndex: number;
  value: string;

  actions: {
    clearState: () => void;
    setChangeLabelTo: (str: string) => void;
    setCurrentTab: (tab: 'add' | 'shift') => void;
    setIsRegexSearch: (bool?: boolean) => void;
    setNotes: (str: string) => void;
    setReplaceTerm: (value: string) => void;
    setSearchTerm: (value: string) => void;
    setSpanEndIndex: (num: number) => void;
    setSpanStartIndex: (num: number) => void;
    setValue: (value: string) => void;
  };
}
