import { Box, Center, Text, ThemeIcon } from '@mantine/core';
import { IconFileSearch } from '@tabler/icons-react';

/**
 * NoDataGeneric
 *
 *
 *
 * @returns {React.Component} NoDataGeneric
 */

interface NoDataGenericProps {
  message?: string;
}

const NoDataGeneric = ({
  message = 'No data to display'
}: NoDataGenericProps) => {
  return (
    <Box w='100%'>
      <Center mt='10vh'>
        <ThemeIcon color='gray' size={55} variant='light'>
          <IconFileSearch data-testid='no-data-icon' size={55} />
        </ThemeIcon>
      </Center>
      <Center data-testid='no-data' style={{ width: '100%' }}>
        <Text c='dimmed' className='italic' mt='lg' size='lg' ta='center'>
          {message}
        </Text>
      </Center>
    </Box>
  );
};

export default NoDataGeneric;
