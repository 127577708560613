import {
  Box,
  Card,
  Flex,
  Grid,
  ScrollArea,
  useMantineTheme
} from '@mantine/core';
import _ from 'lodash';

import { OdBoundingBox } from '@/fine-tune/types/query.types';
import { EditSample, EditWithContent } from '@/fine-tune/types/response.types';

import OdLabelBadge from '../../label-badge/od-label-badge/od-label-badge';
import OdImage from '../../od-image/od-image';

export interface OdEditsCardProps {
  sample: EditSample;
  detail?: EditWithContent;
}

/**
 * OdEditsCard
 *
 *
 *
 * @returns {React.Component} OdCard
 */
const OdEditsCard = ({ sample, detail }: OdEditsCardProps) => {
  const { edit_action, new_label } = detail || {};

  const { colors } = useMantineTheme();

  let boxes = sample?.boxes?.filter((box) => box.is_active);

  if (!detail) {
    boxes = boxes?.filter((box) => Boolean(box.is_gold)); // Never show pred boxes on original sample
  }

  const modelSample = () => {
    const newSample = { ...sample };

    if (detail) {
      newSample.boxes = (boxes || [])?.map((box) => {
        let newBox = { ...box };

        if (edit_action === 'delete') {
          if (box?.is_gold) {
            newBox.gold = null;
          } else {
            newBox.pred = null;
          }
        }

        if (edit_action === 'relabel' || edit_action === 'create_new_label') {
          if (box?.is_gold) {
            newBox.gold = new_label!;
          } else {
            newBox.pred = new_label!;
          }
        }

        if (edit_action === 'relabel_as_pred') {
          const tideMatchBox =
            boxes?.find((box) => box.tide_match_id === newBox.id) || newBox;

          // @ts-expect-error - FIXME
          newBox = {
            ...tideMatchBox,
            gold: tideMatchBox?.pred!,
            is_pred: null,
            is_gold: true,
            is_active: true
          } as OdBoundingBox;
        }

        return newBox;
      });
    } else {
      newSample.boxes = boxes;
    }

    return newSample;
  };

  const modeledSample = modelSample();

  const getLabels = () => {
    if (edit_action === 'delete') {
      return [];
    }
    if (edit_action === 'relabel') {
      return [new_label];
    }

    const newLabels: string[] = [];

    modeledSample.boxes?.forEach((box) => {
      if (!box.is_active) return;

      if (box?.is_gold) {
        newLabels.push(box?.gold!);
      } else {
        newLabels.push(box?.pred!);
      }
    });

    return _.uniq(newLabels);
  };

  if (!sample) {
    return null;
  }

  return (
    <Card
      withBorder
      data-testid='od-edits-card'
      pb={0}
      pt={0}
      radius='md'
      w={300}
    >
      <Card.Section>
        <Box
          bg={colors.gray[1]}
          data-testid='cv-card-header'
          h='200px'
          pos='relative'
          tabIndex={0}
          w='100%'
        >
          <OdImage
            height='200px'
            hiddenFeatures={[]}
            // @ts-expect-error
            sample={modeledSample}
            showLabel={false}
          />
        </Box>
      </Card.Section>
      <Card.Section>
        <Grid>
          <Grid.Col pb={0} span={12}>
            <ScrollArea h={60}>
              <Flex align='flex-start' gap={5} wrap='wrap'>
                {getLabels().map((label) => {
                  if (label) {
                    return <OdLabelBadge key={label} label={label} />;
                  }
                }) || []}
              </Flex>
            </ScrollArea>
          </Grid.Col>
        </Grid>
      </Card.Section>
    </Card>
  );
};

export default OdEditsCard;
