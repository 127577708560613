import { components } from '@/core/types/api';
import {
  useComputedParameters,
  useParametersStore
} from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { FilterParams } from '@/fine-tune/types/query.types';

import { useSlices } from '../query-hooks/use-slices/use-slices';
import { useFilterParams } from '../use-filters-params/use-filter-params';

type Slices = components['schemas']['SliceDB'][];

export const useExportFilterParams = (rowId?: number) => {
  const slice = useParametersStore((s) => s.slice);
  const isNer = useComputedParameters('isNer');

  // Fetchers
  const slices = useSlices();
  const filterParams = useFilterParams();

  // Fetcher parsers
  const sliceLogic = (slices?.data as Slices)?.find(
    ({ id }: { id: string }) => id === slice
  )?.logic;

  // Global Store
  const selectedRowsIds = useStore((state) => state.selectedRows);
  const allRowsSelected = useStore((state) => state.allRowsSelected);
  let params: Partial<FilterParams> = {};

  if (rowId) {
    if (isNer) {
      params.span_sample_ids = [rowId];
    } else {
      params.ids = [rowId];
    }

    return params;
  }

  // Local download filter params
  if (!allRowsSelected && selectedRowsIds?.length) {
    if (isNer) {
      params.span_sample_ids = selectedRowsIds;
    } else {
      params.ids = selectedRowsIds;
    }
  } else {
    params = sliceLogic || filterParams;

    if (selectedRowsIds?.length) {
      params.exclude_ids = selectedRowsIds;
    }
  }

  return params;
};
