import { ASC, DESC, Tab } from '@/core/constants/query-params.constants';
import {
  ALERTS_COLUMN_KEY,
  Metric,
  ParametersKeys,
  ParametersValues,
  Splits
} from '@/fine-tune/stores/parameters-store/parameters.store.types';

import { SpanHighlightData } from './span-highlight.types';
import { components } from '../../core/types/api';

export type FilterParams = components['schemas']['FilterParams'];

export type MetaFilter = CategoricalMetaFilter | ContinuousMetaFilter;

export interface CategoricalMetaFilter {
  name: string;
  isin: (string | number | boolean | null)[];
  greater_than?: undefined;
  less_than?: undefined;
  is_equal?: undefined;
}

export interface ContinuousMetaFilter {
  name: string;
  greater_than?: number;
  less_than?: number;
  isin?: undefined;
  is_equal?: number;
}

export interface QueryParams {
  projectId?: string;
  runId?: string;
  taskType?: string;
  split?: Splits;
  tab?: number;
  depHigh?: string;
  depLow?: string;
  searchTerm?: string;
  goldFilter?: string;
  predFilter?: string;
  classFilter?: string;
  slice?: string;
  misclassifiedOnly?: 'true' | '';
  likelyMislabelled?: 'true' | '';
  task?: string;
  sortBy?: string;
  sortDirection?: typeof ASC | typeof DESC | null;
  spanSearch?: string;
  groupedBy?: string;
  metric?: Metric;
  fromSplit?: Splits | null;
  comparedTo?: Splits | null;
  isOoc?: boolean | null;

  // Overrides/ Parsed Values
  isRegex?: 'true' | '';
  isSpanRegex?: 'true' | '';
  dataframeColumns?: string;
  inferenceName?: string;
}

export type FilterName = keyof QueryParams;

export type InsightsRowsResponse = components['schemas']['DataRows'];

export interface Polygon {
  id: number;
  error_type: SsError;
  is_active: boolean;
  gold: string;
  pred: string;
  contours: [[number, number][]];
  area: number;
  accuracy: number;
}

export interface SemanticRow extends InsightsRow {
  dep_mask: string;
  polygons: Polygon[];
  width: number;
  height: number;
}

export interface InsightsRow {
  background?: number | null;
  bbox?: [number, number, number, number];
  boxes?: OdBoundingBox[];
  classification: number;
  classification_and_localization: number;
  confidence: number;
  data_error_potential: number;
  drift_score?: number;
  duplicate?: number;
  edited?: boolean;
  edit_relabeled?: boolean;
  generated_output?: string;
  generated_uncertainty?: number;
  ghost_span?: number;
  gold: string;
  id: number;
  image?: string;
  is_drifted?: boolean;
  is_on_the_boundary?: boolean;
  localization: number;
  max_dep: number;
  max_dep_label: string;
  meta: Meta;
  missed: number;
  missed_label: number;
  mltc_golds?: string[];
  mltc_golds_deps?: number[];
  mltc_preds?: string[];
  mltc_preds_deps: number[];
  new_label?: string;
  pred: string;
  span_shift: number;
  spans?: SpanHighlightData[];
  text: string;
  total_errors?: number;
  wrong_tag?: number;
  x?: number;
  y?: number;
}

export interface OdBoundingBox {
  bbox: [number, number, number, number];
  confidence: number;
  data_error_potential: number;
  error_type: OdError;
  gold: string | null;
  id: number;
  is_active: boolean;
  is_gold: boolean | null;
  is_pred: boolean | null;
  pred: string | null;
  tide_match_id: number | null;
  x: number;
  y: number;
}

export type OdError =
  | 'classification'
  | 'localization'
  | 'duplicate'
  | 'background'
  | 'classification_and_localization'
  | 'missed'
  | 'None';

export type SsError = 'classification' | 'background' | 'missed' | 'None';

export enum Label {
  Abbr = 'ABBR',
  Desc = 'DESC',
  Enty = 'ENTY',
  Hum = 'HUM',
  LOC = 'LOC',
  Num = 'NUM'
}

export interface Meta {
  galileo_text_length: number;
  galileo_language_id: GalileoLanguageID;
  galileo_pii: GalileoPii;
}

export enum GalileoLanguageID {
  En = 'en'
}

export enum GalileoPii {
  None = 'None'
}

// INSIGHTS
export const DEFAULT_INSIGHTS_PARAMS: Partial<ParametersValues> = {
  buildGrayPoints: false,
  classFilter: [],
  clusterIds: [],
  comparedTo: null,
  correctlyClassified: false,
  dataframeColumns: [],
  depHigh: 1,
  depLow: 0,
  driftScoreThreshold: 0.95,
  email: '',
  fromSplit: '',
  goldFilter: [],
  groupedBy: 'gold',
  inferenceName: '',
  isDataView: false,
  isDrifted: false,
  isMacro: false,
  isOnTheBoundary: false,
  isRegex: false,
  isSpanRegex: false,
  likelyMislabelled: false,
  lmPercentile: 0,
  mapThreshold: 0.5,
  metaFilter: [],
  metric: 'f1',
  misclassifiedOnly: false,
  predFilter: [],
  searchTerm: '',
  similarTo: [],
  slice: '',
  sortBy: 'data_error_potential',
  sortDirection: DESC,
  spanSearch: '',
  split: 'training',
  tab: Tab.DATAFRAME,
  insightsTab: ALERTS_COLUMN_KEY,
  task: '',
  taskType: undefined
};

export const INSIGHTS_NON_CLEARABLE_PARAMETERS: ParametersKeys[] = [
  'dataframeColumns',
  'mapThreshold',
  'taskType',
  'tab',
  'insightsTab'
];

// Query params that should not be rendered as filters in the UI
export const INSIGHTS_HIDDEN_FILTERS = [
  'comparedTo',
  'dataframeColumns',
  'driftScoreThreshold',
  'fromSplit',
  'groupedBy',
  'inferenceName',
  'insightsTab',
  'isMacro',
  'isRegex',
  'isSpanRegex',
  'likelyMislabelled',
  'mapThreshold',
  'metaFilter',
  'metric',
  'projectId',
  'runId',
  'slice',
  'sortBy',
  'sortDirection',
  'split',
  'tab',
  'task',
  'taskType',
  'promptMonitorFilters'
];
