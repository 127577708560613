import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Switch,
  Text,
  Textarea,
  Tooltip
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconArrowLeft,
  IconArrowRight,
  IconInfoCircle,
  IconPennant
} from '@tabler/icons-react';
import check from 'check-types';
import { useEffect, useState } from 'react';

import TokenLevelScore from '@/core/components/molecules/token-level-score/token-level-score';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { useThresholds } from '@/fine-tune/hooks/query-hooks/use-thresholds/use-thresholds';
import { useActionButtons } from '@/fine-tune/hooks/use-action-buttons/use-action-buttons';
import {
  formatBleu,
  formatRouge,
  getUncertaintyColor,
  useColors
} from '@/fine-tune/hooks/use-colors/use-colors';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import { useLLMStore } from '@/fine-tune/stores/llm-store/llm.store';
import { S2SRow } from '@/fine-tune/types/s2s.types';

const inputCutoffText =
  "Your input text exceeded the tokenizer's max length. The text that follows this was ignored by the model during training.";

const targetCutoffText =
  "Your target text exceeded the tokenizer's max length. The text that follows this was ignored by the model.";
const S2SRowDetailModal = () => {
  const { showHighDEPWords, showPriorityHeatmap } = useLLMStore();

  // Default to store values
  const [showTokenLevelDEP, setShowTokenLevelDEP] = useState(showHighDEPWords);
  const [showWordLevelProbs, setShowWordLevelProbs] =
    useState(showPriorityHeatmap);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('');
  const [systemPromptIndex, setSystemPromptIndex] = useState(0);
  const [isPromptOpen, promptHandlers] = useDisclosure(true);
  const [isInputOpen, inputHandlers] = useDisclosure(true);

  const { closeAll } = useModals();
  const modalIndex = useComputerVisionStore((state) => state.modalIndex);

  const { getDepColor } = useColors();

  const { data: insightsRowsData } = useInsightsRows();
  const { createDataEditMutation } = useEditCreate();
  const { updateSimilarToParam } = useActionButtons();
  const { data: thresholdData } = useThresholds();
  const thresh = thresholdData?.easy_samples_threshold;

  // Computed
  const samples =
    insightsRowsData?.pages?.flatMap((page) => page?.data_rows) || [];

  const sample = (samples[modalIndex] as S2SRow) || {};

  useEffect(() => {
    if (sample?.target?.length) {
      setValue(sample?.target);
    }
  }, [sample?.target?.length]);

  const {
    data_error_potential: sampleDEP,
    edited,
    bleu,
    rouge,
    generated_uncertainty: uncertainty,
    input_cutoff,
    target,
    target_cutoff,
    input,
    system_prompts
  } = sample;

  const handleCreateEdit = () => {
    createDataEditMutation.mutate({
      editAction: 'update_text',
      sampleIds: [sample.id],
      newText: value
    });
    setIsEditing(false);
  };

  const handleSimilarClick = () => {
    let similarToId: number | null = sample.id;

    if (similarToId) {
      updateSimilarToParam(similarToId);
    }
  };

  const hasGeneratedOutput = !!sample?.generated_output;

  const _input = input_cutoff
    ? [input.slice(0, input_cutoff), input.slice(input_cutoff)]
    : [input];

  const _target = target_cutoff
    ? [target.slice(0, target_cutoff), target.slice(target_cutoff)]
    : [target];

  const systemPrompt = Object.entries(system_prompts || {})[systemPromptIndex];

  const systemPromptsAmount = Object.keys(system_prompts || {}).length;

  return (
    <Box data-testid='s2s-row-detail-modal' mt={12} pos='relative'>
      <Group
        gap={4}
        style={{
          position: 'absolute',
          top: -40,
          left: 0,
          zIndex: 9999
        }}
      >
        {check.number(sampleDEP) && (
          <>
            <Text c='gray.6' fw={500} size='sm'>
              DEP
            </Text>
            <Text c={getDepColor(sampleDEP)} fw={600} size='sm'>
              {sampleDEP?.toFixed(3)}
            </Text>
          </>
        )}
        {check.number(uncertainty) && (
          <>
            <Text c='gray.6' fw={500} ml={8} size='sm'>
              Uncertainty
            </Text>
            <Text c={getUncertaintyColor(uncertainty)} fw={600} size='sm'>
              {uncertainty?.toFixed(3)}
            </Text>
          </>
        )}
        {check.number(bleu) && (
          <>
            <Text c='gray.6' fw={500} ml={8} size='sm'>
              BLEU
            </Text>
            {formatBleu(bleu, true)}
          </>
        )}
        {check.number(rouge) && (
          <>
            <Text c='gray.6' fw={500} ml={8} size='sm'>
              ROUGE
            </Text>
            {formatRouge(rouge, true)}
          </>
        )}
      </Group>
      {Boolean(systemPromptsAmount) && (
        <>
          <Group gap={4} justify='space-between' mb={2} mt={8}>
            <Text c='gray.6' fw={600} size='sm'>
              {systemPrompt[0]}
            </Text>
            {systemPromptsAmount > 1 && (
              <Group justify='flex-end'>
                <ActionIcon
                  disabled={systemPromptIndex === 0}
                  onClick={() => setSystemPromptIndex((old) => old - 1)}
                >
                  <IconArrowLeft size={16} />
                </ActionIcon>
                <ActionIcon
                  disabled={systemPromptIndex === systemPromptsAmount - 1}
                  onClick={() => setSystemPromptIndex((old) => old + 1)}
                >
                  <IconArrowRight size={16} />
                </ActionIcon>
              </Group>
            )}
          </Group>

          <Box
            mah={200}
            mb={4}
            style={{
              backgroundColor: '#F4F4F6',
              borderRadius: 8,
              overflow: 'auto',
              cursor: 'pointer'
            }}
            onClick={promptHandlers.toggle}
          >
            <Text
              color='gray.9'
              fw={400}
              lineClamp={isPromptOpen ? undefined : 1}
              p={10}
              size='sm'
            >
              {systemPrompt[1]}
            </Text>
          </Box>
        </>
      )}
      <Text c='gray.6' fw={600} size='sm'>
        Input
      </Text>
      <Box
        mah={300}
        style={{
          backgroundColor: '#F4F4F6',
          borderRadius: 8,
          overflow: 'auto',
          cursor: 'pointer'
        }}
        onClick={inputHandlers.toggle}
      >
        <Text
          color='gray.9'
          fw={400}
          lineClamp={isInputOpen ? undefined : 1}
          p={10}
          size='sm'
        >
          {_input[0]?.split('\n').map((line, index) => (
            <Text key={index} mt={index === 0 ? 0 : 'xs'} size='sm' ta='start'>
              {line}
            </Text>
          ))}
          {_input[1] && (
            <Text c='#9B98AE' component='span' fw={400} ml={2}>
              <Tooltip
                multiline
                withArrow
                label={inputCutoffText}
                w={300}
                zIndex={10000}
              >
                <IconInfoCircle
                  size={14}
                  style={{ marginBottom: -2.5, marginRight: 2 }}
                />
              </Tooltip>
              {_input[1]}
            </Text>
          )}
        </Text>
      </Box>

      <Divider color='gray.2' my='sm' />
      <Grid gutter={10} pt={6}>
        <Grid.Col span={hasGeneratedOutput ? 6 : 12}>
          <Group gap={4} mb='xs'>
            {edited && (
              <Tooltip
                multiline
                withinPortal
                label='This sample has been edited. Go to the “Edits Cart” to see it.'
                w={210}
                zIndex={9999}
              >
                <div>
                  <IconPennant color='#9B98AE' size={16} />
                </div>
              </Tooltip>
            )}
            <Text c='gray.6' fw={600} size='sm'>
              Target Output
            </Text>
            <Switch
              checked={showTokenLevelDEP}
              label='Token level DEP'
              ml={8}
              size='sm'
              onChange={() => setShowTokenLevelDEP(!showTokenLevelDEP)}
            />
          </Group>
          {isEditing ? (
            <Box>
              <Textarea
                mah={300}
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
              />
              <Group mt={8}>
                <Button
                  color='brand'
                  size='compact-md'
                  variant='outline'
                  onClick={handleCreateEdit}
                >
                  Save
                </Button>
                <Button
                  color='gray'
                  size='compact-md'
                  variant='outline'
                  onClick={() => {
                    setValue(sample.target);
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
              </Group>
            </Box>
          ) : (
            <TokenLevelScore
              withTooltip
              extraText={_target[1]}
              extraTextTooltip={targetCutoffText}
              isTruncated={false}
              response={_target[0] || ''}
              segments={sample?.target_segments || []}
              showTokens={showTokenLevelDEP}
              threshold={thresh}
            />
          )}
        </Grid.Col>
        {hasGeneratedOutput && (
          <Grid.Col span={6}>
            <Group mb='xs'>
              <Text c='gray.6' fw={600} size='sm'>
                Generated Output
              </Text>
              <Switch
                checked={showWordLevelProbs}
                label='Token uncertainty'
                size='sm'
                onChange={() => setShowWordLevelProbs(!showWordLevelProbs)}
              />
            </Group>
            <TokenLevelScore
              withTooltip
              isTruncated={false}
              response={sample?.generated_output || ''}
              segments={sample?.generated_segments || []}
              showTokens={showWordLevelProbs}
            />
          </Grid.Col>
        )}
      </Grid>

      <Divider color='gray.2' my='xs' />
      <Group justify='flex-end'>
        <Button
          color='gray'
          disabled={isEditing}
          size='compact-md'
          variant='outline'
          onClick={() => setIsEditing(true)}
        >
          Edit Target
        </Button>

        <Button
          color='gray'
          disabled={isEditing}
          size='compact-md'
          variant='outline'
          onClick={handleSimilarClick}
        >
          Show similar
        </Button>
        <Button
          color='gray'
          disabled={isEditing}
          size='compact-md'
          variant='outline'
          onClick={() => closeAll()}
        >
          Close
        </Button>
      </Group>
    </Box>
  );
};

export default S2SRowDetailModal;
