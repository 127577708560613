import {
  Card,
  Checkbox,
  Collapse,
  Group,
  Text,
  UnstyledButton
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import FilterChip from '@/core/components/atoms/filter-chip/filter-chip';
import { useParsedFilters } from '@/fine-tune/hooks/use-parsed-filters/use-parsed-filters';

interface FiltersCheckboxProps {
  checked: boolean;
  onChange: () => void;
}
/**
 * FiltersCheckbox
 *
 *
 *
 * @returns {React.Component} FiltersCheckbox
 */
const FiltersCheckbox = ({ checked, onChange }: FiltersCheckboxProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const filters = useParsedFilters();

  return (
    <Card
      withBorder
      p={8}
      radius='md'
      style={{
        borderColor: '#E9E8ED'
      }}
    >
      <Group justify='space-between'>
        <Checkbox checked={checked} label='Apply filters' onChange={onChange} />
        <UnstyledButton onClick={toggle}>
          <Text c='brand.6' fw={600} size='xs'>
            {opened ? 'Collapse' : 'Expand'}
          </Text>
        </UnstyledButton>
      </Group>

      <Collapse in={opened}>
        <Group gap={4} mt={8}>
          {filters.map(({ parsedLabel, parsedValue }, idx) => (
            <FilterChip key={idx} label={parsedLabel} value={parsedValue} />
          ))}
        </Group>
      </Collapse>
    </Card>
  );
};

export default FiltersCheckbox;
