import {
  BackgroundErrorIcon,
  ClassificationErrorIcon,
  DuplicatesErrorIcon,
  LocalizationClassificationErrorIcon,
  LocalizationErrorIcon,
  MissedErrorIcon
} from '../../core/constants/icons.constants';

export const OD_ERROR_MAP = {
  duplicate: {
    label: 'Duplicate',
    icon: <DuplicatesErrorIcon />,
    tooltip:
      'Duplicated detection error: The model detected the same object multiple times.'
  },
  classification: {
    label: 'Classification Error',
    icon: <ClassificationErrorIcon />,
    tooltip:
      'Classification error: The label predicted for an object doesn’t match that of its corresponding Ground Truth object.'
  },
  localization: {
    label: 'Localization Error',
    icon: <LocalizationErrorIcon />,
    tooltip:
      'Localization error: The detected object overlaps poorly with its corresponding Ground Truth object.'
  },
  classification_and_localization: {
    label: 'Classification and Localization Error ',
    icon: <LocalizationClassificationErrorIcon />,
    tooltip:
      'Classification + Localization error: The label predicted and location for an object doesn’t match that of its corresponding Ground Truth object.'
  },

  background: {
    label: 'Background Error',
    icon: <BackgroundErrorIcon />,
    tooltip:
      'Background Confusion: The model predicted an object for which there’s no corresponding object in the Ground Truth.'
  },
  missed: {
    label: 'Missed',
    icon: <MissedErrorIcon />,
    tooltip:
      'Missed Prediction: The model failed to predict an object that was annotated in the Ground Truth.'
  }
};
