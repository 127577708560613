import { Box, Button, Center, Text, ThemeIcon, Title } from '@mantine/core';
import { IconShoppingCartX, IconTableShortcut } from '@tabler/icons-react';

interface EditsCartEmptyStateProps {
  onModalClose?: () => void;
}

const EditsCartEmptyState = ({ onModalClose }: EditsCartEmptyStateProps) => (
  <Center style={{ width: '100%', height: 400 }}>
    <Box className='text-center items-center'>
      <Center style={{ width: '100%' }}>
        <div className='flag-container'>
          <ThemeIcon color='gray' size={50} variant='light'>
            <IconShoppingCartX height={50} width={50} />
          </ThemeIcon>
        </div>
      </Center>
      <Title my={15} order={2}>
        Edits Cart is Empty
      </Title>
      <Text fw={500} mb={15} size='sm'>
        Looks like you haven&apos;t added anything to Edits Cart
      </Text>
      <Button
        color='brand'
        leftSection={<IconTableShortcut size={16} />}
        radius='md'
        onClick={onModalClose}
      >
        Back to Console
      </Button>
    </Box>
  </Center>
);

export default EditsCartEmptyState;
