import { Modals } from '@/core/hooks/use-modals/use-modals';
import KeyboardShortcutsModal from '@/core/modals/keyboard-shortcuts-modal/keyboard-shortcuts-modal';
import ResetPasswordModal from '@/core/modals/reset-password-modal/reset-password-modal';

import AWSIntegrationsModal from './aws-integrations-modal/aws-integrations-modal';
import CreateAPIKeyModal from './create-api-key-modal/create-api-key-modal';
import IntegrationsModal from './integrations-modal/integrations-modal';

/**
 * List definition for modal id & modal components to be passed to ModalProvider
 * Must live outside useModals - will cause circular dep issue in tests if in useModals 😭
 */
export const modals = {
  [Modals.AWS_INTEGRATIONS]: AWSIntegrationsModal,
  [Modals.INTEGRATIONS]: IntegrationsModal,
  [Modals.KEYBOARD_SHORTCUTS]: KeyboardShortcutsModal,
  [Modals.CREATE_API_KEY]: CreateAPIKeyModal,
  [Modals.RESET_PASSWORD]: ResetPasswordModal
};
