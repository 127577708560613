import check from 'check-types';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import Router from 'next/router';

import { ASC } from '@/core/constants/query-params.constants';
import { Paths } from '@/core/constants/routes.constants';
import { TaskType } from '@/core/constants/tasks-and-frameworks.constants';
import {
  CONTINUOUS_DELINEATOR,
  EQUAL_DELINEATOR,
  splitFilters,
  splitFiltersAsNumbers,
  splitMetaFilters
} from '@/core/utils/parse-query-param-arrays/parse-query-param-arrays';
import {
  ParametersKeys,
  PartialParameters,
  Splits
} from '@/fine-tune/stores/parameters-store/parameters.store.types';
import { MetaFilter } from '@/fine-tune/types/query.types';

import { getDefaultColumns } from '../use-dataframe-column/use-dataframe-columns';

export const parseRouterParams = (defaultValues: PartialParameters) => {
  const query: NextParsedUrlQuery = Router.query || {};

  let parsedParams = {};
  let parsedMetaParams: MetaFilter[] = [];

  Object.entries(query).forEach(([key, value]) => {
    if (Router.pathname === Paths.COMPARE_RUNS) {
      parsedParams = { ...parsedParams, [key]: value };
    } else {
      // Meta filters have a complex structure, so we need to parse them separately and add them to the store
      if (key === 'metaFilter') {
        const parsedMetaFilters = parseMetaValues(value as string);
        parsedMetaParams = parsedMetaFilters;
        return;
      }

      const parsedValue = parseQueryValue(
        defaultValues,
        key as ParametersKeys,
        value as string
      );

      parsedParams = { ...parsedParams, [key]: parsedValue };
    }
  });

  if (
    (query.taskType || query?.split === 'inference') &&
    !query.dataframeColumns?.length
  ) {
    parsedParams = {
      ...parsedParams,
      dataframeColumns: getDefaultColumns(
        query.taskType as TaskType,
        query?.split as Splits
      )
    };

    if (query?.split === 'inference') {
      parsedParams = {
        ...parsedParams,
        groupedBy: 'pred',
        metric: 'confidence',
        sortBy: 'confidence',
        sortDirection: ASC
      };
    }
  }

  return {
    parsedParams,
    parsedMetaParams
  } as {
    parsedParams: PartialParameters;
    parsedMetaParams: MetaFilter[];
  };
};

export const parseQueryValue = (
  defaultValues: PartialParameters,
  key: ParametersKeys,
  value?: string
) => {
  const defaultValue = defaultValues[key];
  const numArrayFilters: ParametersKeys[] = ['clusterIds'];

  if (check.boolean(defaultValue)) {
    return value === 'true';
  }

  if (check.number(defaultValue)) {
    return Number(value);
  }

  if (check.array(defaultValue)) {
    if (numArrayFilters.includes(key)) {
      return splitFiltersAsNumbers(value || '');
    }
    return splitFilters(value || '');
  }

  return value;
};

export const parseMetaValues = (value: string): MetaFilter[] => {
  // Here we need to do the opposite of what we do in persistInUrlMiddleware
  const stringifiedMetaFilters = splitMetaFilters(value);
  return stringifiedMetaFilters.map((meta) => {
    const [name, value] = meta.split(':');

    const splittedCategorical = splitFilters(value);
    const splittedContinuous = value?.split(CONTINUOUS_DELINEATOR) || [];
    const equalContinuous = value?.split(EQUAL_DELINEATOR) || [];

    const isEqual = !isNaN(Number(equalContinuous[0]));
    const isContinuous =
      splittedCategorical.length === 1 &&
      splittedContinuous.length === 2 &&
      splittedContinuous.some((v) => !isNaN(Number(v)));

    const metaFilterData = isEqual
      ? { is_equal: Number(equalContinuous[0]) }
      : isContinuous
        ? {
            greater_than: Number(splittedContinuous[0]),
            less_than: Number(splittedContinuous[1])
          }
        : { isin: splittedCategorical };

    return {
      name,
      ...metaFilterData
    };
  });
};
