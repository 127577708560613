import { Badge, Switch, Text } from '@mantine/core';

import TextSection from '@/core/components/atoms/text-section/text-section';
import TokenLevelScore from '@/core/components/molecules/token-level-score/token-level-score';
import ErrorTypesCell from '@/fine-tune/components/error-types-cell/error-types-cell';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import LabelBadgeWithPopover from '@/fine-tune/components/label-badge/label-badge-with-popover/label-badge-with-popover';
import SpanHighlight from '@/fine-tune/components/span-highlight/span-highlight';
import {
  CONFIDENCE_ACCESSOR,
  DEP_ACCESSOR,
  GOLD_ACCESSOR,
  MAX_DEP_ACCESSOR,
  MAX_DEP_LABEL_ACCESSOR,
  NOISE_TYPE_ACCESSOR,
  PRED_ACCESSOR,
  TEXT_ACCESSOR
} from '@/fine-tune/constants/dataframe-accessors.constants';
import { NER_COL_WIDTH } from '@/fine-tune/constants/table.constants';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import { InsightsRow } from '@/fine-tune/types/query.types';
import {
  CellArgs,
  DepOrConfidenceColors,
  S2SCell
} from '@/fine-tune/types/table.types';

export const BASE_TEXT_COLUMN = {
  align: 'left',
  label: 'Text',
  accessor: TEXT_ACCESSOR,
  width: 650,
  maxWidth: 650,
  cell: ({ value, isTruncated }: { value: string; isTruncated: boolean }) => {
    return (
      <TextSection splitLineBreaks isTruncated={isTruncated} value={value} />
    );
  },
  isSticky: true,
  isAlwaysVisible: true
};

export const IMAGE_COLUMN = {
  label: 'Image',
  accessor: 'image',
  width: 0,
  maxWidth: 0
};

export const BASE_GOLD_COLUMN = {
  label: 'Ground Truth',
  accessor: GOLD_ACCESSOR,
  isFilterable: true,
  width: 200,
  cell: ({ value, data }: { value: string; data: InsightsRow }) => {
    const paramSetter = useParametersStore.getState().actions.setParameters;
    return (
      <LabelBadge
        maxWidth={175}
        size='lg'
        value={value}
        wasEdited={data?.edited && data?.edit_relabeled}
        onClick={() => paramSetter({ goldFilter: [value] })}
      />
    );
  }
};

export const BASE_PRED_COLUMN = {
  label: 'Prediction',
  accessor: PRED_ACCESSOR,
  isFilterable: true,
  width: 200,
  cell: ({ value }: { value: string }) => {
    const paramSetter = useParametersStore.getState().actions.setParameters;
    return (
      <LabelBadge
        maxWidth={175}
        size='lg'
        value={value}
        onClick={() => paramSetter({ predFilter: [value] })}
      />
    );
  }
};

export const DEP_COLUMN = {
  label: 'DEP',
  accessor: DEP_ACCESSOR,
  isSortable: true,
  width: 120,
  cell: ({ value, color }: { value: number; color: DepOrConfidenceColors }) => {
    return (
      <Text c={color} size='sm'>
        {value?.toFixed(3)}
      </Text>
    );
  }
};

export const CONFIDENCE_COLUMN = {
  label: 'Confidence',
  accessor: CONFIDENCE_ACCESSOR,
  isSortable: true,
  cell: ({ value }: { value: number }) => {
    return <Text size='sm'>{value?.toFixed(3)}</Text>;
  }
};

export const ACCURACY_COLUMN = {
  label: 'Accuracy',
  accessor: 'accuracy',
  isSortable: true,
  cell: ({ value }: { value: number }) => {
    return <Text size='sm'>{value?.toFixed(3)}</Text>;
  }
};

export const MAX_DEP_COLUMN = {
  label: 'Max DEP',
  accessor: MAX_DEP_ACCESSOR,
  isSortable: true,
  cell: ({ value, color }: { value: number; color: DepOrConfidenceColors }) => {
    return (
      <Text c={color} size='sm'>
        {value?.toFixed(3)}
      </Text>
    );
  }
};

export const MAX_DEP_LABEL_COLUMN = {
  label: 'Max Dep Label',
  accessor: MAX_DEP_LABEL_ACCESSOR,
  width: 200,
  cell: ({ data, value }: { data: InsightsRow; value: string }) => (
    <LabelBadgeWithPopover maxWidth={175} sample={data} value={value} />
  )
};

export const NER_GOLD_COLUMN = {
  label: 'Ground Truth',
  accessor: GOLD_ACCESSOR,
  isFilterable: true,
  isSticky: true,
  width: NER_COL_WIDTH,
  isAlwaysVisible: true,
  cell: ({ data, column, isTruncated }: CellArgs) => {
    return (
      <SpanHighlight
        columnId={column.accessor as 'gold'}
        rowId={data?.id}
        spans={data?.spans}
        text={data?.text}
        truncated={isTruncated}
      />
    );
  }
};

export const NER_PRED_COLUMN = {
  label: 'Predicted',
  accessor: PRED_ACCESSOR,
  isFilterable: true,
  isSticky: true,
  width: NER_COL_WIDTH,
  isAlwaysVisible: true,
  cell: ({ data, column, isTruncated }: CellArgs) => {
    return (
      <SpanHighlight
        columnId={column.accessor as 'pred'}
        rowId={data?.id}
        spans={data?.spans}
        text={data?.text}
        truncated={isTruncated}
      />
    );
  }
};

export const ERROR_TYPES_COLUMN = {
  label: 'Span & Tag Errors',
  accessor: 'error_types',
  width: 270,
  cell: ({ data }: CellArgs) => <ErrorTypesCell rowData={data} />
};

export const NOISE_TYPE_COLUMN = {
  label: 'Noise Type',
  accessor: NOISE_TYPE_ACCESSOR,
  isFilterable: true,
  uniqueValuesFull: ['Label Noise', 'Feature Noise', 'None'],
  width: 150,
  cell: ({ value }: { value: string }) => {
    const isLabelNoise = value === 'Label Noise';
    const isNone = value === 'None';
    return (
      <Badge
        color={isLabelNoise ? 'red' : isNone ? 'orange' : 'grape'}
        size='sm'
      >
        {value}
      </Badge>
    );
  }
};

// Seq 2 Seq columns
export const INPUT_COLUMN = {
  label: 'Input',
  accessor: 'input',
  width: 300,
  maxWidth: 300,
  isSticky: true,
  isAlwaysVisible: true,
  cell: ({ value, isTruncated }: S2SCell) => {
    return <TextSection isTruncated={!!isTruncated} value={value as string} />;
  }
};

export const GT_OUTPUT_COLUMN = (
  value: boolean,
  thresh: number,
  handler: () => void
) => ({
  isAlwaysVisible: true,
  label: 'Target',
  accessor: 'target',
  width: 350,
  maxWidth: 350,
  order: 1,
  extraHeaderContent: () => (
    <Switch
      checked={value}
      label='Show DEP'
      labelPosition='left'
      ml={12}
      size='xs'
      styles={{
        label: {
          fontWeight: 500,
          color: '#545167'
        }
      }}
      onChange={handler}
    />
  ),
  cell: (row: S2SCell) => {
    const isTruncated = Boolean(row?.isTruncated);
    if (row.data.target) {
      return (
        <TokenLevelScore
          coloringThreshold={thresh}
          isTruncated={isTruncated}
          response={row.data.target}
          segments={row.data.target_segments}
          showTokens={value}
          withTooltip={true}
        />
      );
    }
  }
});

export const GENERATED_OUTPUT_COLUMN = (
  value: boolean,
  handler: () => void
) => ({
  label: 'Generated Output',
  accessor: 'generated_output',
  width: 350,
  maxWidth: 350,
  extraHeaderContent: () => (
    <Switch
      checked={value}
      label='Show uncertainty'
      labelPosition='left'
      ml={12}
      size='xs'
      styles={{
        label: {
          fontWeight: 500,
          color: '#545167'
        }
      }}
      onChange={handler}
    />
  ),
  cell: (row: S2SCell) => {
    const isTruncated = Boolean(row?.isTruncated);
    if (row.data.generated_output) {
      return (
        <TokenLevelScore
          isTruncated={isTruncated}
          response={row.data.generated_output}
          segments={row.data.generated_segments}
          showTokens={value}
          withTooltip={true}
        />
      );
    }
  }
});
