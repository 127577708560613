import { STATUS } from '@/core/constants/job-status.constants';
import { JobStatus } from '@/core/hooks/query-hooks/use-latest-job/use-latest-job';

export function getJobStatusLabel(status: JobStatus) {
  if (status == null) {
    return undefined;
  }

  switch (status) {
    case STATUS.SUCCESS:
    case STATUS.COMPLETED:
      return 'Completed';
    case STATUS.ERROR:
      return 'Error';
    case STATUS.FAILED:
      return 'Failed';
    case STATUS.COMPUTING:
    case STATUS.IN_PROGRESS:
      return 'Computing...';
    case STATUS.PENDING:
    case STATUS.UNSTARTED:
      return 'Pending...';
    case STATUS.NOT_COMPUTED:
      return 'Not Computed';
    case STATUS.NOT_APPLICABLE:
      return 'Not Applicable';
    default:
      return status;
  }
}
