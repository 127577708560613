import { Center, HoverCard, Image } from '@mantine/core';
import { useWindowEvent } from '@mantine/hooks';
import chroma from 'chroma-js';
import { useEffect, useRef, useState } from 'react';

import { ImageType } from '@/fine-tune/stores/computer-vision-store/computer-vision.store.types';
import useStore from '@/fine-tune/stores/store';
import { SemanticRow } from '@/fine-tune/types/query.types';

import SsPopover from '../ss-popover/ss-popover';

export interface SsImageProps {
  sample: SemanticRow;
  type: ImageType;
  isTransparent?: boolean;
  isHoverable?: boolean;
  height: number;
  width: number;
  hiddenLabels: string[];
  hiddenErrors: string[];
  hideFilteredPolygons?: boolean;
}

/**
 * SsImage
 *
 *
 *
 * @returns {React.Component} SsImage
 */
const SsImage = ({
  sample,
  type,
  hiddenLabels,
  hiddenErrors,
  isTransparent,
  isHoverable = false,
  hideFilteredPolygons = true,
  height,
  width
}: SsImageProps) => {
  // Global Store
  const colorMap = useStore((state) => state.colorMap);

  // State
  const [renderedSVG, setRenderedSVG] = useState<JSX.Element>();

  // Ref
  const imgRef = useRef<HTMLImageElement>(null);

  // Computed
  const imageSrc = type === ImageType.DEP ? sample.dep_mask : sample.image;

  useEffect(() => {
    renderSVG();
  }, [
    sample,
    height,
    width,
    type,
    hiddenLabels,
    hiddenErrors,
    isTransparent,
    hideFilteredPolygons
  ]);

  const getLabelColor = (maskLabel: string) => {
    const labelName = maskLabel || '';
    return colorMap?.[labelName].background;
  };

  const getScale = (img: HTMLImageElement) => {
    const { width, height, naturalWidth, naturalHeight } = img;

    const { width: processedWidth, height: processedHeight } = sample;

    const imageAspectRatio = naturalWidth / naturalHeight;
    const containerAspectRatio = width / height;

    const [scaledWidth, scaledHeight] =
      imageAspectRatio >= containerAspectRatio
        ? [width, width / imageAspectRatio]
        : [height * imageAspectRatio, height];

    const widthMultiplier =
      scaledWidth / naturalWidth / (processedWidth / naturalWidth);
    const heightMultiplier =
      scaledHeight / naturalHeight / (processedHeight / naturalHeight);

    return {
      widthMultiplier,
      heightMultiplier,
      scaledHeight,
      scaledWidth
    };
  };

  const mapPolygons = (heightMultiplier: number, widthMultiplier: number) => {
    const maskAccessor = type === ImageType.GOLD ? 'gold' : 'pred';
    const polygons =
      sample.polygons?.filter((polygon) => Boolean(polygon[maskAccessor])) ||
      [];
    const contours: JSX.Element[] = [];

    if (!polygons) {
      return;
    }

    // Sort the polygons by size to ensure that the smallest polygons are drawn on the top and can be hovered
    const sortedBySize = polygons.sort((a, b) => b.area - a.area);

    // Loop through the points and draw a rectangle for each one
    sortedBySize.forEach((polygon) => {
      const { gold, pred, error_type, is_active, id } = polygon;
      const maskLabel = gold || pred;
      const maskErrorType = error_type;

      const filterByError =
        hiddenErrors?.includes(maskErrorType) ||
        (hiddenErrors?.length && maskErrorType === 'None');

      if (
        hiddenLabels?.includes(maskLabel) ||
        filterByError ||
        (hideFilteredPolygons && !is_active)
      ) {
        return;
      }

      const svgPolygons: JSX.Element[] = [];

      polygon?.contours.forEach((points, index) => {
        const flatPoints = points.flat();

        const resizedPoints = flatPoints.map(
          (point, idx) =>
            point * (idx % 2 === 0 ? widthMultiplier : heightMultiplier)
        );

        const pointsString = resizedPoints.reduce((string, point, idx) => {
          const separator = idx % 2 === 0 ? ',' : ' ';
          return string + point + separator;
        }, '');

        const isFirstElement = index === 0;

        const color = getLabelColor(maskLabel);

        if (isFirstElement) {
          // First element is the main polygon
          let alpha = isTransparent ? 0.5 : 1;
          const fillColor = chroma(color).alpha(alpha).hex();

          const mainPolygon = (
            <polygon
              data-testid='ss-main-polygon'
              fill={fillColor}
              key={id}
              // Mask needs to have a different ID for the modal and the grid
              mask={`url(#contour_${isHoverable ? 'modal' : 'grid'}_${id})`}
              points={pointsString}
              stroke={color}
              strokeWidth={2}
            />
          );

          if (isHoverable) {
            svgPolygons.push(
              <HoverCard
                withinPortal
                radius='md'
                shadow='md'
                width={250}
                zIndex={9999}
              >
                <HoverCard.Target>
                  <g className='ss-polygon'>{mainPolygon}</g>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <SsPopover polygon={polygon} />
                </HoverCard.Dropdown>
              </HoverCard>
            );
          } else {
            svgPolygons.push(mainPolygon);
          }
        } else {
          // The rest of the elements subtract content from the main polygon
          svgPolygons.push(
            <polygon
              data-testid='ss-transparent-polygon'
              key={`${id}_${index}`}
              points={pointsString}
            />
          );
        }
      });

      const [firstPoly, ...rest] = svgPolygons;

      // If there are no subtractions, just add the main polygon
      if (!rest.length) {
        contours.push(firstPoly);
      } else {
        // Otherwise, add the main polygon and the subtractions to a mask
        contours.push(
          <g height={height} key={id} width={width}>
            <defs>
              <mask id={`contour_${isHoverable ? 'modal' : 'grid'}_${id}`}>
                <rect fill='white' height={1028} width={1028} x='0' y='0' />
                {rest}
              </mask>
            </defs>
            {firstPoly}
          </g>
        );
      }
    });

    return contours;
  };

  const renderSVG = () => {
    const img = imgRef.current;

    if (!img?.complete) {
      return;
    }
    // No need to render SVG for DEP or RAW images
    if ([ImageType.DEP, ImageType.RAW].includes(type)) {
      return setRenderedSVG(undefined);
    }

    // Take the image and get the scaled values based on aspect ratios.
    const { heightMultiplier, scaledHeight, scaledWidth, widthMultiplier } =
      getScale(img);

    // Map the polygons
    const mappedPolygons = mapPolygons(heightMultiplier, widthMultiplier);

    setRenderedSVG(
      <svg
        height={scaledHeight}
        style={{ position: 'absolute' }}
        width={scaledWidth}
      >
        {mappedPolygons}
      </svg>
    );
  };

  // Redraw the SVGs when the window resizes
  useWindowEvent('resize', renderSVG);

  return (
    <Center h='100%' pos='relative'>
      <Image
        alt={`${type === ImageType.DEP ? 'DEP' : 'Original'} Image`}
        fit='contain'
        height={height}
        m='auto'
        opacity={isTransparent ? 0.5 : 1}
        ref={imgRef}
        src={imageSrc}
        width={width}
        onLoad={() => renderSVG()}
      />
      {renderedSVG}
    </Center>
  );
};

export default SsImage;
