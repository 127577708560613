import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';

export const LABELSTUDIO_PROJECTS = '/integrations/labelstudio/projects';

export const useLabelstudioProjects = () => {
  const { data } = useIntegration('labelstudio');

  const fetchProjects = async () => {
    const res = await api.GET(LABELSTUDIO_PROJECTS);

    return res.data;
  };

  return useQuery([LABELSTUDIO_PROJECTS], fetchProjects, {
    enabled: Boolean(data?.name)
  });
};
