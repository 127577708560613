import { ActionIcon, Checkbox, Group, Loader, Text } from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { useEffect } from 'react';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useInsightsRows } from '@/fine-tune/hooks/query-hooks/use-insights-rows/use-insights-rows';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import { useComputerVisionStore } from '@/fine-tune/stores/computer-vision-store/computer-vision.store';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';

const ModalControls = ({ withTitle }: { withTitle: boolean }) => {
  // Hooks
  const { data: sampleData } = usePercentageSample();
  const { data, isFetching, dataUpdatedAt } = useInsightsRows();
  const samples = data?.pages?.flatMap((page) => page?.data_rows) || [];

  // Computed params
  const { isOd, isS2S } = useComputedParameters();

  // Computer Vision State
  const modalIndex = useComputerVisionStore((state) => state.modalIndex);
  const modalTitle = useComputerVisionStore((state) => state.modalTitle);

  // Global State
  const allRowsSelected = useStore((state) => state.allRowsSelected);
  const selectedObjects = useStore((state) => state.selectedObjects);
  const selectedRows = useStore((state) => state.selectedRows);
  const { setSelectedObjects, setSelectedRows } = useStore(
    (state) => state.actions
  );

  const sample = samples?.[modalIndex];
  const nextSample = samples?.[modalIndex + 1];

  const setModalIndex = useComputerVisionStore(
    (state) => state.actions.setModalIndex
  );

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      handleIncrement();
    } else if (event.key === 'ArrowLeft') {
      handleDecrement();
    } else if (event.key === ' ' && withTitle) {
      event.preventDefault();
      handleToggleCheckbox();
    }
  };

  const isSelected = selectedRows?.includes(sample?.id as number);
  const isChecked = allRowsSelected ? !isSelected : isSelected;

  // Effects
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [modalIndex, dataUpdatedAt, isChecked]);

  // Computed
  const total = sampleData?.sample_count || 0;
  const indexString = `${modalIndex + 1} out of ${total} ${
    isS2S ? 'samples' : 'images'
  }`;

  // Event Handlers
  const handleDecrement = () => {
    if (modalIndex > 0) {
      setModalIndex(modalIndex - 1);
    }
  };

  const handleIncrement = async () => {
    if (modalIndex < total - 1) {
      if (nextSample) {
        setModalIndex(modalIndex + 1);
      }
    } else {
      setModalIndex(0);
    }
  };

  const handleToggleCheckbox = () => {
    let updatedRows: number[] = [];
    let updatedObjects: number[] = [];

    const activeBoxes = isOd
      ? sample?.boxes?.filter((box) => box.is_active)
      : [];

    if (isSelected) {
      updatedRows = selectedRows.filter((selected) => sample?.id !== selected);
      updatedObjects = selectedObjects.filter(
        (selected) => !activeBoxes?.some((box) => box.id === selected)
      );
    } else {
      updatedRows = [...selectedRows, sample?.id as number];
      updatedObjects = [
        ...selectedObjects,
        ...(activeBoxes?.map((box) => box.id) || [])
      ];
    }

    setSelectedRows(updatedRows);

    if (isOd) {
      setSelectedObjects(updatedObjects);
    }
  };

  return (
    <>
      <Group gap='xs'>
        {withTitle && (
          <>
            <Checkbox
              checked={isChecked}
              tabIndex={-1}
              onChange={handleToggleCheckbox}
            />
            <Text>{modalTitle || STRING_PLACEHOLDER}</Text>
          </>
        )}
      </Group>

      <Group data-testid='modal-controls' justify='flex-end'>
        <Text>{indexString}</Text>
        <ActionIcon
          aria-label='decrement modal'
          disabled={modalIndex === 0}
          onClick={handleDecrement}
        >
          <IconArrowLeft size={16} />
        </ActionIcon>
        <ActionIcon
          aria-label='increment modal'
          disabled={isFetching}
          onClick={handleIncrement}
        >
          {isFetching ? (
            <Loader color='gray' data-testid='loader' size={16} type='dots' />
          ) : (
            <IconArrowRight size={16} />
          )}
        </ActionIcon>
      </Group>
    </>
  );
};

export default ModalControls;
