import { Button, Center, Group, Text } from '@mantine/core';
import { IconChecks, IconInfoCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';

import { useUserById } from '@/core/hooks/query-hooks/use-user-by-id/use-user-by-id';

export interface ApprovedEditsProps {
  reviewers?: {
    created_by: string;
    created_at: string;
  }[];
  onEditApprove: () => void;
}

const ApprovedEdits = ({ reviewers, onEditApprove }: ApprovedEditsProps) => {
  const isReviewed = Boolean(reviewers?.length);
  const firstReviewer = reviewers?.[0];
  const reviewerId = firstReviewer?.created_by || '';

  const { data } = useUserById(reviewerId);
  return (
    <Group
      gap='xs'
      justify='space-between'
      mb={12}
      mt={4}
      p={isReviewed ? 10 : 6}
      style={({ colors }) => ({
        backgroundColor: isReviewed ? colors.green[1] : colors.gray[1],
        borderRadius: 8
      })}
    >
      {isReviewed ? (
        <Group>
          <IconChecks color='#16CA16' size={24} />
          <Text c='green.7' mt={2} size='md'>
            Approved by {data?.first_name || data?.email} on{' '}
            {dayjs(firstReviewer?.created_at).format('MMM DD, YYYY')}
          </Text>
        </Group>
      ) : (
        <>
          <Center ml={4}>
            <IconInfoCircle color='#9B98AE' size={24} />
            <Text c='gray.6' ml={6} mt={2} size='md'>
              Not approved yet
            </Text>
          </Center>
          <Button
            color='dark'
            radius='md'
            size='sm'
            variant='default'
            onClick={onEditApprove}
          >
            Approve
          </Button>
        </>
      )}
    </Group>
  );
};

export default ApprovedEdits;
