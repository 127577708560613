import { Box, ScrollArea, Text } from '@mantine/core';
import { MouseEvent } from 'react';

import { useComputedParameters } from '@/fine-tune/stores/parameters-store';

import HistoryLogItem from './history-log-item';
import { ICartElement } from '../cart';

interface HistoryLogProps {
  logItems: ICartElement[];
  currentElement: string;
  onLogItemClick: (id: string) => void;
  onLogItemRemove: (id: string) => void;
}

const HistoryLog = ({
  logItems,
  currentElement,
  onLogItemClick,
  onLogItemRemove
}: HistoryLogProps) => {
  const { isNer } = useComputedParameters();

  const handleItemRemove = (event: MouseEvent, itemId: string) => {
    event.stopPropagation();
    onLogItemRemove(itemId);
  };

  return (
    <Box>
      <Text c='gray.5' fw={550} mb={10} mt={15} size='xs'>
        EDITS LOG
      </Text>
      <ScrollArea.Autosize offsetScrollbars mah={isNer ? 400 : 420}>
        {logItems?.map((item) => (
          <HistoryLogItem
            isSelected={currentElement == item.id}
            item={item}
            key={item.id}
            removeItem={(event) => handleItemRemove(event, item.id)}
            selectItem={() => onLogItemClick(item.id)}
          />
        ))}
      </ScrollArea.Autosize>
    </Box>
  );
};

export default HistoryLog;
