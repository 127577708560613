import { Loader } from '@mantine/core';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { Paths } from '@/core/constants/routes.constants';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { FeatureFlagResponse } from '@/core/stores/feature-flags-store/feature-flags.store.types';
import { setPath } from '@/core/utils/handle-login-redirect/handle-login-redirect';

interface AuthProviderProps {
  children?: React.ReactNode;
  withAuth?: boolean;
  featureFlags?: FeatureFlagResponse;
}

const AuthAndFlagsProvider = ({
  children,
  withAuth,
  featureFlags
}: AuthProviderProps) => {
  const { status } = useSession();

  const router = useRouter();

  const { setFeatureFlags } = useFeatureFlagsStore((state) => state.actions);

  useEffect(() => {
    if (featureFlags) {
      setFeatureFlags(featureFlags);
    }
  }, [featureFlags]);

  useEffect(() => {
    if (withAuth && status === 'unauthenticated') {
      if (router?.pathname !== Paths.ROOT) {
        // OAuth-ing unmounts app, so post sign in path needs to be in session storage
        setPath(router);
      }

      router.push({
        pathname: Paths.SIGN_IN
      });
    }
  }, [withAuth, status]);

  // If page is public, or is private page with auth'd user, return children
  if (!withAuth || status === 'authenticated') {
    return <>{children}</>;
  }

  // NOTE mantine provider is still loading when this component mounts, so can't use mantine layouts to center
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Loader data-testid='auth-loader' />
    </div>
  );
};

export default AuthAndFlagsProvider;
