import { ActionIcon, Box, Button, Divider, Text, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react';
import { Ref } from 'react';

import SplitCard from '@/core/components/atoms/split-card/split-card';

interface FeatureInsightsCardProps {
  title: string;
  onClick: () => void;
  children: React.ReactNode;
  subheader?: React.ReactNode;
  isExpanded: boolean;
  displayShowAllButton?: boolean;
  setHeight?: boolean;
}
/**
 * FeatureInsightsCard
 *
 *
 *
 * @returns {React.Component} FeatureInsightsCard
 */
const FeatureInsightsCard = ({
  title,
  onClick,
  children,
  subheader,
  isExpanded,
  displayShowAllButton = true,
  setHeight = true
}: FeatureInsightsCardProps) => {
  const { hovered, ref } = useHover();

  const label = isExpanded ? 'Minimize' : 'Expand';
  const heightProps = {
    height: '100%',
    minHeight: isExpanded ? '80vh' : 500,
    maxHeight: isExpanded ? 1000 : 600
  };

  return (
    <SplitCard
      disableFocus
      cardPadding={0}
      header={
        <Box>
          <Text>{title}</Text>
          <Tooltip withArrow withinPortal label={label} position='left'>
            <ActionIcon
              aria-label={label}
              color='gray.5'
              size='md'
              style={{
                visibility: hovered ? 'visible' : 'hidden',
                position: 'absolute',
                right: 8,
                top: 8
              }}
              variant='outline'
              onClick={onClick}
            >
              {isExpanded ? (
                <IconArrowsMinimize size={16} />
              ) : (
                <IconArrowsMaximize size={16} />
              )}
            </ActionIcon>
          </Tooltip>
        </Box>
      }
      ref={ref as Ref<HTMLInputElement>}
      style={setHeight ? heightProps : {}}
      subheader={subheader}
      testId='feature-insights-card'
      withoutBorder={false}
    >
      <Box style={{ flexGrow: 1 }}>{children}</Box>
      {displayShowAllButton && !isExpanded && (
        <>
          <Divider style={{ borderTopColor: 'var(--mantine-color-gray-2)' }} />
          <Button fullWidth color='gray.6' variant='light' onClick={onClick}>
            Show All
          </Button>
        </>
      )}
    </SplitCard>
  );
};

export default FeatureInsightsCard;
