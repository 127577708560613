import check from 'check-types';

export const DELINEATOR = ';&';
export const CONTINUOUS_DELINEATOR = '-';
export const EQUAL_DELINEATOR = ';=';
export const META_DELINEATOR = ';&&';
export const OBSERVE_FILTER_DELIMITER = '+__+';

export const joinFilters = (
  filters: (string | number | boolean | undefined | null)[]
) => {
  if (check.not.array(filters)) {
    return '';
  }

  return (filters as string[])?.join(DELINEATOR);
};

export const joinMetaFilters = (filters: (string | number | undefined)[]) => {
  if (check.not.array(filters)) {
    return '';
  }

  return (filters as string[])?.join(META_DELINEATOR);
};

export const splitFilters = (filters: string) => {
  if (check.not.string(filters)) {
    return [];
  }

  return (filters as string)?.split(DELINEATOR);
};

export const splitFiltersAsNumbers = (filters: string) => {
  if (check.not.string(filters)) {
    return [];
  }

  return (filters as string)?.split(DELINEATOR).map((val) => Number(val));
};

export const splitMetaFilters = (filters: string) => {
  if (check.not.string(filters)) {
    return [];
  }

  return (filters as string)?.split(META_DELINEATOR);
};

export const toHumanFriendlyString = (filters: string) => {
  if (check.not.string(filters)) {
    return [];
  }

  return (filters as string)?.replaceAll(DELINEATOR, ', ');
};
