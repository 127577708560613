import { MantineThemeColors } from '@mantine/core';
import chroma from 'chroma-js';

import { BucketRange } from '@/fine-tune/stores/embeddings-store/embeddings.store.types';

export const CONFIDENCE_BUCKETS_AMOUNT = 10;

export const buildBucketRanges = (
  themeColors: MantineThemeColors
): BucketRange[] => {
  // Using chroma-js to generate a color gradient of CONFIDENCE_BUCKETS_AMOUNT colors
  // from the three base colors (red, yellow, green)
  const scale = chroma
    .scale([themeColors.red[6], themeColors.yellow[4], themeColors.green[5]])
    .colors(CONFIDENCE_BUCKETS_AMOUNT);

  const continuousRange: BucketRange[] = Array.from(
    { length: CONFIDENCE_BUCKETS_AMOUNT },
    (_, i) => {
      const min = Number((i * (1 / CONFIDENCE_BUCKETS_AMOUNT)).toFixed(1));
      const max = Number(
        ((i + 1) * (1 / CONFIDENCE_BUCKETS_AMOUNT)).toFixed(1)
      );

      return {
        color: scale[i],
        min,
        max,
        label: `${+min} - ${max}`
      };
    }
  );

  return continuousRange;
};

// Doing this so we don't have to check if point confidence is
// lesser/greater than all of the ranges
export const getConfidenceRangeKey = (pointConf: number) => {
  const stringConf = pointConf?.toString();

  const [before, after] = stringConf?.split('.') || ['', ''];

  const afterValue = after && after[0] !== '0' ? `.${after[0]}` : '';

  const result = `${before}${afterValue}`;

  return result;
};
