import { Box, Group, Paper, Radio, Text } from '@mantine/core';

interface RadioWithIconProps {
  onRadioClick: (value: string) => void;
  checked?: boolean;
  label: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  id: string;
}

const RadioWithIcon = ({
  label,
  checked,
  icon,
  rightIcon,
  id,
  onRadioClick
}: RadioWithIconProps) => {
  return (
    <Paper
      data-testid='radio-with-icon'
      p={6}
      radius='md'
      style={({ colors }) => ({
        background: 'transparent',
        border: `1px solid ${checked ? colors.violet[3] : colors.contrast[7]}`,
        cursor: 'pointer'
      })}
      onClick={() => onRadioClick(id)}
    >
      <Group gap='xs' pr={4}>
        <Radio checked={checked} readOnly={true} value={label} />
        <Box className='align-center'>
          {icon || null}
          <Text ml={6} mr={rightIcon ? 6 : 0}>
            {label}
          </Text>
          {rightIcon || null}
        </Box>
      </Group>
    </Paper>
  );
};

export default RadioWithIcon;
