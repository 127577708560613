import { useMantineTheme } from '@mantine/core';
import chroma from 'chroma-js';

export const useColors = () => {
  // Hooks
  const theme = useMantineTheme();

  // Functions
  const getSteppedSeverityColor = (
    severity: number,
    hallucination?: number | null
  ) => {
    if (typeof hallucination !== 'number') return 'white';

    const alpha = hallucination * (255 / 100); // 255 is the max alpha value

    if (severity === 0) {
      return chroma(theme.colors.green[1]).hex();
    }

    if (severity === 1) {
      return `rgba(${chroma(theme.colors.yellow[5]).alpha(alpha).rgba()})`;
    }

    if (severity === 2) {
      return `rgba(${chroma(theme.colors.red[5]).alpha(alpha).rgba()})`;
    }
    return 'white';
  };

  return {
    getSteppedSeverityColor
  };
};
