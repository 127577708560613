import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const GET_EDITS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/edits';

export const useEdits = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);
  const inferenceName = useParametersStore((state) => state.inferenceName);

  const enabled = Boolean(projectId && runId && split);

  const fetchEdits = async () => {
    const res = await api.GET(GET_EDITS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        },
        query: {
          inference_name: inferenceName
        }
      }
    });

    return res.data;
  };

  const response = useQuery(
    [GET_EDITS, { projectId, runId, split, inferenceName }],
    fetchEdits,
    {
      enabled
    }
  );

  // FIXME: This is a hack to get it working. The API should do this work
  const set = new Set();
  const reviewed = new Set();

  response?.data?.forEach((i) => {
    i.sample_ids?.forEach((id) => set.add(id));
    if (i.reviewers?.length) {
      i.sample_ids?.forEach((id) => reviewed.add(id));
    }
  }) ?? 0;

  return {
    ...response,
    count: set.size ?? 0,
    reviewedCount: reviewed.size ?? 0
  };
};
