import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  useComputedParameters,
  useParametersStore
} from '@/fine-tune/stores/parameters-store';

const POST_THRESHOLDS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/thresholds';

export const useThresholds = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);
  const inferenceName = useParametersStore((state) => state.inferenceName);
  const task = useParametersStore((state) => state.task);
  const lmPercentile = useParametersStore((state) => state.lmPercentile);

  const isMltc = useComputedParameters('isMltc');
  const isInference = useComputedParameters('isInference');

  let enabled = Boolean(projectId && runId);

  if (isMltc) {
    enabled = Boolean(enabled && task);
  }

  // DEP does not exist in inference, so this query should be disabled
  if (isInference) {
    enabled = false;
  }

  const fetchThresholds = async () => {
    const res = await api.POST(POST_THRESHOLDS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body: {
        task
      }
    });

    return res?.data;
  };

  const response = useQuery(
    [
      POST_THRESHOLDS,
      {
        projectId,
        runId,
        split,
        task,
        inferenceName,
        lmPercentile
      }
    ],
    () => fetchThresholds(),
    { enabled }
  );

  return response;
};
