import { useParametersStore } from '../parameters.store';

export const useMetaFilters = () => {
  return useParametersStore((s) => s.metaFilter);
};

export const getMetaFilters = () => {
  return useParametersStore.getState().metaFilter;
};

export const useErrorTypesMetaFilter = () => {
  return useParametersStore((s) =>
    s.metaFilter.find((meta) => meta.name === 'galileo_error_type')
  );
};
