import { Group, Text, ThemeIcon } from '@mantine/core';

import { IntegrationCard } from '@/core/constants/integrations.constants';

interface TitleWithIconProps {
  title: IntegrationCard['title'];
  icon: IntegrationCard['icon'];
  mt?: string;
}

const TitleWithIcon = ({ title, icon, mt }: TitleWithIconProps) => {
  return (
    <Group data-testid='title-with-icon' gap='sm' mt={mt}>
      <ThemeIcon
        color='white'
        mb={2}
        mr={4}
        style={{ width: !title ? 'auto' : undefined }}
      >
        {icon}
      </ThemeIcon>
      <Text>{title}</Text>
    </Group>
  );
};

export default TitleWithIcon;
