import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { ObserveStoreState } from '@/observe/types/observe-store.types';

const defaultValues = {
  lastUpdatedTime: new Date().getTime(),
  groupVisibility: {},
  selectedMetricsRows: []
};

export const useObserveStore = createWithEqualityFn<ObserveStoreState>()(
  (set) => ({
    ...defaultValues,

    actions: {
      setLastUpdatedTime: (lastUpdatedTime) =>
        set(() => ({
          lastUpdatedTime
        })),
      setGroupVisibility: (hiddenGroups) =>
        set(() => ({
          groupVisibility: hiddenGroups.reduce(
            (acc, group) => ({ ...acc, [group]: false }),
            {}
          )
        })),
      setSelectedMetricsRows: (metricsRows) =>
        set(() => {
          return { selectedMetricsRows: metricsRows };
        })
    },

    computed: {}
  }),
  shallow
);
