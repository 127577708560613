import { ComparedToColor } from '@/fine-tune/types/embeddings.types';

interface ComparisonPointColorOptions {
  [key: string]: ComparedToColor;
}

export const FALLBACK_COLOR = '#d6d6d6';
export const GRAY_POINT_ARR = new Uint8Array([255, 255, 255]);

const c1 = '#440154';
const c2 = '#28AE80';
const c3 = '#FDE725';
export const CONTINUOUS_COLOR_RANGE = [c1, c2, c3];

export const COMPARISON_POINT_COLOR_OPTIONS: ComparisonPointColorOptions = {
  gray: 'gray',
  color: 'color',
  hidden: 'hidden'
};

export const getColorBySelectData = (isColorByDriftScore?: boolean) => [
  {
    label: 'Gray',
    value: COMPARISON_POINT_COLOR_OPTIONS.gray
  },
  {
    label: 'Colored',
    value: COMPARISON_POINT_COLOR_OPTIONS.color,
    disabled: isColorByDriftScore
  },
  {
    label: 'Hidden',
    value: COMPARISON_POINT_COLOR_OPTIONS.hidden
  }
];

export const CLASS_KEY = 'class';
export const DEP_KEY = 'dep';
export const GOLD_KEY = 'gold';
export const MISCLASSIFIED_KEY = 'misclassified';
export const PRED_KEY = 'pred';
export const CONFIDENCE_KEY = 'confidence';
export const DRIFT_SCORE_KEY = 'drift-score';
export const CLASS_OVERLAP_SCORE_KEY = 'class-overlap-score';
export const CLUSTER_ID_KEY = 'cluster_id';

export const COLORING_OPTIONS = {
  [DEP_KEY]: 'Data Error Potential',
  [GOLD_KEY]: 'Ground Truth',
  [MISCLASSIFIED_KEY]: 'Misclassified',
  [PRED_KEY]: 'Predicted',
  [CLUSTER_ID_KEY]: 'Cluster'
};

export const NON_META_COLORING_OPTIONS = [
  CLASS_KEY,
  CONFIDENCE_KEY,
  PRED_KEY,
  GOLD_KEY,
  MISCLASSIFIED_KEY,
  DEP_KEY,
  CLUSTER_ID_KEY
];

export const S2S_BASE_COLORING_OPTIONS = {
  [DEP_KEY]: 'DEP',
  perplexity: 'Perplexity'
};

export const S2S_EXTRA_COLORING_OPTIONS = {
  bleu: 'Bleu',
  rouge: 'Rouge'
};

export const INFERENCE_COLORING_OPTIONS = {
  [CONFIDENCE_KEY]: 'Confidence',
  [PRED_KEY]: 'Predicted Class'
};

export const DRIFT_COLORING_OPTION = { [DRIFT_SCORE_KEY]: 'Drift Score' };

export const INITIAL_VIEW_STATE = {
  longitude: 5,
  latitude: 5,
  zoom: 5,
  pitch: 0,
  bearing: 0
};

export const SELECTION_LAYER_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: []
};
