import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { useCurrentUser } from '../use-current-user/use-current-user';

const API_KEYS_PATH = '/users/{user_id}/api_keys';

const toAPIDateFormat = (date: Date | null) => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const useAPIKeys = () => {
  const queryClient = useQueryClient();

  // User
  const { data: user } = useCurrentUser();

  // Dependencies
  const enabled = !!user?.id;

  // Query
  const queryKey = [API_KEYS_PATH, { userId: user?.id }];

  const fetchAPIKeys = async (userId?: string) => {
    const res = await api.GET(API_KEYS_PATH, {
      params: {
        path: {
          user_id: userId || user?.id || ''
        }
      }
    });

    return res.data;
  };

  const handleCreateAPIKey = ({
    description,
    expiresAt
  }: {
    description: string;
    expiresAt: Date | null;
  }) => {
    return api.POST('/users/api_keys', {
      body: { description, expires_at: toAPIDateFormat(expiresAt) }
    });
  };

  const createAPIKey = useMutation(handleCreateAPIKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);

      showNotification({
        title: 'API key created',
        message: 'API Key has been created successfully',
        type: 'success'
      });
    },
    onError: (res: Response) =>
      defaultErrorHandler(res, 'Error creating API Key')
  });

  const handleDeleteKey = (id: string) => {
    return api.DELETE('/users/api_keys/{api_key_id}', {
      params: {
        path: {
          api_key_id: id
        }
      }
    });
  };

  const deleteKey = useMutation(handleDeleteKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (res: Response) =>
      defaultErrorHandler(res, 'Error deleting API Key')
  });

  const config = { enabled };

  const response = useQuery(queryKey, () => fetchAPIKeys(), config);

  return { ...response, createAPIKey, deleteKey, fetchAPIKeys };
};
