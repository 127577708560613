import { Group, Text } from '@mantine/core';
import React from 'react';

import { useColors } from '@/fine-tune/hooks/use-colors/use-colors';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';

interface LabeledWordPopoverProps {
  dep: number;
  errorType: string;
  confidence: number;
}

/**
 * LabeledWordPopover
 *
 */
const LabeledWordPopover = ({
  dep = 0,
  errorType,
  confidence = 0
}: LabeledWordPopoverProps) => {
  const isInferenceNer = useComputedParameters('isInferenceNer');
  const { getDepColor, getDepOrConfidenceColor } = useColors();

  let color = getDepColor(dep);
  let value = dep;
  let copy = 'DEP';

  if (isInferenceNer) {
    color = getDepOrConfidenceColor(confidence);
    value = confidence;
    copy = 'Confidence';
  }

  return (
    <>
      <Group data-testid='labeled-word-popover' justify='space-between'>
        <Text>{copy}</Text>
        <Text c={color}>{value}</Text>
      </Group>
      {!isInferenceNer && <Text tt='capitalize'>{errorType}</Text>}
    </>
  );
};

export default LabeledWordPopover;
