import check from 'check-types';
import { difference, isEqual } from 'lodash';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import Router from 'next/router';
import { StateCreator, StoreMutatorIdentifier } from 'zustand';

import {
  CONTINUOUS_DELINEATOR,
  EQUAL_DELINEATOR,
  joinFilters,
  joinMetaFilters
} from '@/core/utils/parse-query-param-arrays/parse-query-param-arrays';
import {
  DEFAULT_INSIGHTS_PARAMS,
  MetaFilter
} from '@/fine-tune/types/query.types';

import {
  ParametersKeys,
  ParametersState,
  PartialParameters
} from '../parameters.store.types';

// https://docs.pmnd.rs/zustand/guides/typescript#middleware-that-doesn't-change-the-store-type

type Persister = <
  T extends ParametersState,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type PersisterImpl = (
  f: StateCreator<ParametersState, [], []>,
  name?: string
) => StateCreator<ParametersState, [], []>;

// Clean parameters by filtering out parameters that are not related to the task type
const cleanParameters = (
  newParams: PartialParameters,
  defaultParams: PartialParameters
) => {
  const filterKeys = Object.keys(defaultParams) as ParametersKeys[];
  const newParamsKeys = Object.keys(newParams) as ParametersKeys[];

  // Only return keys that are related to the TaskType AND have a different value than the default value
  const cleanedKeys = newParamsKeys.filter((key) => {
    let isParamEqual = isEqual(newParams[key], defaultParams[key]);

    // Check the difference between arrays in case the param has the same values as the default, but the order is different
    if (Array.isArray(newParams[key]) && Array.isArray(defaultParams[key])) {
      isParamEqual =
        difference(newParams[key] as string[], defaultParams[key] as string[])
          .length === 0;
    }

    return (
      filterKeys.includes(key) &&
      !isParamEqual && // filter out default values
      newParams[key] !== '' // filter out undefined values -- router.query.value === '' when value is undefined
    );
  });

  if (cleanedKeys.length === 0) {
    return {};
  }

  // Cleaned Params are the params that are related to the task type and have a different value than the default value
  const cleanedParameters = cleanedKeys.reduce((paramObj, key) => {
    paramObj[key] = newParams[key] as any;
    return paramObj;
  }, {} as NextParsedUrlQuery);

  return cleanedParameters;
};

// Persists to url every time state is changed
const persistInUrlMiddleware: PersisterImpl = (config) => (set, get, api) =>
  config(
    (...args) => {
      // Sets state
      set(...args);
      // Sets to url - pass diff here
      const state: ParametersState = get();
      const updatedParams = state?.differs?.getDiff();
      persistInUrl(updatedParams);
    },
    get,
    api
  );

export const persistStateInUrl = persistInUrlMiddleware as unknown as Persister;

const persistInUrl = (params: PartialParameters) => {
  const isInsightsRoute = Router?.pathname?.includes('/insights');

  let defaults = {};

  if (isInsightsRoute) {
    defaults = DEFAULT_INSIGHTS_PARAMS;
  }

  // Path params get passed to all query param pushes
  const pathParams: PartialParameters = {
    projectId: Router?.query?.projectId as string | undefined
  };

  if (Router?.query?.runId) {
    pathParams.runId = Router?.query?.runId as string | undefined;
  }

  // Clean the params. This is needed so we don't pollute the url with unnecessary params from other task types, or default values
  const cleanedParams = cleanParameters(params, defaults);

  // Convert parsed params to url params
  const parsedParams = parseUrlParams(cleanedParams as PartialParameters);

  // Get URL without query parameters (this ensures default parameters get removed)
  const nextUrl = new URL(window.location.origin + window.location.pathname);

  // Include only the parsed query parameters
  for (const [key, value] of Object.entries(parsedParams)) {
    nextUrl.searchParams.set(key, value as string);
  }

  // Get the string formatted path and query parameters
  const urlPathWithQueryParameters = `${nextUrl.pathname}${nextUrl.search}`;

  // NOTE: we push parameters to the url manually instead of using Router because
  // Router causes a react state change (which re-renders the entire application
  // component from the root level)
  window.history.pushState({}, '', urlPathWithQueryParameters);

  if (nextUrl.searchParams.has('runId')) {
    Router.push(urlPathWithQueryParameters);
  }
};

export const parseUrlParams = (params: PartialParameters) => {
  const parsed = Object.entries(params || {}).reduce((acc, [key, value]) => {
    if (key === 'metaFilter') {
      if (check.not.array(value)) return acc;
      const urlMetaFilters = (value as unknown as MetaFilter[]).map(
        (meta) =>
          `${meta.name}:${
            meta.isin
              ? joinFilters(meta.isin)
              : meta.is_equal
                ? `${meta.is_equal}${EQUAL_DELINEATOR}`
                : `${meta.greater_than}${CONTINUOUS_DELINEATOR}${meta.less_than}`
          }`
      );
      return { ...acc, metaFilter: joinMetaFilters(urlMetaFilters) };
    }

    if (check.array(value)) {
      return {
        ...acc,
        [key]: joinFilters(value as (string | number)[])
      };
    }

    return { ...acc, [key]: value };
  }, {});

  return parsed;
};
