import _capitalize from 'lodash/capitalize';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export const parseMetaColumnName = (metaColumnName: string): string => {
  const parsedName = metaColumnName
    ?.replace(/galileo_/, '')
    ?.replace(/_/g, ' ');

  return _capitalize(parsedName) || STRING_PLACEHOLDER;
};
