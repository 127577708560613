import { MetricColor } from '@/core/components/atoms/metric/metric';

/** Util to format the color and label for a score */
export function getScoreColorAndLabel({
  value,
  dangerThreshold,
  highIsBad = false,
  showThresholdLabel = false,
  warningThreshold,
  warningIsNeutral = false
}: {
  value: any;
  dangerThreshold: number | undefined;
  highIsBad?: boolean | undefined;
  showThresholdLabel?: boolean;
  warningThreshold?: number | undefined;
  warningIsNeutral?: boolean | undefined;
}): { label: string | undefined; color: MetricColor } {
  const valueAsNumber: number | undefined =
    value != null && typeof value === 'number' && !isNaN(value)
      ? value
      : undefined;

  let label: string | undefined;
  let color: MetricColor = 'gray';

  if (valueAsNumber != null) {
    if (highIsBad) {
      if (dangerThreshold != null && valueAsNumber >= dangerThreshold) {
        label = 'High';
        color = 'red';
      } else if (
        warningThreshold != null &&
        valueAsNumber >= warningThreshold
      ) {
        label = 'Medium';
        color = warningIsNeutral ? 'gray' : 'yellow';
      } else {
        label = 'Low';
        color = 'green';
      }
    } else {
      if (dangerThreshold != null && valueAsNumber <= dangerThreshold) {
        label = 'Low';
        color = 'red';
      } else if (
        warningThreshold != null &&
        valueAsNumber <= warningThreshold
      ) {
        label = 'Medium';
        color = warningIsNeutral ? 'gray' : 'yellow';
      } else {
        label = 'High';
        color = 'green';
      }
    }
  }

  return {
    label: showThresholdLabel ? label : undefined,
    color
  };
}
