import { components } from '@/core/types/api';

import { DateUnit as Date } from './date-conversions';
import { ListUnit as List } from './list-conversions';
import { NumberUnit as Number } from './number-conversions';
import { StringUnit as String } from './string-conversions';

export const DateUnit = Date;
export const ListUnit = List;
export const NumberUnit = Number;
export const StringUnit = String;

type APIDataType = components['schemas']['DataTypeOptions'];

// TODO: temporary, these were ported over from Observe's formatters
// and need to be deduplicated with the existing formatters once
// we figure out where parsing should live
type ParsedUnitFormatType =
  | 'parsed__dollars'
  | 'parsed__milliseconds'
  | 'parsed__date'
  | 'parsed__datetime'
  | 'parsed__startCase'
  | 'parsed__time'
  | 'parsed__score'
  | 'parsed__whole_number'
  | 'parsed__number';

export type UnitFormatType =
  | APIDataType
  | ParsedUnitFormatType
  | 'pii'
  | 'protect_status';
