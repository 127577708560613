import { components } from '@/core/types/api';
import { ObserveFilterType } from '@/observe/types/observe-store.types';

/**
 * Util to convert filter types into API accepted formats.
 * This is temporary until clickhouse stuff is finished and we
 * can have filtering and sorting speak in terms of unique ids
 * for each field instead of having to pass in metadata to the API.
 */
export function convertFilterToFieldType(
  filterType: ObserveFilterType | undefined
): components['schemas']['FieldType'] | undefined {
  switch (filterType) {
    case 'string':
    case 'string-array':
    case 'multi-chooser':
    case 'node-type':
    case 'tags':
      return 'string';
    case 'multi-chooser-metric-array':
      return 'array';
    case 'dollars':
    case 'float':
    case 'score':
    case 'milliseconds':
      return 'float';
    case 'integer':
    case 'whole-number':
      return 'int';
    default:
      return undefined;
  }
}
