// @ts-nocheck
import { Group, Text, Tooltip, UnstyledButton } from '@mantine/core';

import { SS_ERROR_MAPPING } from '@/fine-tune/constants/semantic-segmentation.constants';
import { SemanticRow } from '@/fine-tune/types/query.types';

const SsErrors = ({
  sample,
  withLabels,
  hiddenErrors,
  hideFilteredPolygons,
  onToggleError
}: {
  sample: SemanticRow;
  withLabels?: boolean;
  hideFilteredPolygons?: boolean;
  hiddenErrors?: string[];
  onToggleError: (errorType: string) => void;
}) => {
  const sampleErrors = sample.polygons?.reduce((errors, polygon) => {
    const errorType = polygon.error_type;

    const isFiltered = hideFilteredPolygons && !polygon.is_active;

    if (errorType === 'None' || isFiltered) return errors;

    if (!errors[errorType]) {
      errors[errorType] = 1;
    } else {
      errors[errorType] += 1;
    }
    return errors;
  }, {});

  const errors = Object.keys(SS_ERROR_MAPPING).filter(
    (key) => sampleErrors?.[key] > 0
  );

  if (!errors.length) return null;

  return (
    <>
      {errors.map((errKey) => {
        const count = sampleErrors[errKey];

        const { label, icon, text } = SS_ERROR_MAPPING[errKey];

        return (
          <Tooltip key={label} label={withLabels ? text : label}>
            <UnstyledButton
              aria-label={errKey}
              mr={6}
              opacity={hiddenErrors?.includes(errKey) ? 0.5 : 1}
              onClick={() => onToggleError(errKey)}
            >
              <Group gap={4} wrap='nowrap'>
                {icon}
                <Text>{count}</Text>
                {withLabels && <Text size='sm'>{label}s</Text>}
              </Group>
            </UnstyledButton>
          </Tooltip>
        );
      })}
    </>
  );
};

export default SsErrors;
