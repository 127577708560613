import { Group } from '@mantine/core';
import React from 'react';

import ModalControls from '@/fine-tune/components/modal-controls/modal-controls';

interface ModalCarouselProps {
  width?: string | number;
  withTitle?: boolean;
}

const ModalCarouselHeader = ({
  width,
  withTitle = true
}: ModalCarouselProps) => {
  return (
    <Group
      data-testid='modal-carousel-header'
      gap='xs'
      justify='space-between'
      w={width || '85vw'}
    >
      <ModalControls withTitle={withTitle} />
    </Group>
  );
};

export default ModalCarouselHeader;
