import { Box, Group, Text } from '@mantine/core';
import React, { ForwardedRef, useEffect } from 'react';

import ButtonGroup from '@/fine-tune/components/button-group/button-group';
import { OD_ERROR_MAP } from '@/fine-tune/constants/object-detection.constants';
import {
  ACTION_IDS,
  useActionButtons
} from '@/fine-tune/hooks/use-action-buttons/use-action-buttons';
import useStore from '@/fine-tune/stores/store';
import { InsightsRow, OdBoundingBox } from '@/fine-tune/types/query.types';

import DepOrConfidence from '../dep-or-confidence/dep-or-confidence';
import OdLabelBadge from '../label-badge/od-label-badge/od-label-badge';
interface OdPopoverProps {
  sample: OdBoundingBox;
  row: InsightsRow;
}

const OdPopover = React.forwardRef(
  (props: OdPopoverProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { sample, row } = props;
    const { id, gold, pred, is_gold, error_type } = sample;

    const { objectDetectionModalButtons } = useActionButtons();
    const buttons = objectDetectionModalButtons(row.id);

    const { setSelectedObjects } = useStore((state) => state.actions);

    useEffect(() => {
      setSelectedObjects([id]);
    }, []);

    const btns = is_gold
      ? [
          ACTION_IDS.SIMILAR_TO_ACTION_ID,
          ACTION_IDS.SET_LABELS_ACTION_ID,
          ACTION_IDS.REMOVE_ACTION_ID
        ]
      : [
          ACTION_IDS.SIMILAR_TO_ACTION_ID,
          ACTION_IDS.REPLACE_WITH_PREDICTION_ACTION_ID,
          ACTION_IDS.REPLACE_WITH_GOLD_ACTION_ID
        ];
    const filteredButtons = buttons.filter((btn) => {
      return btns.includes(btn.id);
    });

    const error = Object.entries(OD_ERROR_MAP).find(
      (err) => err[0] === error_type
    );

    return (
      <Box {...props} data-testid='od-popover' ref={ref}>
        <Group justify='space-between' mb='md'>
          <OdLabelBadge label={is_gold ? gold : pred} />
          <Text c='dimmed' size='xs'>
            {is_gold ? 'Ground Truth' : 'Predicted'}
          </Text>
        </Group>
        {error && (
          <Group mb='md'>
            {error[1].icon}
            <Text c='dimmed'>{error[1].label}</Text>
          </Group>
        )}
        <Group grow mb='md'>
          <DepOrConfidence
            accessor='data_error_potential'
            component='div'
            sample={sample}
            variant='filled'
          />
          {!is_gold && (
            <DepOrConfidence
              accessor='confidence'
              component='div'
              sample={sample}
              variant='filled'
            />
          )}
        </Group>
        <ButtonGroup vertical buttons={filteredButtons} variant='light' />
      </Box>
    );
  }
);

export default OdPopover;
