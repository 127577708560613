import {
  Box,
  Group,
  HoverCard,
  Text,
  ThemeIcon,
  useMantineTheme
} from '@mantine/core';
import { IconFileDescription } from '@tabler/icons-react';

import Metric from '@/core/components/atoms/metric/metric';
import ScoreMetric from '@/core/components/molecules/score-metric/score-metric';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import { getScoreColorAndLabel } from '@/core/utils/get-score-color-and-label/get-score-color-and-label';
import { Chunk } from '@/observe/utils/get-chunks-from-row/get-chunks-from-row';

export const ChunksOutputChip = ({ chunks }: { chunks: Chunk[] }) => {
  const { colors } = useMantineTheme();
  return (
    <HoverCard withinPortal zIndex={Z_INDEX.TOOLTIPS}>
      <HoverCard.Target>
        <Group
          align='center'
          data-testid='chunks-output-chip'
          gap={2}
          style={{
            background: `rgba(225, 240, 254, 0.48)`,
            border: `1px solid ${colors.blue[2]}`,
            borderRadius: '20px',
            padding: '2px 8px'
          }}
          wrap='nowrap'
        >
          <ThemeIcon c={colors.blue[1]} size='1rem' variant='white'>
            <IconFileDescription size='1rem' />
          </ThemeIcon>
          <Text className='truncate'>
            {`${chunks.length > 0 ? chunks.length : STRING_PLACEHOLDER}`} chunks
            retrieved
          </Text>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown
        bg='gray.7'
        c='white'
        mah={400}
        maw={300}
        p={0}
        style={{ overflowY: 'auto' }}
      >
        <Text p='md' style={{ flexShrink: 0 }}>
          {`${chunks.length > 0 ? chunks.length : STRING_PLACEHOLDER}`} chunks
          retrieved
        </Text>
        <Box bg='gray.8' style={{ overflowY: 'auto' }}>
          {chunks.map((chunk, index) => (
            <Box
              key={index}
              maw='100%'
              p='md'
              style={{ borderBottom: `1px solid ${colors.gray[7]}` }}
            >
              <Group justify='space-between' mb='sm'>
                {chunk.attributed != null && (
                  <AttributeToOutput attributed={chunk.attributed} />
                )}
                {chunk.attributed === true && chunk.utilization != null && (
                  <ChunkUtilization utilization={chunk.utilization} />
                )}
              </Group>
              <Text lineClamp={5} size='sm'>
                {chunk.outputText}
              </Text>
            </Box>
          ))}
        </Box>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export const AttributeToOutput = ({ attributed }: { attributed: boolean }) => {
  const { colors } = useMantineTheme();
  const color = attributed ? 'blue.1' : 'white';
  const value = attributed ? 'Yes' : 'No';
  return (
    <Metric
      color='gray'
      data-testid='attribute-to-output'
      style={{
        padding: '6px 12px',
        backgroundColor: colors.gray[9]
      }}
      value={
        <Text c='white' fw={400} size='sm'>
          Attributed
        </Text>
      }
      variant='filled'
    >
      <Text c={color} fw={600} size='sm'>
        {value}
      </Text>
    </Metric>
  );
};

export const ChunkUtilization = ({ utilization }: { utilization: number }) => {
  const { colors } = useMantineTheme();
  return (
    <Metric
      color='gray'
      data-testid='chunk-utilization'
      style={{
        padding: '3px 2px 3px 12px',
        backgroundColor: colors.gray[9]
      }}
      value={
        <Text c='white' fw={400} size='sm'>
          Utilization
        </Text>
      }
      variant='filled'
    >
      <ScoreMetric
        value={utilization}
        variant='subtle'
        {...getScoreColorAndLabel({
          value: utilization,
          dangerThreshold: 0.3,
          warningIsNeutral: true,
          warningThreshold: 0.75
        })}
      />
    </Metric>
  );
};
