import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Grid,
  Group,
  Input,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Text,
  Textarea,
  Tooltip
} from '@mantine/core';
import { IconCheck, IconPencil, IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useModals } from '@/core/hooks/use-modals/use-modals';
import { ContextModalPropsWithRowId } from '@/core/hooks/use-modals/use-modals.types';
import { useOnMount } from '@/core/hooks/use-on-mount/use-on-mount';
import Label from '@/fine-tune/components/label/label';
import LabelBadge from '@/fine-tune/components/label-badge/label-badge';
import OdLabelBadge from '@/fine-tune/components/label-badge/od-label-badge/od-label-badge';
import { useEditCreate } from '@/fine-tune/hooks/query-hooks/use-edit-create/use-edit-create';
import {
  GET_EDITS_LABELS,
  useEditLabels
} from '@/fine-tune/hooks/query-hooks/use-edit-labels/use-edit-labels';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { usePercentageSample } from '@/fine-tune/hooks/query-hooks/use-percentage-sample/use-percentage-sample';
import {
  EDIT_ACTIONS,
  EditAction
} from '@/fine-tune/stores/data-edits-store/data-edits.store.types';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { CreateEditArgs } from '@/fine-tune/types/mutation.types';

const { CREATE_NEW_LABEL, RELABEL, RELABEL_AS_PRED } = EDIT_ACTIONS;

interface CustomOption {
  label: string;
  value: string;
  isNew?: boolean;
}

const renderSelectItem = ({
  option,
  checked
}: {
  option: CustomOption;
  checked?: boolean;
}) => (
  <Group flex='1' gap='xs'>
    {checked && <IconCheck size={14} />}
    {option.label}
    {option.isNew && (
      <Badge color='brand' variant='light'>
        New
      </Badge>
    )}
  </Group>
);

const SetLabelModal = ({ id, innerProps }: ContextModalPropsWithRowId) => {
  // Computed
  const isOd = useComputedParameters('isOd');

  // Local State
  const [changeToLabel, setChangeToLabel] = useState<string>('');
  const [reason, setReason] = useState('');
  const [editAction, setEditAction] = useState<EditAction | null>(
    isOd ? RELABEL : RELABEL_AS_PRED
  );
  const [canCreateNewLabel, setCanCreateNewLabel] = useState<boolean>(true);

  // Input Ref
  const newLabelRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  // Global Store
  const fixedParams = {
    ...(editAction === RELABEL_AS_PRED && { correctly_classified: true }),
    ...(editAction === RELABEL && { gold_filter: [changeToLabel] })
  };

  const { data: comparedData } = usePercentageSample(fixedParams);
  const difference = editAction ? comparedData?.sample_count || 0 : 0;
  const allRowsSelected = useStore((state) => state.allRowsSelected);

  // Hooks
  const {
    createEditMutation,
    changesByLabel,
    changesToSamePred,
    getChangesToSameLabelAmount
  } = useEditCreate(innerProps?.rowId);

  const queryClient = useQueryClient();

  const editLabels = useEditLabels();
  const labels = useLabels();
  const { closeModal } = useModals();

  const isLoading = editLabels.isLoading || labels.isLoading;

  // Combine the created labels and existing labels
  const allLabelItemsSorted: CustomOption[] = useMemo(
    () =>
      [
        ...(editLabels?.data || [])
          .filter((lbl) => !(labels?.data?.labels || []).includes(lbl))
          .map((label) => ({
            label,
            value: label,
            isNew: true
          })),
        ...(labels?.data?.labels || []).map((label) => ({
          label,
          value: label
        }))
      ].sort((itemA, itemB) => itemA.value.localeCompare(itemB.value)),
    [editLabels?.data, labels?.data]
  );

  // Effect
  useEffect(() => {
    if (!changeToLabel.length) {
      newLabelRef?.current?.focus();
    }
  }, [changeToLabel, editAction]);

  useOnMount(() => {
    // keep the labels in sync with any changes to the edits cart and other created labels
    queryClient.invalidateQueries([GET_EDITS_LABELS]);
  });

  // Event Handlers
  const handleChangeNewLabel = () => {
    const newLabel = newLabelRef?.current?.value?.toString();
    const isUnique =
      newLabel != null &&
      allLabelItemsSorted.findIndex(({ value }) => value === newLabel) === -1;

    // don't allow creating labels that already exist
    setCanCreateNewLabel(isUnique);
  };

  const handleCreateNewLabel = () => {
    const newLabel = newLabelRef?.current?.value?.toString();

    if (typeof newLabel === 'string' && newLabel.length > 0) {
      setEditAction(CREATE_NEW_LABEL);
      setChangeToLabel(newLabel);
    }
  };

  const handleSelectLabel = (value: string | null) => {
    setChangeToLabel(value as string);
    // Selecting a previously created label still requires `CREATE_NEW_LABEL`
    if (editLabels.data?.includes(value as string)) {
      setEditAction(CREATE_NEW_LABEL);
    } else {
      setEditAction(RELABEL);
    }
  };

  const handleReasonUpdate = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event?.target?.value);
  };

  const handleSubmit = () => {
    if (!editAction) {
      return;
    }

    const body: CreateEditArgs = {
      note: reason,
      edit_action: editAction
    };

    if (changeToLabel) {
      body.new_label = changeToLabel;
    }

    createEditMutation.mutate(body);
    closeModal(id);
  };

  const sameLabelChangesAmount = allRowsSelected
    ? difference
    : getChangesToSameLabelAmount(
        editAction === RELABEL_AS_PRED,
        changeToLabel
      );

  const hideNewLabelBadge =
    editAction !== CREATE_NEW_LABEL ||
    !changeToLabel?.length ||
    (editAction === CREATE_NEW_LABEL &&
      editLabels?.data?.includes(changeToLabel));

  return (
    <Box data-testid='set-label-modal'>
      <Text c='gray' fw={500} size='sm'>
        Selected Predictions
      </Text>
      <Paper withBorder my={8} p='sm'>
        <ScrollArea style={{ height: 100 }}>
          {Object.entries(changesByLabel || {}).map(([label, amount]) => (
            <Group justify='space-between' key={label} mb={4}>
              {isOd ? (
                <OdLabelBadge label={label} />
              ) : (
                <Label labelName={label || 'Ghost span'} size='sm' />
              )}
              <Text c='gray' size='sm'>
                <Text c='gray' component='span' fw={600} size='sm'>
                  <>
                    {allRowsSelected && '+'}
                    {amount}
                  </>
                </Text>{' '}
                selected
              </Text>
            </Group>
          ))}
        </ScrollArea>
      </Paper>
      {!isOd && (
        <Checkbox
          checked={editAction === RELABEL_AS_PRED}
          label={`Set to Predicted ${
            changesToSamePred ? `(${changesToSamePred})` : ''
          }`}
          mt='sm'
          onChange={() => {
            setChangeToLabel('');
            setEditAction(
              editAction === RELABEL_AS_PRED ? null : RELABEL_AS_PRED
            );
          }}
        />
      )}
      <Text c='gray' fw={500} mt={12} size='sm'>
        Select Existing
      </Text>
      <div className='col-span-3 pt-0.5 text-sm text-gray-800'>
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <Select
            searchable
            comboboxProps={{ withinPortal: false }}
            data={allLabelItemsSorted}
            disabled={editAction === RELABEL_AS_PRED}
            mr='sm'
            renderOption={renderSelectItem}
            size='sm'
            value={changeToLabel}
            onChange={handleSelectLabel}
          />
        )}
      </div>
      <Stack align='center' w='100%'>
        <Text c='gray' fw={500} mt={12} size='sm'>
          or
        </Text>
        <Button
          hidden={
            // only hide if the created label is new
            editAction === CREATE_NEW_LABEL &&
            !editLabels?.data?.includes(changeToLabel)
          }
          variant='subtle'
          onClick={() => {
            setChangeToLabel('');
            setEditAction(CREATE_NEW_LABEL);
          }}
        >
          <IconPlus /> Create new
        </Button>

        {editAction === CREATE_NEW_LABEL && !changeToLabel.length && (
          <>
            <Group w='100%' wrap='nowrap'>
              <Input
                placeholder='Write label here'
                ref={newLabelRef}
                w='80%'
                onChange={handleChangeNewLabel}
              />
              <Button
                disabled={!canCreateNewLabel}
                variant='outline'
                onClick={handleCreateNewLabel}
              >
                Save
              </Button>
            </Group>
            {newLabelRef.current?.value != null && !canCreateNewLabel && (
              <Group justify='center' w='100%' wrap='nowrap'>
                <Text c='red.6' size='xs' ta='left'>
                  Label already exists.
                </Text>
                <Button
                  size='xs'
                  variant='subtle'
                  onClick={() => {
                    if (newLabelRef.current?.value != null) {
                      handleSelectLabel(newLabelRef.current.value);
                    }
                  }}
                >
                  Use existing label
                </Button>
              </Group>
            )}
          </>
        )}

        {!hideNewLabelBadge && (
          <Center m='lg'>
            <Tooltip
              withArrow
              className='no-transform'
              label={
                <Center>
                  Edit Label <IconPencil size='16' />
                </Center>
              }
            >
              <LabelBadge
                size='lg'
                value={changeToLabel}
                onClick={() => setChangeToLabel('')}
              />
            </Tooltip>
          </Center>
        )}
      </Stack>
      <Text c='gray' fw={500} mt={12} size='sm'>
        Notes (optional)
      </Text>
      <Textarea
        mb='sm'
        placeholder='Write here'
        onChange={handleReasonUpdate}
      />
      <Grid mt={12}>
        {sameLabelChangesAmount > 0 && (
          <Grid.Col py={0} span={12}>
            <Text c='gray.6' size='xs'>
              Found {sameLabelChangesAmount} label
              {sameLabelChangesAmount > 1 ? 's' : ''} set to the same value as
              before!
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={6}>
          <Text c='gray.6' size='xs'>
            You can always undo this change from the data change log
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button color='brand' variant='subtle' onClick={() => closeModal(id)}>
            Cancel
          </Button>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button
            color='brand'
            disabled={
              !editAction ||
              (editAction === CREATE_NEW_LABEL && !changeToLabel?.length)
            }
            id='set-label-modal-submit'
            variant='filled'
            onClick={handleSubmit}
          >
            Set Label
          </Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default SetLabelModal;
