export const PYTORCH = 'pytorch';
export const TENSORFLOW = 'tensorflow';
export const SPACY = 'spacy';
export const PYTORCH_HUGGING_FACE = 'pytorch-hugging-face';
export const TENSORFLOW_HUGGING_FACE = 'tensorflow-hugging-face';
export const HUGGING_FACE = 'hugging-face';
export const ULTRALYTICS = 'ultralytics';

export const TASK_TYPE = {
  NLI: '-1',
  MCTC: '0',
  MLTC: '1',
  NER: '2',
  IC: '3',
  SD: '4',
  OD: '5',
  SS: '6',
  PE: '7',
  S2S: '8',
  PM: '9',
  PROMPT_CHAINS: '12'
} as const;

export type TaskType = (typeof TASK_TYPE)[keyof typeof TASK_TYPE];

export const TASK_TYPE_LABEL_MAP = {
  '-1': 'NLI',
  '0': 'TC',
  '1': 'MLTC',
  '2': 'NER',
  '3': 'IC',
  '4': 'SD',
  '5': 'OD',
  '6': 'SS',
  '7': 'PE',
  '8': 'S2S',
  '9': 'PM'
};

export type FrameworkType =
  | typeof PYTORCH
  | typeof TENSORFLOW
  | typeof SPACY
  | typeof PYTORCH_HUGGING_FACE
  | typeof TENSORFLOW_HUGGING_FACE
  | typeof HUGGING_FACE
  | typeof ULTRALYTICS;
