import {
  Box,
  Card,
  Grid,
  Group,
  Highlight,
  NumberInput,
  Select,
  Tabs,
  Text
} from '@mantine/core';
import { IconMessageCircle, IconPhoto } from '@tabler/icons-react';

import Button from '@/fine-tune/components/button/button';
import GoldSpanHighlighter, {
  GoldSpanHighlighterProps
} from '@/fine-tune/components/gold-span-highlighter/gold-span-highlighter';
import { EDIT_ACTIONS } from '@/fine-tune/constants/data-edits.constants';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';
import { EditAction } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';

import Notes from '../notes/notes';
import Search from '../search/search';

const mockText =
  'The energetic and extra quick, dark brown fox jumps well over the lazy, sleeping dog';
const splitMockText = mockText.split(' ');
const mockStartIndex = 5;
const mockEndIndex = 6;
import classes from './ner-content.module.css';
/**
 * NerSearchContent
 *
 *
 *
 * @returns {React.Component} NerSearchContent
 */
export const NerSearchContent = ({
  onEditCreate
}: {
  onEditCreate: (action: EditAction) => void;
}) => {
  const { data: labelData } = useLabels();

  const {
    spanStartIndex,
    spanEndIndex,
    changeLabelTo,
    searchTerm,
    currentTab,
    actions
  } = useDataEditsStore((s) => ({
    spanStartIndex: s.spanStartIndex,
    spanEndIndex: s.spanEndIndex,
    changeLabelTo: s.changeLabelTo,
    searchTerm: s.searchTerm,
    currentTab: s.currentTab,
    actions: s.actions
  }));

  const {
    setCurrentTab,
    setSpanStartIndex,
    setSpanEndIndex,
    setChangeLabelTo
  } = actions;

  const start = spanStartIndex + mockStartIndex;
  const end = spanEndIndex + mockEndIndex;

  const isGreaterThanMockLeft = start >= 0;
  const isGreaterThanMockRight = end <= splitMockText?.length;

  const startIndx = isGreaterThanMockLeft ? start : 0;
  const endIndx = isGreaterThanMockRight ? end : splitMockText?.length;

  const valuesToHighlight = splitMockText.slice(startIndx, endIndx).join(' ');

  // Prevent duplicate words from being highlighted
  const renderSelectedText = () => {
    const preHighlightTxt = splitMockText.slice(0, startIndx).join(' ');
    const highlightTxt = (
      <Highlight
        className={classes.highlight}
        component='span'
        highlight={valuesToHighlight}
        highlightStyles={{
          display: 'inline-flex',
          flexDirection: 'column',
          padding: 4,
          paddingBottom: 0,
          backgroundColor: 'var(--mantine-color-gray-0)',
          border: `1px solid var(--mantine-color-green-6)`,
          borderRadius: 4
        }}
        ml={2}
        mr={2}
      >
        {valuesToHighlight}
      </Highlight>
    );

    const postHighlightTxt = splitMockText
      .slice(endIndx, splitMockText.length)
      .join(' ');

    return (
      <Text component='span'>
        {preHighlightTxt}
        {highlightTxt}
        {postHighlightTxt}
      </Text>
    );
  };

  return (
    <Tabs
      color='brand.5'
      data-testid='ner-search-content'
      value={currentTab}
      variant='pills'
      onChange={(tab) => setCurrentTab(tab as 'add' | 'shift')}
    >
      <Tabs.List>
        <Tabs.Tab leftSection={<IconPhoto size={14} />} value='add'>
          Add Span
        </Tabs.Tab>
        <Tabs.Tab leftSection={<IconMessageCircle size={14} />} value='shift'>
          Shift Span
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel pt='xs' value='add'>
        <Search />

        <Card className='h-100' mt='lg'>
          <Select
            comboboxProps={{ withinPortal: false }}
            data={labelData?.labels ?? []}
            label='To'
            maxDropdownHeight={150}
            value={changeLabelTo}
            onChange={(label = '') => setChangeLabelTo(label ?? '')}
          />
          <Notes />

          <Group justify='flex-end' mt='lg'>
            <Button
              copy='Send to Edits Cart'
              disabled={!searchTerm || !changeLabelTo}
              onClick={() => onEditCreate(EDIT_ACTIONS.ADD_SPAN)}
            />
          </Group>
        </Card>
      </Tabs.Panel>

      <Tabs.Panel pt='xs' value='shift'>
        <Search />

        <Card mt='lg'>
          <Text>Position related to selected spans</Text>
          <Box
            className='align-center justify-center'
            mb='xs'
            p='xs'
            style={{
              backgroundColor: 'var(--mantine-color-gray-1)',
              borderRadius: 4
            }}
          >
            {renderSelectedText()}
          </Box>
          <Group justify='space-between'>
            <NumberInput
              label='Word left'
              max={spanEndIndex}
              min={-mockStartIndex}
              size='xs'
              value={spanStartIndex}
              onChange={(value = 0) => {
                setSpanStartIndex(value as number);
              }}
            />

            <NumberInput
              label='Word right'
              max={splitMockText.length - mockEndIndex}
              min={spanStartIndex}
              size='xs'
              value={spanEndIndex}
              onChange={(value = 0) => {
                setSpanEndIndex(value as number);
              }}
            />
          </Group>

          <Notes />

          <Group justify='flex-end' mt='lg'>
            <Button
              copy='Send to Edits Cart'
              disabled={!(searchTerm || spanStartIndex || spanEndIndex)}
              onClick={() => onEditCreate(EDIT_ACTIONS.SHIFT_SPAN)}
            />
          </Group>
        </Card>
      </Tabs.Panel>
    </Tabs>
  );
};

export const NerTableContent = ({ data }: GoldSpanHighlighterProps) => {
  const changeLabelTo = useDataEditsStore((state) => state.changeLabelTo);
  const searchTerm = useDataEditsStore((state) => state.searchTerm);
  const currentTab = useDataEditsStore((state) => state.currentTab);

  // Users can search using regex, but the Highlight component only accepts plain strings, this removes all special characters
  const searchWithoutSpecialCharacters = searchTerm?.replace(/[^\w\s]/gi, '');

  return (
    <Grid.Col data-testid='ner-table-content' p='xs'>
      <GoldSpanHighlighter
        changeLabelTo={currentTab === 'add' ? changeLabelTo ?? '' : undefined}
        data={data}
        searchTerm={searchWithoutSpecialCharacters}
      />
    </Grid.Col>
  );
};
