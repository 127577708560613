import { Badge, Box, Grid, Group, Text } from '@mantine/core';

import AmountCard from '@/fine-tune/components/amount-card/amount-card';
import Button from '@/fine-tune/components/button/button';
import ExportButton from '@/fine-tune/components/export-button/export-button';
import SearchBar from '@/fine-tune/components/search-bar/search-bar';
import { EditAction } from '@/fine-tune/stores/data-edits-store/data-edits.store.types';

import HistoryLog from './history-log/history-log';

interface ICart {
  title: string;
  currentElement: string;
  isEditsCart?: boolean;
  onAllRemove?: () => void;
  onModalClose: () => void;
  onElementRemove: (id: string) => void;
  onElementChange: (id: string) => void;
  elements: ICartElement[];
  filters?: ICartFilter[];
  children?: React.ReactNode;
  isEmpty?: boolean;
  amountComponent?: React.ReactNode;
  onRegexToggle: () => void;
  onSearchEnter: (searchTerm: string) => void;
  isRegexSearch: boolean;
  searchTerm: string;
  canSearch?: boolean;
}

interface ICartFilter {
  value: string;
  label: string;
  amount: number;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export interface ICartElement {
  id: string;
  created_at: string;
  created_by: string;
  edit_action: EditAction;
  new_label: string;
  note: string;
  sample_ids: number[];
  text_replacement?: string;
  search_string?: string;
  reviewers?: {
    created_by: string;
    created_at: string;
  }[];
}

const Cart = ({
  currentElement,
  title,
  amountComponent,
  isEditsCart = false,
  onModalClose,
  onAllRemove,
  onElementRemove,
  onElementChange,
  onSearchEnter,
  onRegexToggle,
  isRegexSearch,
  searchTerm,
  elements,
  isEmpty,
  filters,
  canSearch = true,
  children
}: ICart) => {
  const handleElementChange = (id: string) => {
    onElementChange(id);
  };

  const handleElementRemove = (elementId: string) => {
    onElementRemove(elementId);
  };

  return (
    <Box className='c-cart' data-testid='cart' h='100%' mb={0}>
      <Box
        h={65}
        p={15}
        style={{
          borderBottom: `1px solid var(--mantine-color-gray-2)`
        }}
        w='100%'
      >
        <Text c='contrast.2' fw={600} size='lg'>
          {title}
        </Text>
        <Box className='position-absolute' style={{ right: 30, top: 15 }}>
          <Group justify='space-between'>
            {canSearch && (
              <SearchBar
                defaultValue={searchTerm}
                isRegexSearch={isRegexSearch}
                mr='xs'
                onEnter={onSearchEnter}
                onRegexToggle={onRegexToggle}
              />
            )}
            <Button
              color='indigo.5'
              copy='Close'
              size='sm'
              variant='light'
              onClick={onModalClose}
            />

            <ExportButton
              color='brand.5'
              disabled={!elements?.length}
              fromEditsCart={isEditsCart}
              size='sm'
              variant='filled'
            />
          </Group>
        </Box>
      </Box>
      <Grid
        columns={24}
        gutter={0}
        h='calc(100% - 65px)'
        styles={{
          inner: {
            height: '100%'
          }
        }}
      >
        {!isEmpty && (
          <Grid.Col
            span={8}
            style={{
              backgroundColor: 'var(--mantine-color-contrast-8)',
              padding: '16px 8px 0 16px'
            }}
          >
            <AmountCard
              amountComponent={amountComponent}
              isSelected={currentElement === 'all'}
              mr={10}
              title='Total Edited'
              titleColor='brand.5'
              onClick={() => onElementChange('all')}
              onSecondaryClick={onAllRemove}
            />
            {filters && (
              <Box mt={10}>
                {filters.map(
                  ({ amount, value, label, isActive, disabled, onClick }) => (
                    <Badge
                      color='gray'
                      key={value}
                      mb={4}
                      mr={10}
                      radius='sm'
                      size='lg'
                      style={({ colors }) => ({
                        textTransform: 'lowercase',
                        maxWidth: '45%',
                        background: isActive
                          ? colors.contrast[8]
                          : colors.contrast[6],
                        cursor: disabled ? 'not-allowed' : 'pointer'
                      })}
                      variant={isActive ? 'outline' : 'light'}
                      onClick={disabled ? undefined : onClick}
                    >
                      <Text
                        color='contrast.2'
                        component='span'
                        fw={800}
                        size='xs'
                      >
                        {amount || 0}{' '}
                      </Text>
                      <Text component='span' fw={400} size='xs'>
                        {label}
                      </Text>
                    </Badge>
                  )
                )}
              </Box>
            )}
            <HistoryLog
              currentElement={currentElement}
              logItems={elements}
              onLogItemClick={handleElementChange}
              onLogItemRemove={handleElementRemove}
            />
          </Grid.Col>
        )}
        <Grid.Col p={4} span={isEmpty ? 24 : 16}>
          {children}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default Cart;
