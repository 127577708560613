import { useQueryParameters as useGenericQueryParameters } from '@/core/hooks/use-query-parameters/use-query-parameters';

import { defaultStoreParams } from './query-parameters';
import { ObserveParameters } from './query-parameters.types';
import { fromRouter } from './utils';

export function useQueryParameters() {
  const { isReady, set, get, reset } =
    useGenericQueryParameters<ObserveParameters>(defaultStoreParams, {
      parsers: {
        fromRouter
      }
    });

  return {
    isReady,
    set,
    get,
    reset
  };
}
