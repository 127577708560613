import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export const getFileName = (path: string) => {
  if (!path || typeof path !== 'string') {
    return STRING_PLACEHOLDER;
  }

  const splitPath = path.split('/');
  let fileName = splitPath[splitPath.length - 1];

  if (fileName.includes('?')) {
    fileName = fileName.split('?')[0];
  }

  return fileName;
};
