import { useParametersStore } from '../parameters.store';

export const useParametersStoreActions = () => {
  const actions = useParametersStore((state) => state.actions);

  return actions;
};

export const getParametersStoreActions = () => {
  const actions = useParametersStore.getState().actions;

  return actions;
};
