import { useSessionStorage } from '@mantine/hooks';

import { defaults as defaultUserPermissions } from '@/core/hooks/use-permissions/use-user-permissions';
import { FlagTags } from '@/core/types/feature-flags.types';
import {
  UserAction,
  UserPermissions
} from '@/core/types/user-permissions.types';

export function useUserPermissionsFlags() {
  const [userFlag, setUserFlag] = useSessionStorage({
    key: 'user',
    defaultValue: false
  });

  const [userPermissions, setUserPermissions] =
    useSessionStorage<UserPermissions>({
      key: 'user-permissions',
      defaultValue: defaultUserPermissions
    });

  return {
    userPermissions: {
      description: 'Select User permissions',
      isEnabled: userFlag,
      tag: [FlagTags.Permission],
      title: 'User Permissions',
      values: userPermissions,
      toggle: () => {
        const newValue = !userFlag;
        setUserFlag(newValue);
        if (newValue === false) {
          setUserPermissions(defaultUserPermissions);
        }
      },
      subFlags: Object.keys(userPermissions || []).map((key) => ({
        description: `toggle ${key} permissions`,
        isEnabled: userPermissions[key as UserAction].allowed,
        tag: [FlagTags.Permission],
        title: key,
        toggle: () =>
          setUserPermissions((current) => ({
            ...current,
            [key]: {
              ...current[key as UserAction],
              allowed: !current[key as UserAction].allowed
            }
          }))
      }))
    }
  };
}
