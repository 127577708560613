import { Group, Text } from '@mantine/core';

import Metric, { MetricProps } from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';
import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

const VALUES_LIMIT = 1;

const TagsMetric = ({
  value,
  variant = 'filled'
}: {
  value: string[] | null | undefined;
  variant?: MetricProps['variant'];
}) => {
  const tagValues = value != null && Array.isArray(value) ? value : [];

  let contentElements: JSX.Element[] = [
    <Metric
      key='default-tags-metric'
      value={<Text truncate>{STRING_PLACEHOLDER}</Text>}
      variant={variant}
    />
  ];
  let truncatedContentElements = [...contentElements];

  if (tagValues.length > 0) {
    contentElements = tagValues.map((tagValue) => (
      <Metric
        color='gray'
        key={tagValue}
        value={<Text truncate>{tagValue}</Text>}
        variant={variant}
      />
    ));

    truncatedContentElements = [...contentElements].slice(0, VALUES_LIMIT);
    if (tagValues.length > VALUES_LIMIT) {
      truncatedContentElements.push(
        <Metric
          color='gray'
          key='show-more-tags-metric'
          value={<Text truncate>{`+${tagValues.length - VALUES_LIMIT}`}</Text>}
          variant={variant}
        />
      );
    }
  }

  return (
    <MetricPopover
      body={<Group p='sm'>{contentElements}</Group>}
      disabled={tagValues.length === 0}
      header={
        <Metric value={`Detected ${tagValues.length} Tags`} variant='subtle' />
      }
    >
      <Group className='truncate' data-testid='tags-metric' wrap='nowrap'>
        {truncatedContentElements}
      </Group>
    </MetricPopover>
  );
};

export default TagsMetric;
