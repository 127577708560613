import check from 'check-types';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';

import type { BaseState } from './store.types';

const defaultValues = {
  // Similar To (ids of select spans)
  similarToIds: [],
  similarToQuantity: undefined,
  // Similar To (ids of select spans)
  similarFromIds: [],
  // Color Map (colors by label per run)
  colorMap: {},
  columnsOrder: [],
  shouldAddColumns: true,
  // Selected Rows (dataframe row selection)
  selectedRows: [],
  selectedObjects: [],
  selectedSpans: [],
  hiddenSpans: [],
  allRowsSelected: false,
  // Used only if we want to store a baseline run id before navigating to compare runs
  baselineRunId: null,
  mlcTaskAndIds: {
    task: '',
    ids: [],
    isCoOccurrence: false
  },
  allMltcLabelsVisible: true,
  isTableExpanded: false,
  isActionPaneVisible: false,
  actionMenuTarget: {},
  showEditedRows: true
} as any; // TODO: Fix

const useStore = createWithEqualityFn<BaseState>()(
  (set) => ({
    ...defaultValues,

    actions: {
      resetStore: () => set(() => defaultValues),
      setSimilarToIds: (ids: number[]) =>
        set(() => {
          return { similarToIds: ids };
        }),
      clearSimilarToIds: () => set(() => ({ similarToIds: [] })),
      setColumnsOrder: (order: string[]) =>
        set(() => ({ columnsOrder: order })),
      setSimilarToQuantity: (value) =>
        set(() => ({ similarToQuantity: value })),
      setShouldAddColumns: (bool: boolean) =>
        set(() => ({ shouldAddColumns: bool })),
      setSimilarFromIds: (ids: number[]) =>
        set(() => {
          return { similarFromIds: ids };
        }),
      setColorMap: (map) => set(() => ({ colorMap: map })),
      setSelectedObjects: (objects) =>
        set(() => ({ selectedObjects: objects })),
      setSelectedRows: (rows) =>
        set(() => {
          return { selectedRows: rows };
        }),
      setSelectedSpans: (span) =>
        set((state) => {
          if (check.array(span)) return { selectedSpans: [] };

          const isPresent = state.selectedSpans?.find(
            (storedSpan) => (span as SpanHighlightData).id === storedSpan.id
          );
          let selectedSpans: SpanHighlightData[];
          if (isPresent) {
            selectedSpans = state.selectedSpans.filter(
              (storedSpan) => (span as SpanHighlightData).id !== storedSpan.id
            );
          } else {
            selectedSpans = [...state.selectedSpans, span as SpanHighlightData];
          }
          return { selectedSpans };
        }),
      setAllRowsSelected: (bool) => set(() => ({ allRowsSelected: bool })),
      setShowEditedRows: (bool) => set(() => ({ showEditedRows: bool })),
      clearSelectionState: () =>
        set(() => ({
          isInsightsCardVisible: null,
          isInsightsCardDisabled: false,
          selectedRows: [],
          selectedSpans: [],
          similarFromIds: [],
          similarToIds: [],
          hiddenSpans: [],
          allRowsSelected: false,
          mlcTaskAndIds: {
            task: '',
            ids: [],
            isCoOccurrence: false
          }
        })),
      setBaselineRunId: (id) => set(() => ({ baselineRunId: id })),

      setAllMltcLabelsVisible: (val: boolean) =>
        set(() => ({ allMltcLabelsVisible: val })),
      setMlcTaskAndIds: (taskAndIds) =>
        set(() => ({
          mlcTaskAndIds: {
            task: taskAndIds?.task,
            ids: taskAndIds?.ids ?? [],
            isCoOccurrence: taskAndIds?.isCoOccurrence || false
          }
        })),
      setIsActionPaneVisible: (bool: boolean) =>
        set(() => ({ isActionPaneVisible: bool })),
      setIsTableExpanded: (bool: boolean) =>
        set(() => ({ isTableExpanded: bool })),
      updateStore: (params: Partial<BaseState>) => set(() => ({ ...params })),
      toggleHiddenSpan: (spans: string | string[]) =>
        set((state) => {
          if (check.array(spans)) return { hiddenSpans: spans };

          let traces: string[] = [];

          if (state.hiddenSpans.includes(spans as string)) {
            traces = state.hiddenSpans.filter((stored) => stored !== spans);
          } else {
            traces = [...state.hiddenSpans, spans];
          }

          return { hiddenSpans: traces };
        })
    }
  }),
  shallow
);

export default useStore;
