import {
  SCORER_TYPE,
  ScorerType
} from '@/core/constants/scorer-type.constants';

export function getScorerTypeLabel(scorerType: ScorerType) {
  switch (scorerType) {
    case SCORER_TYPE.LUNA:
      return 'Luna';
    case SCORER_TYPE.PLUS:
      return 'Plus';
  }
}
