export enum FlagTags {
  Feature = 0,
  External,
  Tool,
  Permission
}

export type ToggleFlag = {
  description: string;
  isEnabled: boolean;
  tag: FlagTags[];
  title: string;
  toggle: () => void;
};

export type SelectFlag<T> = {
  description: string;
  value: T;
  options: { label: keyof T; value: string }[];
  tag: FlagTags[];
  title: string;
  set: (newValue: T) => void;
};

export type CompoundFlag = {
  description: string;
  tag: FlagTags[];
  title: string;
  isEnabled: boolean;
  toggle: () => void;
  subFlags: FeatureFlag[];
};

export type FeatureFlag = ToggleFlag | SelectFlag<any> | CompoundFlag;
