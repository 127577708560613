import { Box, Grid, Group, Text } from '@mantine/core';
import React, { ForwardedRef } from 'react';

import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import { SS_ERROR_MAPPING } from '@/fine-tune/constants/semantic-segmentation.constants';
import { Polygon } from '@/fine-tune/types/query.types';

import DepOrConfidence from '../dep-or-confidence/dep-or-confidence';
import OdLabelBadge from '../label-badge/od-label-badge/od-label-badge';

interface SsPopoverProps {
  polygon: Polygon;
}

const SsPopover = React.forwardRef(
  (props: SsPopoverProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { polygon } = props;
    const { gold, pred, error_type, accuracy, area } = polygon;

    const error = Object.entries(SS_ERROR_MAPPING).find(
      (err) => err[0] === error_type && error_type !== 'None'
    );

    const isGTPolygon = !!gold;
    const accuracyText = isGTPolygon ? 'Prediction' : 'Ground Truth';

    return (
      <Box {...props} data-testid='ss-popover' ref={ref}>
        <Grid gutter={5}>
          <Grid.Col mt={6} span={6}>
            <OdLabelBadge label={gold || pred} />
          </Grid.Col>
          <Grid.Col span={6}>
            <DepOrConfidence
              accessor='data_error_potential'
              component='span'
              sample={polygon}
              variant='filled'
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Group>
              <Text c='gray.6' size='sm'>
                <Text component='span' fw={600}>
                  {toHumanReadableNumber(accuracy * 100)}
                </Text>
                % matching {accuracyText}
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <Group justify='flex-end'>
              <Text c='gray.6' size='sm'>
                Size:{' '}
                <Text component='span' fw={600}>
                  {toHumanReadableNumber(area)}px
                </Text>
              </Text>
            </Group>
          </Grid.Col>
          {error && (
            <Group mt='sm'>
              {error[1].icon}
              <Text c='dimmed' size='sm'>
                {error[1].label}
              </Text>
            </Group>
          )}
        </Grid>
      </Box>
    );
  }
);

export default SsPopover;
