import _isPlainObject from 'lodash/isPlainObject';

import { MetricStatusType } from '@/core/types/metric-status.types';
import { MetricsRow } from '@/core/types/metrics-table.types';
import { isSuccessMetricStatus } from '@/core/utils/is-metric-status/is-metric-status';
import { ObserveRow } from '@/observe/hooks/query-hooks/use-rows/use-rows';

/** NOTE: job info is set per metric for all chunks, we pass in the status to each chunk */
export interface Chunk {
  index: number;
  outputText: string | null | undefined;
  metadata: string | undefined;
  tokens: number | null | undefined;
  attributed: boolean | undefined;
  utilization: number | null | undefined;
}

type ParsedChunkOutput =
  | {
      content: string | null | undefined;
      metadata: string | undefined;
    }
  | undefined;

// This local util helps us to re-stringify the metadata so it's easier to pass the type around
export function getMetadataString(metadata: any): string | undefined {
  if (metadata == null) {
    return undefined;
  } else if (typeof metadata === 'string') {
    return metadata;
  } else {
    return JSON.stringify(metadata);
  }
}

/** Expects `rowOutputText` is a stringified array of objects with { metadata: {}, content: string } */
function parseChunkOutputs(
  rowOutputText: string | null | undefined
): ParsedChunkOutput[] {
  const rowOutputJSON =
    rowOutputText != null ? JSON.parse(rowOutputText) : undefined;
  const chunkOutputs =
    rowOutputJSON != null && Array.isArray(rowOutputJSON)
      ? rowOutputJSON.map((chunkOutput) => {
          const output = _isPlainObject(chunkOutput)
            ? {
                // TODO: Remove page_content and pageContent after API unification
                content:
                  chunkOutput['content'] ??
                  chunkOutput['page_content'] ??
                  chunkOutput['pageContent'],
                metadata: getMetadataString(chunkOutput['metadata'])
              }
            : typeof chunkOutput === 'string'
              ? {
                  content: chunkOutput,
                  metadata: undefined
                }
              : undefined;
          return output;
        })
      : [];
  return chunkOutputs;
}

function buildChunks({
  chunkOutputs,
  chunk_length,
  chunk_attribution,
  chunk_utilization
}: {
  chunkOutputs: ParsedChunkOutput[];
  chunk_length: MetricStatusType | undefined;
  chunk_attribution: MetricStatusType | undefined;
  chunk_utilization: MetricStatusType | undefined;
}) {
  const chunkTokens: number[] =
    isSuccessMetricStatus(chunk_length) &&
    chunk_length?.value != null &&
    Array.isArray(chunk_length.value)
      ? (chunk_length.value as number[])
      : [];

  // API can return number or booleans
  const chunkAttribution: boolean[] =
    isSuccessMetricStatus(chunk_attribution) &&
    chunk_attribution?.value != null &&
    Array.isArray(chunk_attribution.value)
      ? (chunk_attribution.value.map((attributionValue) =>
          typeof attributionValue === 'number'
            ? attributionValue !== 0
            : attributionValue
        ) as boolean[])
      : [];

  const chunkUtilization: number[] =
    isSuccessMetricStatus(chunk_utilization) &&
    chunk_utilization?.value != null &&
    Array.isArray(chunk_utilization.value)
      ? (chunk_utilization.value as number[])
      : [];

  return chunkOutputs.map((chunkOutput, index) => ({
    index,
    outputText: chunkOutput?.content,
    metadata: chunkOutput?.metadata,
    tokens: chunkTokens[index],
    attributed: chunkAttribution[index],
    utilization: chunkUtilization[index]
  }));
}

/** Returns complete chunks with compiled metrics  */
export function getChunksFromObserveRow(row: ObserveRow): Chunk[] {
  const chunkOutputs = parseChunkOutputs(row.output_text);

  const metrics = row.metrics ?? {};

  return buildChunks({
    chunkOutputs,
    chunk_length: isSuccessMetricStatus(metrics['chunk_length'])
      ? metrics['chunk_length']
      : undefined,
    chunk_attribution: isSuccessMetricStatus(metrics['chunk_attribution'])
      ? metrics['chunk_attribution']
      : undefined,
    chunk_utilization: isSuccessMetricStatus(metrics['chunk_utilization'])
      ? metrics['chunk_utilization']
      : undefined
  });
}

/** Returns complete chunks with compiled metrics  */
export function getChunksFromMetricsRow(row: MetricsRow): Chunk[] {
  const chunkOutputs =
    row.output_text != null && typeof row.output_text === 'string'
      ? parseChunkOutputs(row.output_text)
      : [];

  return buildChunks({
    chunkOutputs,
    chunk_length: isSuccessMetricStatus(row['chunk_length'])
      ? row['chunk_length']
      : undefined,
    chunk_attribution: isSuccessMetricStatus(row['chunk_attribution'])
      ? row['chunk_attribution']
      : undefined,
    chunk_utilization: isSuccessMetricStatus(row['chunk_utilization'])
      ? row['chunk_utilization']
      : undefined
  });
}
